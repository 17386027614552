import React, {useState} from 'react';

import useLayout from '@/hooks/inbox/useLayout';

import {useHighlightsSectionId} from '../../contexts/HighlightsSectionIdContext';
import HighlightsContainer from '../common/components/HighlightsContainer';
import HighlightsHeader from '../common/components/HighlightsHeader';
import FullscreenDesktopHighlights from '../FullscreenDesktopHighlights/FullscreenDesktopHighlights';
import FullscreenHighlightsCarousel from '../MobileHighlights/components/FullscreenHighlightsCarousel';
import HighlightsCarouselList from './components/HighlightsCarouselList';
import {getHighlights} from './services/highlightsService';

const DesktopHighlights = () => {
    const {isMd, isMl} = useLayout();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const id = useHighlightsSectionId();
    const {carouselsFromConfig, isAutoPlayEnabled} = getHighlights(id);
    const visibleCount = isMd ? 2 : isMl ? 3 : carouselsFromConfig.length;

    if (!carouselsFromConfig?.length) return null;

    return (
        <HighlightsContainer className='@container'>
            <HighlightsHeader onViewAllClick={() => setIsFullscreen(true)} />
            <HighlightsCarouselList
                carouselsFromConfig={carouselsFromConfig}
                visibleCount={visibleCount}
                isAutoPlayEnabled={isAutoPlayEnabled}
                isPlaying={!isFullscreen}
            />
            {isFullscreen && (
                <FullscreenHighlights
                    isMobileView={isMd}
                    carouselsFromConfig={carouselsFromConfig}
                    visibleCount={visibleCount}
                    isAutoPlayEnabled={isAutoPlayEnabled}
                    setIsFullscreen={setIsFullscreen}
                />
            )}
        </HighlightsContainer>
    );
};

const FullscreenHighlights = ({
    isMobileView,
    carouselsFromConfig,
    visibleCount,
    isAutoPlayEnabled,
    setIsFullscreen,
}) => {
    const mergedHighlights = carouselsFromConfig.slice(0, visibleCount).flatMap((carousel) => carousel.highlights);

    return isMobileView ? (
        <FullscreenHighlightsCarousel
            setIsFullscreen={setIsFullscreen}
            currentIndex={0}
            highlights={mergedHighlights}
            autoplay={isAutoPlayEnabled}
            isInfinite
        />
    ) : (
        <FullscreenDesktopHighlights
            carouselsFromConfig={carouselsFromConfig}
            isAutoPlayEnabled={isAutoPlayEnabled}
            onClose={() => setIsFullscreen(false)}
        />
    );
};

export default DesktopHighlights;
