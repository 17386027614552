import React, {forwardRef} from 'react';

import {twx} from '@/utils/tailwindUtils';

import PageHeaderMedia from '../PageHeaderMedia/PageHeaderMedia';

const Banner = forwardRef(function BannerRef(
    {
        className,
        onClick,
        imageMedia,
        videoPath,
        isGradient,
        isDarkBannerText,
        isVideoAvailable,
        children,
        gradient,
        ...rest
    },
    ref
) {
    return (
        <article ref={ref} className={twx('relative', className, {'text-primary': isDarkBannerText})} onClick={onClick}>
            <PageHeaderMedia
                isVideoAvailable={isVideoAvailable ?? !!videoPath}
                className='absolute h-full'
                videoSrc={videoPath}
                imageMedia={imageMedia}
                {...rest}
            />
            {isGradient && <div className='translate-z-1 absolute h-full w-full' style={{background: gradient}} />}
            {children}
        </article>
    );
});

export default Banner;
