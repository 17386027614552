import cn from 'classnames';
import React from 'react';

import routePaths from '@/consts/route/routePaths';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {InfoIcon} from '../../../components/Icons';

const FirmwareVersionWarning = ({className}) => {
    return (
        <div className={cn('flex w-full items-start justify-between rounded-xl bg-secondary p-4 pr-8', className)}>
            <div className='flex items-center gap-2 text-start'>
                <InfoIcon className='shrink-0' />
                <div>
                    <h4 className='mb-0 text-16 leading-15 tracking-02'>
                        <AemEditableText pageName={routePaths.ABOUT_DEVICE} itemName='firmwareWarningTitle' />
                    </h4>
                    <p className='text-14 leading-143 tracking-04'>
                        <AemEditableText pageName={routePaths.ABOUT_DEVICE} itemName='firmwareWarningDescription' />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FirmwareVersionWarning;
