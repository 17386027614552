import gsap from 'gsap';
import {useEffect, useRef} from 'react';

import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';

const useHighlightsSlideButtonGsap = ({progressLineRef, duration, playState, onEnded}) => {
    const {timelineRef, animationRef} = useGsapInit({progressLineRef, duration, playState, onEnded});

    useSyncPropsWithAnimation({timelineRef, animationRef, duration, playState});

    return {timelineRef, animationRef};
};

const useGsapInit = ({progressLineRef, duration, playState, onEnded}) => {
    const DEFAULT_DURATION = 7;

    const timelineRef = useRef(null);
    const animationRef = useRef(null);
    const onEndedRef = useOnEndedRef(onEnded);

    // handleComplete caches all the values inside it so every variable from outer scope should be passed with ref
    const handleComplete = () => {
        timelineRef.current.pause();
        onEndedRef.current?.();
    };

    useEffect(() => {
        const isPaused = playState !== VIDEO_PLAY_STATES.PLAYING || !duration; // duration is 0 while video is loading
        const timeline = gsap.timeline({paused: isPaused});
        const animation = timeline.fromTo(
            progressLineRef.current,
            {
                width: 0,
            },
            {
                width: '100%',
                duration: duration || DEFAULT_DURATION,
                ease: 'linear',
                onComplete: handleComplete,
            }
        );

        timelineRef.current = timeline;
        animationRef.current = animation;

        return () => timelineRef.current.kill();
    }, []);

    return {timelineRef, animationRef};
};

const useOnEndedRef = (onEnded) => {
    const onEndedRef = useRef(onEnded);

    useEffect(() => {
        onEndedRef.current = onEnded;
    }, [onEnded]);

    return onEndedRef;
};

const useSyncPropsWithAnimation = ({timelineRef, animationRef, duration, playState}) => {
    const syncPlayState = () => {
        if (playState === VIDEO_PLAY_STATES.PLAYING) {
            timelineRef.current.play();
        } else if (playState === VIDEO_PLAY_STATES.PAUSED) {
            timelineRef.current.pause();
        } else if (playState === VIDEO_PLAY_STATES.STOPPED) {
            const suppressEvents = true;

            timelineRef.current.pause().progress(0, suppressEvents);
        } else if (playState === VIDEO_PLAY_STATES.ENDED) {
            const suppressEvents = true;

            timelineRef.current.pause().progress(1, suppressEvents);
        }
    };

    useEffect(() => {
        syncPlayState();
    }, [playState]);

    useEffect(() => {
        if (duration) {
            animationRef.current.duration(duration);
            syncPlayState();
        }
    }, [duration]);
};

export default useHighlightsSlideButtonGsap;
