import React from 'react';

import appConfig from '@/config/appConfig';
import {AEM_SECTION_TYPES} from '@/consts/aem/aemSectionTypes';
import HomePageAutoDeliverySection from '@/containers/HomePage/components/HomePageAutoDeliverySection/HomePageAutoDeliverySection';
import HomePageForYouSection from '@/containers/HomePage/components/HomePageForYouSection/HomePageForYouSection';
import DevicesSection from '@/containers/ShopPage/components/DeviceSection/DevicesSection';
import ShopByProductSection from '@/containers/ShopPage/components/ShopByProductSection';
import YourUsualSection from '@/containers/ShopPage/components/YourUsualSection/YourUsualSection';
import useIsTopBannerEnabled from '@/hooks/useIsTopBannerEnabled';
import usePageSections from '@/hooks/usePageSections';
import GreetingAsSeparateSection from '@/views/Greeting/GreetingAsSeparateSection';
import Highlights from '@/views/Highlights/Highlights';
import HomePageTopBanner from '@/views/HomePageTopBanner/HomePageTopBanner';

const DynamicPage = () => {
    const isAutoDeliveryEnabled = appConfig.getIsAutoDeliveryEnabled();
    const isYourDevicesSectionEnabled = appConfig.isYourDevicesSectionEnabled();
    const pageSections = usePageSections();
    const isTopBannerEnabled = useIsTopBannerEnabled();

    return (
        <>
            {!isTopBannerEnabled && <GreetingAsSeparateSection className='mb-2 md:mb-0' />}
            {pageSections?.map(({id, type}) => {
                switch (type) {
                    case AEM_SECTION_TYPES.TOP_BANNER:
                        return <HomePageTopBanner id={id} key={id} />;
                    case AEM_SECTION_TYPES.AUTO_DELIVERY:
                        return isAutoDeliveryEnabled ? <HomePageAutoDeliverySection id={id} key={id} /> : null;
                    case AEM_SECTION_TYPES.HIGHLIGHTS:
                        return <Highlights id={id} key={id} />;
                    case AEM_SECTION_TYPES.FOR_YOU:
                        return <HomePageForYouSection id={id} key={id} />;
                    case AEM_SECTION_TYPES.YOUR_USUAL:
                        return <YourUsualSection id={id} key={id} />;
                    case AEM_SECTION_TYPES.YOUR_DEVICES:
                        return isYourDevicesSectionEnabled ? <DevicesSection id={id} key={id} /> : null;
                    case AEM_SECTION_TYPES.SHOP_BY_PRODUCT:
                        return <ShopByProductSection id={id} key={id} />;
                    default:
                        return null;
                }
            })}
        </>
    );
};

export default DynamicPage;
