import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {
    CHARGER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT,
    HOLDER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT,
} from '@/consts/localization/localizationKeys';
import amplitudeDeviceTracking from '@/services/analytics/amplitude/amplitudeDeviceTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import productHelpers from '@/services/product/productHelpers';
import productService from '@/services/product/productService';
import {setNotificationError} from '@/state/slices/notificationsSlice';

const useDeviceUnregistration = ({devices, onClose}) => {
    const dispatch = useDispatch();
    const [deviceIndex, setDeviceIndex] = useState(null);
    const localizedStrings = getLocalizedStrings();
    const isPopupOpened = deviceIndex !== null;
    const popupDeviceName = deviceIndex !== null ? productHelpers.getProductName(devices[deviceIndex]) : null;

    const handleUnregisterClick = (i) => setDeviceIndex(i);

    const unregisterDevice = async (device) => {
        const {deviceSerialNumber, type, holder} = device;
        const result = await productService.unregisterConsumerProduct(device);
        // isChargerRequestSent/isHolderRequestSent can be true/false/undefined so explicit checks below are required
        const {isChargerRequestSent, isHolderRequestSent} = result || {};

        if (isChargerRequestSent === true) {
            amplitudeDeviceTracking.trackUnregisterDeviceClick(deviceSerialNumber, type, isChargerRequestSent);
            googleAnalyticsService.trackUnregisterDeviceClick(deviceSerialNumber, type, isChargerRequestSent);
        } else if (isChargerRequestSent === false) {
            dispatch(setNotificationError(localizedStrings[CHARGER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT]));
        }

        if (isHolderRequestSent === true) {
            amplitudeDeviceTracking.trackUnregisterHolderClick(
                holder?.type,
                holder?.deviceSerialNumber,
                isHolderRequestSent
            );
            googleAnalyticsService.trackUnregisterDeviceClick(
                holder?.type,
                holder?.deviceSerialNumber,
                isHolderRequestSent
            );
        } else if (isHolderRequestSent === false) {
            dispatch(setNotificationError(localizedStrings[HOLDER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT]));
        }
    };

    const handlePopupUnregisterClick = async () => {
        setDeviceIndex(null);
        onClose?.();
        await unregisterDevice(devices[deviceIndex]);
    };

    const handlePopupCancelClick = () => setDeviceIndex(null);

    return {isPopupOpened, popupDeviceName, handleUnregisterClick, handlePopupUnregisterClick, handlePopupCancelClick};
};

export default useDeviceUnregistration;
