import arrayUtils from '@/utils/arrayUtils';

// Groups slides and their duplicates into groups and synchronizes any change between them
const useHighlightsDuplicateGroups = ({carousels, carouselControls, initialCount}) => {
    const {getSlideProgress, setSlideProgress, resetSlideProgress} = carouselControls;

    const getGroupSize = () => Math.max(initialCount, 2);
    const getGroupIndex = (i) => i % getGroupSize();
    const getIsInGroup = (i, group) => i % getGroupSize() === group;
    const getNextInGroup = (i) => (i + getGroupSize()) % carousels.length;

    const syncWithGroup = (states, i) => {
        const duplicate = getNextInGroup(i);

        if (i === duplicate) {
            return states;
        }

        const currentState = states[i];
        const duplicateState = {...currentState};
        const newStates = arrayUtils.updateArrayImmutably(states, [duplicate, duplicateState]);

        return newStates;
    };

    // All slides in the same group play simultaneously, which causes progress desynchronization,
    // so they need to be synchronized
    const fixGroupProgressDesync = (i, slide) => {
        const duplicate = getNextInGroup(i);

        if (i === duplicate) return;

        const progress = getSlideProgress({carousel: i, slide});

        setSlideProgress({carousel: duplicate, slide, progress});
    };

    const resetGroupSlideProgress = (i, slide) => {
        const duplicate = getNextInGroup(i);

        resetSlideProgress({carousel: i, slide});
        resetSlideProgress({carousel: duplicate, slide});
    };

    return {
        getGroupIndex,
        getIsInGroup,
        syncWithGroup,
        fixGroupProgressDesync,
        resetGroupSlideProgress,
    };
};

export default useHighlightsDuplicateGroups;
