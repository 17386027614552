import {twx} from '../../utils/tailwindUtils';

const TncPageContainer = ({className, ...rest}) => {
    // It is needed only for setting bg-color on desktop
    return (
        <div
            className={twx('flex w-full flex-1 flex-col items-center justify-center md:bg-romance', className)}
            {...rest}
        />
    );
};

export default TncPageContainer;
