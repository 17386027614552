import * as logMessageTypes from '@/consts/log/logMessageTypes';
import log from '@/services/logger/log';
import httpStatusTypesService from '@/services/server/httpStatusTypesService';

export const logRequestError = (statusCode, logMessage) => {
    const isInfoLevel = !statusCode || httpStatusTypesService.isNotAuthorized(statusCode);

    if (isInfoLevel) {
        log.info(logMessage);
    } else {
        log.error(logMessage, {logMessageType: logMessageTypes.EXTERNAL});
    }
};
