import React from 'react';

import {getLocalizedStrings} from '@/services/localization/localizationService';

import HomePageForYouContainer from './HomePageForYouContainer/HomePageForYouContainer';

const HomePageTextBottomItem = (props) => {
    const {banner, promotedBadgeType} = props;
    const {
        titleKey,
        subTitleKey,
        descriptionKey,
        promotionKey,
        imageMedia,
        videoPath,
        isGradient,
        isDarkBannerText,
        amplitudeTrackingValue,
        action,
    } = banner;
    const localizedStrings = getLocalizedStrings();

    return (
        <HomePageForYouContainer
            externalLink={action}
            imageMedia={imageMedia}
            videoPath={videoPath}
            isDarkText={isDarkBannerText}
            promotedBadgeType={promotedBadgeType}
            badgeText={localizedStrings[promotionKey]}
            titleText={localizedStrings[titleKey]}
            subTitleText={localizedStrings[subTitleKey]}
            descriptionText={localizedStrings[descriptionKey]}
            amplitudeTrackingValue={amplitudeTrackingValue}
            linearGradient={isGradient}
            isSmallTitle
            isTertiarySubtitle
            className='flex-col-reverse'
        />
    );
};

export default HomePageTextBottomItem;
