import React from 'react';

import Icon from '../Icon/Icon';

const SmartGesturesEnabledIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M21.625 9.957c2.692 0 4.875 2.15 4.875 4.804 0 1.804-1.009 3.375-2.5 4.197V15.5a2.5 2.5 0 0 0-5 0l-.001 3.31a4.785 4.785 0 0 1-2.249-4.05c0-2.652 2.183-4.803 4.875-4.803z'
                    fill='#00D1D2'
                    fillRule='nonzero'
                />
                <path
                    d='M31.734 25.839c-.383 0-.747.102-1.067.283v-.051c0-1.322-1.017-2.398-2.266-2.398-.386 0-.75.103-1.068.283v-.054c0-1.322-1.016-2.398-2.266-2.398-.385 0-.748.1-1.065.279v-6.435c0-1.306-.988-2.398-2.202-2.434a2.183 2.183 0 0 0-1.606.639 2.473 2.473 0 0 0-.724 1.758v14.325l-1.78-2.427c-.972-1.324-2.684-1.704-4.073-.903-.577.334-.787 1.095-.468 1.697 1.072 2.026 3.739 6.96 5.366 9.096.22.324 2.752 3.901 7.14 3.901 2.43 0 4.474-.878 5.912-2.54 1.054-1.216 1.724-2.823 1.888-4.524l.542-5.64a.616.616 0 0 0 .003-.06c0-1.321-1.017-2.397-2.266-2.397z'
                    stroke='#383839'
                    strokeWidth='2'
                    strokeLinejoin='round'
                />
                <path
                    d='M28.48 17.222c.367-.872.57-1.828.57-2.83 0-4.083-3.358-7.392-7.5-7.392-4.142 0-7.5 3.31-7.5 7.391 0 1.081.235 2.108.658 3.033'
                    stroke='#00D1D2'
                    strokeWidth='1.848'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M28.48 17.222c.367-.872.57-1.828.57-2.83 0-4.083-3.358-7.392-7.5-7.392-4.142 0-7.5 3.31-7.5 7.391 0 1.081.235 2.108.658 3.033'
                    stroke='#34303D'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </Icon>
    );
};

export default SmartGesturesEnabledIcon;
