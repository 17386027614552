import React, {useState} from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import DeviceIcon from '@/components/DeviceIcon/DeviceIcon';
import {StickyHeader} from '@/components/Layout';
import * as advanceDiagnosticScreenTypes from '@/consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import {
    AD_POST_CLEANING_DEVICE_HEADER_TITLE,
    AD_POST_CLEANING_HEADER_TITLE,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import useRedirectToPhase2 from '../hooks/useRedirectToPhase2';

const AdvancedDiagnosticPostCleaning = () => {
    const isMobile = useIsMobileLayout();
    const [isResultScreen, setIsResultScreen] = useState(false);
    const redirectToPhase2 = useRedirectToPhase2({disabled: !isResultScreen});
    const localizedStrings = getLocalizedStrings();

    const onNextClick = () => {
        if (isResultScreen) {
            appRouterService.forwardToAdvancedDiagnosticScreen({
                screenName: advanceDiagnosticScreenTypes.FINISH_SCREEN,
            });
        } else {
            setIsResultScreen(true);
        }
    };

    const onDeclineClick = () => {
        if (isResultScreen) {
            redirectToPhase2();
        } else {
            appRouterService.forwardToDevicesPage();
        }
    };

    const forwardToCleaningGuide = () => {
        appRouterService.forwardToCleaningGuide({isAdvancedDiagnostic: true});
    };

    const onBackClick = () => {
        setIsResultScreen(false);
    };

    const screenProps = {onNextClick, onDeclineClick};

    useDocumentTitle(
        isResultScreen
            ? localizedStrings[AD_POST_CLEANING_HEADER_TITLE]
            : localizedStrings[AD_POST_CLEANING_DEVICE_HEADER_TITLE]
    );

    return isResultScreen ? (
        <ScreenComponent
            title='postcleaningresulttitle'
            acceptButtonText='postcleaningyesbutton'
            declineButtonText='postcleaningnobutton'
            {...screenProps}
        >
            {isMobile && <StickyHeader onBack={onBackClick} title={localizedStrings[AD_POST_CLEANING_HEADER_TITLE]} />}
            <AppButton tertiary onClick={forwardToCleaningGuide}>
                <AemComponent
                    component={EditableText}
                    pageName={routePaths.ADVANCE_DIAGNOSTIC}
                    itemName='postcleaningrepeatbutton'
                />
            </AppButton>
        </ScreenComponent>
    ) : (
        <ScreenComponent
            title='postcleaningdevicetitle'
            description='postcleaningdevicedesc'
            acceptButtonText='postcleaningdeviceyesbutton'
            declineButtonText='postcleaningdevicenobutton'
            {...screenProps}
        >
            {isMobile && <StickyHeader title={localizedStrings[AD_POST_CLEANING_DEVICE_HEADER_TITLE]} />}
        </ScreenComponent>
    );
};

const ScreenComponent = (props) => {
    const {title, description, acceptButtonText, declineButtonText, onNextClick, onDeclineClick, children} = props;
    const buttonClassName = 'mt-0 mb-3';

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer>
                <DeviceIcon mode='diagnostics' className='h-16 w-16' />
                <AdvancedDiagnosticTitle itemName={title} />
                <AdvancedDiagnosticDescription itemName={description} />

                <div className='mt-10 w-full md:w-auto'>
                    <AppButton secondary light className={buttonClassName} onClick={onNextClick}>
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.ADVANCE_DIAGNOSTIC}
                            itemName={acceptButtonText}
                        />
                    </AppButton>
                    <AppButton secondary light className={buttonClassName} onClick={onDeclineClick}>
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.ADVANCE_DIAGNOSTIC}
                            itemName={declineButtonText}
                        />
                    </AppButton>
                </div>
                {children}
            </AdvancedDiagnosticContainer>
            <WarningBanners source={WarningBanners.SOURCES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticPostCleaning;
