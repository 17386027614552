import React, {useEffect} from 'react';

import {PopupFullPage} from '@/components/Popup';
import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';
import useModalRoute from '@/hooks/useModalRoute';
import modalService from '@/services/modalService';
import appRouterService from '@/services/route/appRouterService';

import useLanguageSelect from '../../hooks/useLanguageSelect';
import LanguageSelectPopupContent from './LanguageSelectPopupContent';
import LanguageSelectPopupTrigger from './LanguageSelectPopupTrigger';

const LanguageSelectPopup = ({modalType}) => {
    const isDesktopLayout = useIsDesktopLayout();
    const {languageCode, selectListData, handleLanguageChange} = useLanguageSelect();
    const closeModal = () => modalService.hideModal(modalType);
    const {onClose} = useModalRoute({modalType, closeModal});

    //language select popup is absent for desktop design
    useEffect(() => {
        if (isDesktopLayout) {
            appRouterService.forwardToConsumerProfilePage();
        }
    }, [isDesktopLayout]);

    return (
        <PopupFullPage>
            <LanguageSelectPopupContent
                selectedLanguageCode={languageCode}
                selectListData={selectListData}
                onLanguageChange={handleLanguageChange}
                onClose={onClose}
            />
        </PopupFullPage>
    );
};

LanguageSelectPopup.Trigger = LanguageSelectPopupTrigger;

export default LanguageSelectPopup;
