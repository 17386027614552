import React from 'react';

import {twx} from '@/utils/tailwindUtils';

const SIZES = {
    SM: 'sm',
    MD: 'md',
    ML: 'ml',
    LG: 'lg',
};

const MaxWidthContainer = (props) => {
    const {className, children, size = SIZES.SM} = props;

    return (
        <div
            className={twx(
                'flex flex-col items-center',
                {
                    'max-w-[375px]': size === SIZES.SM,
                    'max-w-[744px]': size === SIZES.MD,
                    'max-w-[1133px]': size === SIZES.ML,
                    'max-w-[1280px]': size === SIZES.LG,
                },
                className
            )}
        >
            {children}
        </div>
    );
};

export default MaxWidthContainer;
