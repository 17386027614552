import {createSelector} from 'reselect';

const selectInbox = (state) => state.inbox;

const selectInboxMessages = createSelector(selectInbox, (data) => data.messages);
const selectInboxMessagesSorted = createSelector(selectInboxMessages, (messages) => {
    return messages
        ?.slice()
        .filter(({isPendingDeletion}) => !isPendingDeletion)
        .sort((a, b) => new Date(b.sendTs) - new Date(a.sendTs));
});
const selectInboxMessagesNextPageStartKey = createSelector(selectInbox, (data) => data.nextPageStartKey);
const selectInboxMessageId = createSelector(selectInbox, (data) => data.messageId);

const makeSelectInboxMessage = (messageId) =>
    createSelector(selectInboxMessages, (messages) => {
        return messages?.find((message) => message.messageId === messageId);
    });

const makeSelectNextInboxMessage = (messageId) =>
    createSelector(selectInboxMessagesSorted, (messages) => {
        const index = messages?.findIndex((message) => message.messageId === messageId);

        if (index !== -1 && index < messages?.length - 1) {
            return messages[index + 1];
        } else {
            return null;
        }
    });

export {
    makeSelectInboxMessage,
    makeSelectNextInboxMessage,
    selectInboxMessageId,
    selectInboxMessages,
    selectInboxMessagesNextPageStartKey,
    selectInboxMessagesSorted,
};
