import React from 'react';

import {PopupFullPage} from '@/components/Popup';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';

import HighlightsCarousel from './HighlightsCarousel';

const FullscreenHighlightsCarousel = ({
    setIsFullscreen,
    currentIndex,
    highlights,
    autoplay,
    onSlideChange,
    isInfinite,
}) => {
    useScrollToPreviousPosition();

    return (
        <PopupFullPage
            className='z-10 bg-romance'
            isCloseVisible
            onClose={() => setIsFullscreen(false)}
            closeButtonClass='text-white top-safe-offset-2 right-2'
        >
            <HighlightsCarousel
                initialSlide={currentIndex}
                currentIndex={currentIndex}
                isFullscreen
                highlights={highlights}
                autoplay={autoplay}
                onSlideChange={onSlideChange}
                isInfinite={isInfinite}
            />
        </PopupFullPage>
    );
};

export default FullscreenHighlightsCarousel;
