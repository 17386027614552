const ANALYTICS_EVENT_VALUES = {
    PWA: 'PWA',
    BROWSER: 'Browser',
    INBOX: 'Inbox',
    HOMEPAGE: 'Homepage',
    TERMS_OF_USE: 'Terms of Use',
    PRIVACY_NOTICE: 'Privacy notice',
    ATTRIBUTION_NOTICE: 'Attribution notice',
    TRADE_IN: 'Trade in',
    SHOP_DEVICE: 'Shop Device',
    SHOP_ACCESSORIES: 'Shop accessories',
    REACTIVATE: 'Reactivate',
    CHECK_ORDER_STATUS: 'Check Order Status',
    ACTIVE: 'Active',
    ON_HOLD: 'On hold',
    PAUSED: 'Paused',
    PAYMENT_PENDING: 'Payment Pending',
    PRICES_INFO: 'Prices info',
    LINK: 'Link',
    SCREEN_VIEWED_UNDERSCORE: 'screen_viewed',
    NOTIFICATION_ALLOWED_UNDERSCORE: 'notification_allowed',
};

export default ANALYTICS_EVENT_VALUES;
