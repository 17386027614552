import {useEffect, useState} from 'react';

import useIsMlLayout from '@/hooks/useIsMlLayout';

const useGreetingPortal = (isHomePage) => {
    const isMl = useIsMlLayout();
    const [greetingPortal, setGreetingPortal] = useState(null);

    // Greeting portal should be recalculated every time tablet orientation changes
    useEffect(() => {
        const greetingPortal = isHomePage ? document.getElementById('greetingPortal') : null;

        setGreetingPortal(greetingPortal);
    }, [isMl]);

    return greetingPortal;
};

export default useGreetingPortal;
