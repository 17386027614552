import {Slot} from '@radix-ui/react-slot';
import cn from 'classnames';
import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import routePaths from '@/consts/route/routePaths';
import {twx} from '@/utils/tailwindUtils';

const ContactUsSection = ({children, className}) => {
    return (
        <section
            className={twx('flex w-full flex-col items-center gap-4 ml:rounded-3xl ml:bg-secondary ml:p-8', className)}
        >
            {children}
        </section>
    );
};

const Icon = ({children}) => {
    return <Slot className='min-h-12'>{children}</Slot>;
};

const Title = ({itemName}) => {
    return (
        <h3 className='text-28 ml:leading-143'>
            <AemEditableText pageName={routePaths.CONTACT_US} itemName={itemName} />
        </h3>
    );
};

const Description = ({itemName, ...props}) => {
    return (
        <p className='text-16 leading-15'>
            <AemEditableText pageName={routePaths.CONTACT_US} itemName={itemName} {...props} />
        </p>
    );
};

const Button = ({children, itemName, hasThickerShadow, ...props}) => {
    return (
        <AppButton
            className={cn(
                'm-0 w-full ml:mt-auto ml:max-w-[364px]',
                hasThickerShadow && 'shadow-[inset_0_0_0_1.5px_var(--box-shadow-color)]'
            )}
            {...props}
        >
            <AemEditableText pageName={routePaths.CONTACT_US} itemName={itemName} />
        </AppButton>
    );
};

ContactUsSection.Icon = Icon;
ContactUsSection.Title = Title;
ContactUsSection.Description = Description;
ContactUsSection.Button = Button;

export default ContactUsSection;
