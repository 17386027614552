import {useEffect} from 'react';

import {amplitude} from '@/services/analytics/amplitude';
import marketService from '@/services/marketService';

const useAmplitudeMarket = () => {
    useEffect(() => {
        const marketName = marketService.getMarketCode()?.toUpperCase();

        amplitude.setMarket(marketName);
    }, []);
};

export default useAmplitudeMarket;
