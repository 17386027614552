import React, {useEffect} from 'react';

import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import PopupOutline from '@/components/Popup/PopupOutline';
import {FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import FirmwareAvailableArticle from './FirmwareAvailableArticle/FirmwareAvailableArticle';
import FirmwareAvailableButtons from './FirmwareAvailableArticle/FirmwareAvailableButtons';

const FirmwareAvailablePopup = ({modalType, source}) => {
    const isMobile = useIsMobileLayout();
    const onClose = () => modalService.hideModal(modalType);

    useEffect(() => {
        // TODO: Add amplitude tracking
        // amplitudeInboxTracking.trackActionScreen(source);
    }, []);

    return isMobile ? <MobileFirmwareAvailable onClose={onClose} /> : <DesktopFirmwareAvailable onClose={onClose} />;
};

const MobileFirmwareAvailable = ({onClose}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupFullPage className='z-20 flex flex-col bg-romance'>
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={<FirmwareAvailableButtons onCancel={onClose} />}
            >
                <FirmwareAvailableArticle />
                <WarningBanners source={WarningBanners.SOURCES.FIRMWARE_AVAILABLE_POPUP} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

const DesktopFirmwareAvailable = ({onClose}) => {
    return (
        <PopupOutline
            className='flex w-full max-w-[457px] flex-col items-center rounded-3xl p-8'
            hasNavbarOffset
            onClose={onClose}
        >
            <FirmwareAvailableArticle />
            <FirmwareAvailableButtons onCancel={onClose} />
        </PopupOutline>
    );
};

export default FirmwareAvailablePopup;
