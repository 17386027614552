import cn from 'classnames';
import React from 'react';
import {Route} from 'react-router-dom';

import Toaster from '@/components/Toaster/Toaster';
import appConfig from '@/config/appConfig';
import domIdentifiers from '@/consts/app/domIdentifiers';
import useHistoryScrollTop from '@/hooks/useHistoryScrollTop';
import useRegUnregInterval from '@/hooks/useRegUnregInterval';
import rtlService from '@/services/app/rtlService';
import userBrowserService from '@/services/user/userBrowserService';
import userDeviceService from '@/services/user/userDeviceService';
import TermsOfUsePopup from '@/views/Tnc/TermsOfUsePopup/TermsOfUsePopup';

import LoaderGlobal from '../LoaderGlobal/LoaderGlobal';
import ModalsRoot from '../Modals/ModalsRoot';
import Notifications from '../Notifications/Notifications';
import useTncPageRedirection from './hooks/useTncPageRedirection';
import Logs from './Logs/Logs';

const AppLayout = ({component: Component, path, exact, strict}) => {
    const isTouchSupported = userDeviceService.isTouchSupported();
    const isRTLEnabled = rtlService.isRtlEnabled();

    useHistoryScrollTop();
    useRegUnregInterval();
    useTncPageRedirection();

    return (
        <Route
            path={path}
            exact={exact}
            strict={strict}
            render={(matchProps) => (
                <div
                    dir={isRTLEnabled ? 'rtl' : 'ltr'}
                    className={cn(domIdentifiers.APP_CLASSNAME, {
                        touch: isTouchSupported,
                        'ica-standalone': userBrowserService.isInStandaloneMode(),
                    })}
                >
                    <div className='relative flex min-h-screen flex-col items-center bg-romance font-iqos ml:bg-secondary'>
                        <Notifications />
                        <Toaster />
                        <TermsOfUsePopup />
                        <div
                            className={cn(
                                'flex w-full flex-1 flex-col',
                                domIdentifiers.COMPONENTS_CONTAINER_CLASS_NAME
                            )}
                        >
                            <div className='page-content flex flex-1 justify-center'>
                                <Component {...matchProps} />
                            </div>
                        </div>
                        <LoaderGlobal />
                        <ModalsRoot />
                        {appConfig.getIsDebug() && <Logs />}
                    </div>
                </div>
            )}
        />
    );
};

export default AppLayout;
