import cn from 'classnames';
import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';

import {selectNotificationsError, selectNotificationsInfo} from '@/state/selectors/notifications';

import NotificationBottom from './Notification/NotificationBottom';
import NotificationTop from './Notification/NotificationTop';

const Notifications = () => {
    const notificationsInfo = useSelector(selectNotificationsInfo);
    const notificationsError = useSelector(selectNotificationsError);

    return (
        <>
            <NotificationsContainer notifications={notificationsError} />
            <NotificationsContainer notifications={notificationsInfo} isBottom />
        </>
    );
};

const NotificationsContainer = ({notifications, isBottom}) => {
    if (!notifications?.length) return null;

    return (
        <div
            className={cn(
                'fixed z-[150] w-full md:right-8 md:max-w-[360px]',
                {'bottom-0  ml:left-1/2 ml:-translate-x-1/2': isBottom},
                {'top-0': !isBottom}
            )}
        >
            <div
                className={cn(
                    'sticky flex justify-center px-4 md:px-0',
                    {'pb-safe-offset-18': isBottom},
                    {'pt-safe-offset-4 md:pt-safe-offset-8': !isBottom}
                )}
            >
                <div className='flex w-full flex-col items-center gap-3'>
                    {notifications.map((notification) => (
                        <Fragment key={notification.notificationId}>
                            {!isBottom && <NotificationTop notification={notification} />}
                            {isBottom && <NotificationBottom notification={notification} />}
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Notifications;
