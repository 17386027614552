import React from 'react';

import appConfig from '@/config/appConfig';
import {WARNING_BANNER_SOURCE_TYPES} from '@/consts/aem/warningBannerSourceTypes';

import HealthWarning from './components/HealthWarning';
import HealthWarningExtended from './components/HealthWarningExtended';
import WeCare from './components/WeCare';

const WARNING_BANNERS_TYPES = {
    HEALTH_WARNING: 'HEALTH_WARNING',
    WE_CARE: 'WE_CARE',
    HEALTH_WARNING_EXTENDED: 'HEALTH_WARNING_EXTENDED',
};

const getBannerComponent = (type) => {
    switch (type) {
        case WARNING_BANNERS_TYPES.HEALTH_WARNING:
            return HealthWarning;
        case WARNING_BANNERS_TYPES.WE_CARE:
            return WeCare;
        case WARNING_BANNERS_TYPES.HEALTH_WARNING_EXTENDED:
            return HealthWarningExtended;
        default:
            return null;
    }
};

const getWarningBannersBySource = (source) => {
    const warningBanners = appConfig.getWarningBanners();
    const result = warningBanners?.filter((b) => b.pages?.some((page) => page === source));

    return result;
};

const WarningBanners = ({source, className, contentClassName}) => {
    const warningBanners = getWarningBannersBySource(source);

    return (
        <div className='w-full'>
            {warningBanners.map(({type, titleKey, descriptionKey}, index) => {
                const Component = getBannerComponent(type);
                const isPreviousBannerWeCare =
                    index > 0 ? warningBanners[index - 1].type === WARNING_BANNERS_TYPES.WE_CARE : false;
                const isSeparatorVisible = index > 0 && !isPreviousBannerWeCare;

                return (
                    <Component
                        key={index}
                        className={className}
                        contentClassName={contentClassName}
                        titleKey={titleKey}
                        descriptionKey={descriptionKey}
                        isSeparatorVisible={isSeparatorVisible}
                    />
                );
            })}
        </div>
    );
};

WarningBanners.SOURCES = WARNING_BANNER_SOURCE_TYPES;

export default WarningBanners;
