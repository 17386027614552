import {useSelector} from 'react-redux';

import appConfig from '@/config/appConfig';
import {AEM_DEVICE_SETTINGS_TYPES} from '@/consts/aem/aemDeviceSettingsTypes';
import {selectDeviceFirmwareVersion, selectHolderFirmwareVersion} from '@/state/selectors/device';
import versionUtils from '@/utils/versionUtils';

const useDeviceSettingsEnabled = ({type}) => {
    const deviceFirmwareVersion = useSelector(selectDeviceFirmwareVersion);
    const holderFirmwareVersion = useSelector(selectHolderFirmwareVersion);
    const configSettings = appConfig.getDeviceSettings(type);

    const getIsSettingEnabled = (settingType) => {
        return isSettingEnabled({configSettings, settingType, deviceFirmwareVersion, holderFirmwareVersion});
    };

    const isAemFlexBatteryEnabled = getIsSettingEnabled(AEM_DEVICE_SETTINGS_TYPES.AEM_FLEX_BATTERY);
    const isAutoStartEnabled = getIsSettingEnabled(AEM_DEVICE_SETTINGS_TYPES.AUTO_START);
    const isFlexPuffEnabled = getIsSettingEnabled(AEM_DEVICE_SETTINGS_TYPES.FLEX_PUFF);
    const isSmartGesturesEnabled = getIsSettingEnabled(AEM_DEVICE_SETTINGS_TYPES.SMART_GESTURES);
    const isIlluminationModeEnabled = getIsSettingEnabled(AEM_DEVICE_SETTINGS_TYPES.ILLUMINATION_MODE);
    const isVibrationEnabled = getIsSettingEnabled(AEM_DEVICE_SETTINGS_TYPES.VIBRATIONS);
    const isAnySettingEnabled =
        isAemFlexBatteryEnabled ||
        isAutoStartEnabled ||
        isFlexPuffEnabled ||
        isSmartGesturesEnabled ||
        isIlluminationModeEnabled ||
        isVibrationEnabled;

    return {
        isAnySettingEnabled,
        isAemFlexBatteryEnabled,
        isAutoStartEnabled,
        isFlexPuffEnabled,
        isSmartGesturesEnabled,
        isIlluminationModeEnabled,
        isVibrationEnabled,
    };
};

const isSettingEnabled = ({configSettings, settingType, holderFirmwareVersion, deviceFirmwareVersion}) => {
    const setting = configSettings.find((x) => x.settingType === settingType);

    if (!setting) return;

    const {firmwareRestrictions} = setting;

    if (firmwareRestrictions?.length) {
        return firmwareRestrictions.some((firmwareRestriction) => {
            const {comparisonType, isHolderRelated, value, version} = firmwareRestriction;
            const currentVersion = isHolderRelated ? holderFirmwareVersion : deviceFirmwareVersion;
            const isValid = compareFunction(comparisonType)(currentVersion, version);

            if (isValid) return value;

            return false;
        });
    }

    return true;
};

const compareFunction = (comparisonType) => {
    if (comparisonType === '>=') return versionUtils.isFirstVersionGreaterOrEqual;
    if (comparisonType === '>') return versionUtils.isFirstVersionGreater;
    if (comparisonType === '=') return versionUtils.isFirstVersionEqual;
    if (comparisonType === '<=') return versionUtils.isFirstVersionLessOrEqual;
    if (comparisonType === '<') return versionUtils.isFirstVersionLess;
};

export default useDeviceSettingsEnabled;
