import React from 'react';

import {PopupFullPage} from '@/components/Popup';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';

import HighlightsCarouselList from './components/HighlightsCarouselList';
import HighlightsHeader from './components/HighlightsHeader';
import useHighlightsHeaderText from './hooks/useHighlightsHeaderText';

export const FullscreenDesktopHighlights = ({carouselsFromConfig, isAutoPlayEnabled, onClose}) => {
    const {headerText, handleSlideChange} = useHighlightsHeaderText(carouselsFromConfig);

    useScrollToPreviousPosition();

    return (
        <PopupFullPage
            className='z-10 bg-primary'
            isCloseVisible
            onClose={onClose}
            closeButtonClass='text-white top-7 right-13'
        >
            <div className='flex h-full flex-col pt-8'>
                <HighlightsHeader title={headerText} />
                <div className='min-h-0 flex-1 py-8'>
                    <HighlightsCarouselList
                        carouselsFromConfig={carouselsFromConfig}
                        isAutoPlayEnabled={isAutoPlayEnabled}
                        onCarouselChange={handleSlideChange}
                        onSlideChange={handleSlideChange}
                    />
                </div>
            </div>
        </PopupFullPage>
    );
};

export default FullscreenDesktopHighlights;
