import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {
    CommentIcon,
    SocialChannelFacebookIcon,
    SocialChannelInstagramIcon,
    SocialChannelTelegramIcon,
    SocialChannelTwitterIcon,
    SocialChannelYoutubeIcon,
} from '@/components/Icons';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';

import {SOCIAL_CHANNEL_ICON_IDS} from '../consts/socialChannels';
import ContactUsSection from './ContactUsSection';

const ContactUsSocialChannels = ({className, socialChannels}) => {
    if (!socialChannels || !socialChannels.some((c) => c.action)) return null;

    return (
        <ContactUsSection className={className}>
            <ContactUsSection.Icon>
                <CommentIcon />
            </ContactUsSection.Icon>
            <ContactUsSection.Title itemName='socialChannelsTitle' />
            <ContactUsSection.Description itemName='socialChannelsDesc' />
            <div className='flex w-full justify-center gap-3 ml:gap-6'>
                {socialChannels.map(({id, action}, index) => (
                    <ContactUsSocialChannel key={index} id={id} action={action} />
                ))}
            </div>
        </ContactUsSection>
    );
};

const ContactUsSocialChannel = ({id, action}) => {
    const redirectToExternalLink = useConfigExternalLink(action);
    const icon = getSocialChannelIcon(id);
    const {icon: Icon, background} = icon || {};

    return (
        <ButtonRaw
            className='overflow-hidden rounded-[4px] ml:max-w-[146px] ml:flex-1'
            onClick={redirectToExternalLink}
        >
            <div style={{background, color: background}} className='flex w-full justify-center'>
                <Icon />
            </div>
        </ButtonRaw>
    );
};

const getSocialChannelIcon = (id) => {
    switch (id) {
        case SOCIAL_CHANNEL_ICON_IDS.TWITTER:
            return {icon: SocialChannelTwitterIcon, background: 'black'};
        case SOCIAL_CHANNEL_ICON_IDS.FACEBOOK:
            return {icon: SocialChannelFacebookIcon, background: '#0866FF'};
        case SOCIAL_CHANNEL_ICON_IDS.YOUTUBE:
            return {icon: SocialChannelYoutubeIcon, background: '#FF0000'};
        case SOCIAL_CHANNEL_ICON_IDS.TELEGRAM:
            return {icon: SocialChannelTelegramIcon, background: '#2AABEE'};
        case SOCIAL_CHANNEL_ICON_IDS.INSTAGRAM:
            return {
                icon: SocialChannelInstagramIcon,
                background: 'linear-gradient(-19.58deg, rgb(245, 31, 31) 0%, rgb(193, 31, 189) 100%)',
            };
        default:
            return null;
    }
};

export default ContactUsSocialChannels;
