import React from 'react';
import {RemoveScroll} from 'react-remove-scroll';

import {twx} from '@/utils/tailwindUtils';

import AppPopup from './AppPopup';
import styles from './Popup.module.scss';

const PopupRaw = ({className, overlayClassName, isHidePopup, hasNavbarOffset, ...rest}) => {
    return (
        <RemoveScroll enabled={!isHidePopup}>
            <AppPopup
                isOpen
                className={twx(styles.PopupContainer, className)}
                overlayClassName={twx(
                    styles.Overlay,
                    overlayClassName,
                    hasNavbarOffset && 'ml:left-[var(--sidebar-width)] ml:max-w-[calc(100%-var(--sidebar-width))]'
                )}
                {...rest}
            />
        </RemoveScroll>
    );
};

export default PopupRaw;
