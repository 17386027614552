import React from 'react';
import {Switch} from 'react-router-dom';

import ROUTE_PATHS from '@/consts/route/routePaths';
import AdvancedDiagnosticPage from '@/containers/AdvancedDiagnosticPage/AdvancedDiagnosticPage';
import AutoDeliveryAsapPage from '@/containers/AutoDeliveryPage/AutoDeliveryAsapPage';
import AutoDeliveryPage from '@/containers/AutoDeliveryPage/AutoDeliveryPage';
import AutoDeliverySkipPage from '@/containers/AutoDeliveryPage/AutoDeliverySkipPage';
import CleaningGuidePage from '@/containers/CleaningGuidePages/CleaningGuidePage';
import ConsumerProfilePage from '@/containers/ConsumerProfilePage/ConsumerProfilePage';
import DevicesPage from '@/containers/DevicesPage/DevicesPage';
import ErrorHandlingPage from '@/containers/ErrorHandlingPage/ErrorHandlingPage';
import HomePage from '@/containers/HomePage/HomePage';
import InboxPage from '@/containers/Inbox/InboxPage';
import InstallPwaPage from '@/containers/InstallPwaPage/InstallPwaPage';
import LoginPage from '@/containers/LoginPage/LoginPage';
import ModalsPage from '@/containers/Modals/ModalsPage';
import QuickOrderPage from '@/containers/QuickOrder/QuickOrderPage';
import SelectYourDevicePage from '@/containers/SelectYourDevicePage/SelectYourDevicePage';
import ShopPage from '@/containers/ShopPage/ShopPage';
import SplashScreen from '@/containers/SplashScreen/SplashScreen';
import QuickStartGuidePage from '@/containers/TipsAndTutorials/QuickStartGuidePage';
import TipsAndTutorialsPage from '@/containers/TipsAndTutorials/TipsAndTutorialsPage';
import TNC from '@/containers/TNC/TNC';
import TncDetailsPage from '@/containers/TncDetailsPage/TncDetailsPage';
import UsbRequiredPage from '@/containers/UsbRequiredPage/UsbRequiredPage';
import useWithLocale from '@/hooks/useWithLocale';

import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
    const withLocale = useWithLocale();

    return (
        <Switch>
            <AppRoute path={withLocale(ROUTE_PATHS.ERROR_PAGE)} component={ErrorHandlingPage} />
            <PublicRoute exact path={withLocale(ROUTE_PATHS.ROOT)} component={SplashScreen} />
            <PublicRoute path={withLocale(ROUTE_PATHS.INSTALL_PWA)} component={InstallPwaPage} />
            <PublicRoute path={withLocale(ROUTE_PATHS.LOGIN)} component={LoginPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.TNC)} component={TNC} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.TNC_DETAILS)} component={TncDetailsPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.HOME_PAGE)} component={HomePage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.SHOP_PAGE)} component={ShopPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.QUICK_ORDER)} component={QuickOrderPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.INBOX_PAGE_PARAMETERIZED)} component={InboxPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.ADVANCE_DIAGNOSTIC)} component={AdvancedDiagnosticPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.AUTO_DELIVERY)} exact component={AutoDeliveryPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.AUTO_DELIVERY_SKIP)} component={AutoDeliverySkipPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.AUTO_DELIVERY_ASAP)} component={AutoDeliveryAsapPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.DEVICES_PAGE)} component={DevicesPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.CLEAN_TUTORIAL)} component={CleaningGuidePage} />
            <PrivateRoute
                path={withLocale(ROUTE_PATHS.TIPS_AND_TUTORIALS_PARAMETERIZED)}
                component={TipsAndTutorialsPage}
            />
            <PrivateRoute
                path={withLocale(ROUTE_PATHS.QUICK_START_GUIDE_PARAMETERIZED)}
                component={QuickStartGuidePage}
            />
            <PrivateRoute path={withLocale(ROUTE_PATHS.MODALS)} component={ModalsPage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.CONSUMER_PROFILE)} component={ConsumerProfilePage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.SELECT_YOUR_DEVICE)} component={SelectYourDevicePage} />
            <PrivateRoute path={withLocale(ROUTE_PATHS.USB_REQUIRED)} component={UsbRequiredPage} />
        </Switch>
    );
};

export default Routes;
