import React from 'react';

import Icon from '../Icon/Icon';

const StealthIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <path
                d='M24 31.391a7.391 7.391 0 1 0 0-14.782 7.391 7.391 0 0 0 0 14.782z'
                stroke='#232029'
                strokeWidth='2'
                fill='none'
            />
            <path
                d='M24 34.7v.8M24 13.3V13M34.7 24h.3M12.5 24h.8M31.566 31.566 32 32M16.434 16.434 16 16M31.566 16.434 32 16M16 32l.434-.434'
                stroke='#232029'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default StealthIcon;
