import React from 'react';

import Icon from '../Icon/Icon';

const AemEcoBlueIcon = (props) => {
    return (
        <Icon width='65' height='64' {...props}>
            <path
                clipRule='evenodd'
                d='M19.755 56.886c-1.091 0-2.26-1.794-3.503-5.38V11.391c0-1.674.696-3.25 2.09-4.725h7.498c1.393 1.476 2.09 3.05 2.09 4.725v40.113c-1.244 3.587-2.412 5.38-3.503 5.38h-4.672z'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
                fill='none'
            />
            <path
                d='M22.193 31.637a2.17 2.17 0 1 0 0-4.342 2.17 2.17 0 0 0 0 4.342z'
                fill='#34303D'
                stroke='#00D1D2'
                strokeWidth='2'
                className='icon-accent-stroke'
            />
            <circle
                cx='36.487'
                cy='44.219'
                r='11.667'
                fill='#00D1D2'
                stroke='#34303D'
                strokeWidth='2'
                className='icon-accent-fill'
            />
            <path
                d='m34.824 45.33 1.629-2.215h-1.468l-.935 1.35-.954-1.35h-1.503l1.628 2.17-1.628 2.267h1.476l.926-1.377.964 1.377h1.511l-1.646-2.223zm2.545-3.322v1.161l1.71-.774v5.157h1.26v-6.408h-1.017l-1.953.864z'
                fill='#34303D'
            />
            <path
                d='m34.824 45.33-.162-.12-.087.12.088.119.16-.12zm1.629-2.215.16.119.235-.319h-.395v.2zm-1.468 0v-.2h-.104l-.06.087.165.113zm-.935 1.35-.164.116.165.233.163-.235-.164-.114zm-.954-1.35.163-.115-.06-.085h-.103v.2zm-1.503 0v-.2h-.4l.24.32.16-.12zm1.628 2.17.163.116.086-.12-.089-.117-.16.12zm-1.628 2.267-.163-.116-.227.316h.39v-.2zm1.476 0v.2h.106l.06-.088-.166-.112zm.926-1.377.164-.114-.167-.24-.162.243.166.111zm.964 1.377-.164.115.06.085h.104v-.2zm1.511 0v.2h.398l-.237-.319-.16.12zm-1.485-2.104 1.629-2.214-.323-.237-1.629 2.214.323.237zm1.468-2.533h-1.468v.4h1.468v-.4zm-1.632.087-.936 1.35.329.227.936-1.35-.329-.227zm-.608 1.348L33.259 43l-.327.23.954 1.35.327-.23zm-1.117-1.435h-1.503v.4h1.503v-.4zm-1.663.32 1.629 2.17.32-.24-1.63-2.17-.32.24zm1.626 1.933-1.629 2.268.325.233 1.629-2.268-.325-.233zm-1.466 2.584h1.476v-.4h-1.477v.4zm1.641-.088.927-1.377-.331-.223-.927 1.377.331.223zm.598-1.374.963 1.377.327-.23-.963-1.376-.327.229zm1.127 1.462h1.511v-.4H34.96v.4zm1.672-.319-1.647-2.223-.321.239 1.647 2.223.321-.239zm.738-5.425-.081-.183-.12.053v.13h.2zm0 1.161h-.2v.31l.282-.127-.082-.183zm1.71-.774h.2v-.31l-.283.128.083.182zm0 5.157h-.2v.2h.2v-.2zm1.26 0v.2h.2v-.2h-.2zm0-6.408h.2v-.2h-.2v.2zm-1.017 0v-.2h-.043l-.038.017.08.183zm-2.153.864v1.161h.4v-1.16h-.4zm.282 1.344 1.71-.774-.165-.365-1.71.774.165.365zm1.428-.957v5.157h.4v-5.157h-.4zm.2 5.357h1.26v-.4h-1.26v.4zm1.46-.2v-6.408h-.4v6.408h.4zm-.2-6.608h-1.017v.4h1.017v-.4zm-1.098.017-1.953.864.162.366 1.953-.864-.162-.365z'
                fill='#34303D'
            />
        </Icon>
    );
};

export default AemEcoBlueIcon;
