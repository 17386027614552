import React from 'react';

import Icon from '../Icon/Icon';

const SocialChannelTwitterIcon = (props) => {
    return (
        <Icon width='63' height='44' fill='none' {...props}>
            <g clipPath='url(#clip0_3955_10030)' fill='currentColor'>
                <path
                    d='M33.0124 20.6179L39.7133 13H38.1254L32.307 19.6145L27.6599 13H22.3L29.3274 23.0023L22.3 30.9908H23.888L30.0324 24.0056L34.9401 30.9908H40.3L33.012 20.6179H33.0124ZM30.8375 23.0904L30.1254 22.0944L24.4602 14.1691H26.8992L31.4712 20.5651L32.1832 21.5611L38.1262 29.8748H35.6871L30.8375 23.0908V23.0904Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_3955_10030'>
                    <rect width='18' height='18' fill='white' transform='translate(22.3 13)' />
                </clipPath>
            </defs>
        </Icon>
    );
};

export default SocialChannelTwitterIcon;
