import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import RemoteImage from '@/components/Image/RemoteImage';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';

const ContactUsHeader = ({imageMedia}) => {
    const isMobile = useIsMobileLayout();

    return (
        <div className='flex flex-col gap-8 ml:mt-0'>
            <div className='flex flex-col gap-4 px-4 ml:gap-2 ml:px-0'>
                <h1 className='mb-0 ml:text-left ml:text-36 ml:leading-12'>
                    <AemEditableText pageName={routePaths.CONTACT_US} itemName='contactUsTitle' />
                </h1>
                <p className='ml:text-left ml:text-18 ml:leading-155 ml:tracking-02'>
                    <AemEditableText pageName={routePaths.CONTACT_US} itemName='contactUsDesc' />
                </p>
            </div>
            {isMobile && <RemoteImage className='w-full bg-center object-cover ml:h-[335px]' imageMedia={imageMedia} />}
        </div>
    );
};

export default ContactUsHeader;
