import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import routePaths from '@/consts/route/routePaths';

const ContactUsCallSchedule = ({workingDays}) => {
    const nonEmptyWorkingDays = workingDays.filter(({title, hours}) => title && hours);

    if (!nonEmptyWorkingDays.length) {
        return null;
    }

    return (
        <ul className='mx-auto w-full rounded-[4px] border border-border ml:max-w-[824px]'>
            <ScheduleItem>
                <ScheduleLabel>
                    <AemEditableText pageName={routePaths.CONTACT_US} itemName='callUsPhoneHours' />
                </ScheduleLabel>
            </ScheduleItem>

            {workingDays.map(({title, hours}, i) => (
                <ScheduleItem key={i}>
                    <ScheduleLabel>{title}</ScheduleLabel>
                    <ScheduleHours>{hours}</ScheduleHours>
                </ScheduleItem>
            ))}
        </ul>
    );
};

const ScheduleItem = ({children}) => {
    return (
        <li className='flex justify-between border-y border-border px-4 py-6 text-left first:border-t-0 last:border-b-0'>
            {children}
        </li>
    );
};

const ScheduleLabel = ({children}) => {
    return <span className='text-16 font-bold leading-15 tracking-02'>{children}</span>;
};

const ScheduleHours = ({children}) => {
    return <span className='ml-6 shrink-0 text-16 leading-15'>{children}</span>;
};

export default ContactUsCallSchedule;
