const unique = (a) => Array.from(new Set(a));

const toArray = (...args) => args.flat().filter((item) => item !== undefined);

const splitArray = (input, itemsPerChunk) => {
    return input.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / itemsPerChunk);

        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push(item);

        return result;
    }, []);
};

const sortArrayWithOrderField = (array) => {
    if (!array) return [];

    return array.sort((a, b) => {
        if (!a.order && !b.order) return 0;

        if (!a.order && b.order) return 1;

        if (a.order && !b.order) return -1;

        return a.order - b.order;
    });
};

const filterEmptyObjects = (array) => array?.filter((item) => !!item) || [];

const updateArrayImmutably = (array, ...items) => {
    const fromTupleToObject = ([index, updatedItem]) => ({[index]: updatedItem});
    const mappedItems = items.map(fromTupleToObject);
    const updatedArray = Object.assign([], array, ...mappedItems);

    return updatedArray;
};

const groupBy = (items, key) => {
    return items.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const objectToArray = (object) => {
    return Object.entries(object).map(([k, v]) => {
        return {key: k, item: v};
    });
};

export default {
    toArray,
    splitArray,
    unique,
    sortArrayWithOrderField,
    filterEmptyObjects,
    updateArrayImmutably,
    groupBy,
    objectToArray,
};
