import {useGetAutoDeliveryDetailsExtended} from '@/api/consumerApi';
import routePaths from '@/consts/route/routePaths';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import AutoDeliveryActiveProgress from '../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryPaymentPendingButton from '../../../../views/AutoDelivery/AutoDeliveryPaymentPending/AutoDeliveryPaymentPendingButton';
import AutoDeliveryStatusTitle from '../../../../views/AutoDelivery/AutoDeliveryStatusTitle';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';
import AutoDeliveryPageDesktopLayout from '../components/AutoDeliveryPageDesktopLayout';

const StatusPaymentPendingDesktop = ({amplitudeEvent}) => {
    const {
        data: {
            autoDelivery: {status},
            order: {deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();

    return (
        <AutoDeliveryPageDesktopLayout>
            <AutoDeliveryPageDesktopLayout.Start>
                <AutoDeliveryOrderHeader />
                <AutoDeliveryStatusTitle
                    className='mt-8 text-28'
                    title={
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='autoDeliveryPaymentPendingTitle'
                        />
                    }
                />
                <AutoDeliveryActiveProgress status={status} className='mt-8' />
                <AutoDeliveryOrder className='mt-8 p-0' products={products} />
                <AutoDeliveryDetailsFooter>
                    <AutoDeliveryPaymentPendingButton className='mt-0 self-start' amplitudeEvent={amplitudeEvent} />
                </AutoDeliveryDetailsFooter>
            </AutoDeliveryPageDesktopLayout.Start>
            <AutoDeliveryPageDesktopLayout.End>
                <AutoDeliveryOrderAddress className='p-0' address={deliveryAddress} />
            </AutoDeliveryPageDesktopLayout.End>
        </AutoDeliveryPageDesktopLayout>
    );
};

export default StatusPaymentPendingDesktop;
