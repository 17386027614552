import routePaths from '@/consts/route/routePaths';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {CalendarIcon} from '../../../../components/Icons';
import useDeliveryDate from '../hooks/useDeliveryDate';
import DeliveryAsapInfo from './DeliveryAsapInfo';
import EditDeliveryPopupTopSection from './EditDeliveryPopupTopSection';

const DeliveryAsapSection = () => {
    const {days, asapDeliveryDate, asapDeliveryDay} = useDeliveryDate();

    return (
        <EditDeliveryPopupTopSection
            header={<AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliverAsapHeaderTitle' />}
            title={asapDeliveryDate}
            subtitle={
                <AemEditableText
                    pageName={routePaths.AUTO_DELIVERY}
                    itemName='deliverAsapHeaderSubTitle'
                    contentList={[days]}
                />
            }
            infoText={<DeliveryAsapInfo />}
            icon={<CalendarIcon date={asapDeliveryDay} className='text-tertiary' />}
        />
    );
};

export default DeliveryAsapSection;
