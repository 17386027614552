import React from 'react';

import {SupportServiceIcon} from '@/components/Icons';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';

import ContactUsCallSchedule from './ContactUsCallSchedule';
import ContactUsSection from './ContactUsSection';

const ContactUsCallUs = ({className, callUs}) => {
    const isMobile = useIsMobileLayout();
    const {phoneNumber, workingDays} = callUs;

    if (!phoneNumber) return null;

    return (
        <ContactUsSection className={className}>
            <ContactUsSection.Icon>
                <SupportServiceIcon />
            </ContactUsSection.Icon>
            <ContactUsSection.Title itemName='callUsTitle' />
            <ContactUsSection.Description itemName='callUsDesc' contentList={[phoneNumber]} />
            <ContactUsCallSchedule workingDays={workingDays} />
            {isMobile && (
                <ContactUsSection.Button
                    itemName='callUsButton'
                    to={`tel:${phoneNumber}`}
                    primary
                    light
                    isExternalLink
                />
            )}
        </ContactUsSection>
    );
};

export default ContactUsCallUs;
