import {track} from '@amplitude/analytics-browser';

import analyticsEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';

const trackEnableNotificationScreen = () => {
    track(analyticsEventNames.ENABLE_NOTIFICATION);
};

const trackEnableNotificationScreenNextButton = () => {
    track(analyticsEventNames.NEXT_BUTTON_CLICKED);
};

const trackSubscribeToReceiveMessages = () => {
    track(analyticsEventNames.NOTIFICATION_ALLOWED, {
        [analyticsEventProperties.SUCCESS]: true,
    });
};

const trackUnsubscribeToReceiveMessages = () => {
    track(analyticsEventNames.NOTIFICATION_ALLOWED, {
        [analyticsEventProperties.SUCCESS]: false,
    });
};

export default {
    trackEnableNotificationScreen,
    trackEnableNotificationScreenNextButton,
    trackSubscribeToReceiveMessages,
    trackUnsubscribeToReceiveMessages,
};
