import cn from 'classnames';
import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import BackButton from '@/components/BackButton/BackButton';
import {StickyHeader} from '@/components/Layout';
import {SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useLayout from '@/hooks/inbox/useLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import SelectDeviceItem from './SelectDeviceItem';

const SelectYourDeviceContent = ({supportedDeviceData, onBack}) => {
    const {isMobile, isDesktop} = useLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE]);

    return (
        <div className='flex flex-1 flex-col'>
            <div className='pt-safe-offset-sticky-header ml:mx-16'>
                <div className='mx-4 ml:mx-0'>
                    {isDesktop && <BackButton className='mb-4 mt-8' onBack={onBack} />}
                    {isMobile && (
                        <StickyHeader onBack={onBack} title={localizedStrings[SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE]} />
                    )}
                    <h1 className='m-0 text-left text-32 ml:text-36'>
                        <AemEditableText pageName={routePaths.DEVICES_PAGE} itemName='SelectYourDeviceTitle' />
                    </h1>
                </div>
                <ul className={cn('mt-8 grid w-full grid-cols-1 gap-4 ml:mt-10 ml:grid-cols-3')}>
                    {supportedDeviceData.map(({type, name, images}, i) => {
                        return (
                            <SelectDeviceItem
                                alt={name}
                                image={images}
                                key={type}
                                name={name}
                                type={type}
                                isLast={i === supportedDeviceData.length - 1}
                            />
                        );
                    })}
                </ul>
            </div>
            <WarningBanners className='pt-4' source={WarningBanners.SOURCES.SELECT_DEVICE} />
        </div>
    );
};

export default SelectYourDeviceContent;
