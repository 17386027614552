import React from 'react';

import StickyHeader from '@/components/Layout/StickyHeader';
import {LANGUAGE_SELECT_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import LanguageSelectContentSelectList from '../LanguageSelectContent/LanguageSelectContentSelectList';
import LanguageSelectContentTitle from '../LanguageSelectContent/LanguageSelectContentTitle';

const LanguageSelectPopupContent = ({selectedLanguageCode, selectListData, onLanguageChange, onClose}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div className='relative mb-8 flex h-full w-full flex-col items-center'>
            <StickyHeader onBack={onClose} title={localizedStrings[LANGUAGE_SELECT_PAGE_HEADER_TITLE]} />
            <LanguageSelectContentTitle className='text-center mt-safe-offset-[calc(var(--sticky-header-height)+16px)]' />
            <LanguageSelectContentSelectList
                className='py-8'
                selectedValue={selectedLanguageCode}
                data={selectListData}
                onChange={onLanguageChange}
            />
        </div>
    );
};

export default LanguageSelectPopupContent;
