const isFirstVersionGreater = (ver1, ver2) => _compare(ver1, ver2) === 1;

const isFirstVersionGreaterOrEqual = (ver1, ver2) => {
    const comparisonResult = _compare(ver1, ver2);

    return comparisonResult === 1 || comparisonResult === 0;
};

const isFirstVersionEqual = (ver1, ver2) => {
    const comparisonResult = _compare(ver1, ver2);

    return comparisonResult === 0;
};

const isFirstVersionLessOrEqual = (ver1, ver2) => {
    const comparisonResult = _compare(ver1, ver2);

    return comparisonResult === -1 || comparisonResult === 0;
};

const isFirstVersionLess = (ver1, ver2) => {
    const comparisonResult = _compare(ver1, ver2);

    return comparisonResult === -1;
};

const _compare = (ver1, ver2) => {
    if (ver1 && ver2) {
        return ver1.localeCompare(ver2, undefined, {
            numeric: true,
            sensitivity: 'base',
        });
    }
};

export default {
    isFirstVersionEqual,
    isFirstVersionGreater,
    isFirstVersionGreaterOrEqual,
    isFirstVersionLessOrEqual,
    isFirstVersionLess,
};
