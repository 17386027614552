import React from 'react';

import {ButtonRaw} from '@/components/Button';
import RemoteImage from '@/components/Image/RemoteImage';
import PageSection from '@/components/Layout/PageSection';
import appConfig from '@/config/appConfig';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';
import amplitudeShopPageTracking from '@/services/analytics/amplitude/amplitudeShopPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {filterSectionsById} from '@/services/pageSectionService';
import arrayUtils from '@/utils/arrayUtils';

const getProducts = (id) => {
    const productsFromConfig = appConfig.getShopByProductSection();
    const products = filterSectionsById({list: productsFromConfig, id});
    const productsWithAction = products?.filter((p) => p?.action);

    if (!productsWithAction?.length) return null;

    const productsWithOrder = arrayUtils.sortArrayWithOrderField(
        productsWithAction?.filter((p) => p?.order !== undefined)
    );
    const productsWithoutOrder = productsWithAction.filter((p) => p?.order === undefined);

    return [...productsWithOrder, ...productsWithoutOrder];
};

const ShopByProductSection = ({id, className}) => {
    const products = getProducts(id);
    const localizedStrings = getLocalizedStrings();
    const titleText = usePageSectionTitle(id);

    if (!products?.length) return;

    return (
        <PageSection className={className}>
            <PageSection.Title text={titleText} />
            <div className='mt-4 grid grid-cols-2 gap-x-2 gap-y-4 md:mt-6 md:gap-4 ml:mt-8 ml:grid-cols-4 ml:gap-x-4'>
                {products.map(({imageMedia, titleKey, action, amplitudeTrackingValue}, index) => (
                    <ShopByProductTile
                        imageMedia={imageMedia}
                        title={localizedStrings[titleKey]}
                        action={action}
                        amplitudeTrackingValue={amplitudeTrackingValue}
                        key={index}
                    />
                ))}
            </div>
        </PageSection>
    );
};

const ShopByProductTile = ({imageMedia, title, action, amplitudeTrackingValue}) => {
    const redirectToExternalLink = useConfigExternalLink(action);
    const onTileClick = () => {
        amplitudeShopPageTracking.trackShopByProductBannerClick(amplitudeTrackingValue);
        googleAnalyticsService.trackShopByProductBannerClick(amplitudeTrackingValue);
        redirectToExternalLink();
    };

    return (
        <ButtonRaw
            className='flex max-w-[250px] flex-col items-center justify-between gap-4 rounded-xl bg-secondary p-4 md:w-full md:max-w-none md:rounded-3xl md:bg-romance md:p-8'
            onClick={action && onTileClick}
        >
            <span className='line-clamp-2 w-full text-14 font-bold leading-155 text-black md:text-24'>{title}</span>
            <RemoteImage className='h-full object-contain md:h-[233px]' imageMedia={imageMedia} />
        </ButtonRaw>
    );
};

export default ShopByProductSection;
