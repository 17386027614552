import {useSelector} from 'react-redux';

import {AutoStartDisabledIcon, AutoStartEnabledIcon} from '@/components/Icons';
import {ON} from '@/consts/uam/onOffTypes';
import {selectDeviceSettings} from '@/state/selectors/device';

const useAutoStartData = () => {
    const {autoStartHeating} = useSelector(selectDeviceSettings) || {};

    if (!autoStartHeating) return;

    const {featureState} = autoStartHeating;
    const isEnabled = featureState === ON;
    const itemName = isEnabled ? 'autoStartOn' : 'autoStartOff';
    const icon = isEnabled ? AutoStartEnabledIcon : AutoStartDisabledIcon;

    return {
        itemName,
        icon,
    };
};

export default useAutoStartData;
