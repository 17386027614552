import React from 'react';
import {twMerge} from 'tailwind-merge';

const ContentWrapper = (props) => {
    const {className, children, testId, lang} = props;

    return (
        <div
            className={twMerge(
                'mx-8 my-0 flex flex-col items-center md:mx-12 ml:mx-16 lg:mx-auto lg:max-w-[1280px]',
                className
            )}
            data-testid={testId}
            lang={lang}
        >
            {children}
        </div>
    );
};

export default ContentWrapper;
