const onTouchStartEvent = 'ontouchstart';

const isMobile = {
    Android: () => navigator.userAgent.match(/Android/i),
    iOS: () => navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iOS/i),
    any: () => isMobile.Android() || isMobile.iOS(),
};

const isTouchSupported = () => {
    return onTouchStartEvent in window || navigator.maxTouchPoints || navigator.msMaxTouchPoints;
};

const isMobileDevice = () => !!isMobile.any();

const isIOSDevice = () => navigator.userAgent.match(/iOS/i);

export default {
    isTouchSupported,
    isMobileDevice,
    isIOSDevice,
};
