import * as localizationKeys from '@/consts/localization/localizationKeys';
import iccMarketService from '@/services/icc/iccMarketService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import log from '@/services/logger/log';
import {updateConsumerProductData} from '@/state/slices/consumerSlice';
import {hideDeviceSettingsLoader, showDeviceSettingsLoader} from '@/state/slices/loadersSlice';
import {setNotificationError, setNotificationInfo} from '@/state/slices/notificationsSlice';
import {dispatch} from '@/state/store';

const useOnRenameClick = () => {
    const localizedStrings = getLocalizedStrings();
    const onRenameClick = async (deviceName, isDefaultMode, codentify) => {
        const processedDeviceName = deviceName ? deviceName.trim() : null;

        if (isDefaultMode || (!isDefaultMode && deviceName)) {
            dispatch(showDeviceSettingsLoader());

            try {
                await iccMarketService.setConsumerProductName({
                    productName: processedDeviceName,
                    codentifyID: codentify,
                    throwException: true,
                });
                dispatch(
                    setNotificationInfo(
                        localizedStrings[localizationKeys.DEVICE_RENAME_NOTIFICATION_SUCCESS_TEXT],
                        true
                    )
                );
                dispatch(
                    updateConsumerProductData({
                        codentify,
                        productName: processedDeviceName,
                    })
                );
            } catch (e) {
                dispatch(
                    setNotificationError(localizedStrings[localizationKeys.DEVICE_RENAME_NOTIFICATION_FAILED_TEXT])
                );
                log.info(`onRenameClick error: ${e}`);
            }
            dispatch(hideDeviceSettingsLoader());
        }
    };

    return {onRenameClick};
};

export default useOnRenameClick;
