import * as RadioGroup from '@radix-ui/react-radio-group';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import deviceStateService from '@/services/device/deviceStateService';
import productHelpers from '@/services/product/productHelpers';
import {selectConsumerProductsMergedInfo} from '@/state/selectors/consumer';
import {selectDeviceCodentify} from '@/state/selectors/device';
import {twx} from '@/utils/tailwindUtils';

import DeviceListItem from './DevicesListItem/DevicesListItem';
import UnregisterDevicePopup from './UnregisterDevicePopup';
import useDeviceUnregistrationPopup from './useDeviceUnregistration';

const DevicesList = ({className, onClose}) => {
    const devices = useSelector(selectConsumerProductsMergedInfo);
    const selectedDeviceCodentify = useSelector(selectDeviceCodentify);
    const [touchedDeviceId, setTouchedDeviceId] = useState();
    // We don't want to sort devices when another device is selected
    const sortedDevices = useMemo(
        () =>
            productHelpers.promoteProductByCodentify({
                products: devices,
                codentify: selectedDeviceCodentify,
            }),
        [devices]
    );
    const {isPopupOpened, popupDeviceName, handleUnregisterClick, handlePopupUnregisterClick, handlePopupCancelClick} =
        useDeviceUnregistrationPopup({devices: sortedDevices, onClose});

    const onChange = (codentify) => {
        deviceStateService.storeSelectedDeviceCodentify(codentify);
        onClose();
    };

    return (
        <>
            <RadioGroup.Root
                onValueChange={onChange}
                className={twx('flex flex-col overflow-auto overflow-x-hidden ml:overflow-hidden', className)}
                required
            >
                {sortedDevices.map((device, index) => {
                    const {codentify} = device;

                    return (
                        <React.Fragment key={codentify}>
                            <DeviceListItem
                                device={device}
                                isSelected={codentify === selectedDeviceCodentify}
                                touchedDeviceId={touchedDeviceId}
                                onDeviceTouch={(deviceId) => setTouchedDeviceId(deviceId)}
                                onUnregisterClick={() => handleUnregisterClick(index)}
                            />
                            {index < sortedDevices.length - 1 && (
                                <hr className='mx-4 h-px shrink-0 bg-border ml:mx-0' />
                            )}
                        </React.Fragment>
                    );
                })}
            </RadioGroup.Root>
            {isPopupOpened && (
                <UnregisterDevicePopup
                    deviceName={popupDeviceName}
                    onUnregisterClick={handlePopupUnregisterClick}
                    onCancelClick={handlePopupCancelClick}
                />
            )}
        </>
    );
};

export default DevicesList;
