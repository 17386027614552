import React from 'react';

import {twx} from '@/utils/tailwindUtils';

import PopupRaw from './PopupRaw';

const PopupCentered = (props) => {
    const {className, ...rest} = props;

    return (
        <PopupRaw
            className={twx('absolute top-1/2 h-min w-auto -translate-y-1/2 ml:left-1/2 ml:-translate-x-1/2', className)}
            {...rest}
        />
    );
};

export default PopupCentered;
