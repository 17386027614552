import React, {useState} from 'react';

import {AppButton, ButtonRaw} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {ArrowIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import PopupRaw from '@/components/Popup/PopupRaw';
import PopupStickedWithSwipeClose from '@/components/Popup/PopupStickedWithSwipeClose';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import amplitudeDeviceTracking from '@/services/analytics/amplitude/amplitudeDeviceTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import {twx} from '@/utils/tailwindUtils';

import DevicesList from './DevicesList';
import MissingDeviceNotification from './MissingDeviceNotification';

const DevicesListPopup = ({onClose}) => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();

    const onAddDeviceClick = () => {
        amplitudeDeviceTracking.trackAddNewDeviceClick();
        googleAnalyticsService.trackAddNewDeviceClick();
        appRouterService.forwardToSelectYourDevicePage();
    };

    const props = {
        title: localizedStrings[localizationKeys.MY_DEVICES_LIST_TITLE],
        button: localizedStrings[localizationKeys.MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT],
        onAddDeviceClick,
        onPopupClose: onClose,
    };

    return isMobile ? <MobilePopup {...props} /> : <DesktopPopup {...props} />;
};

const YourDevicesHeader = ({children, className}) => {
    return <h2 className={twx('mb-0 text-24', className)}>{children}</h2>;
};

const AddDeviceButton = ({children, className, onClick}) => {
    return (
        <AppButton primary light className={twx('shrink-0', className)} onClick={onClick}>
            {children}
        </AppButton>
    );
};

const MobilePopup = ({title, button, onAddDeviceClick, onPopupClose}) => {
    return (
        <PopupStickedWithSwipeClose
            className='flex max-h-[calc(100vh-56px)] flex-col overflow-hidden px-4 pb-safe-offset-8'
            swipeAreaClassName='py-4'
            onClose={onPopupClose}
            isSwipeDashVisible={false}
        >
            <div className='flex items-center justify-between'>
                <YourDevicesHeader>{title}</YourDevicesHeader>
                <ButtonRaw onClick={onPopupClose} className='shrink-0'>
                    <ArrowIcon className='rotate-90' />
                </ButtonRaw>
            </div>
            <MissingDeviceNotification className='mt-8' />
            <DevicesList className='-mx-4 mt-4' onClose={onPopupClose} />
            <AddDeviceButton className='mx-auto mt-8' onClick={onAddDeviceClick}>
                {button}
            </AddDeviceButton>
        </PopupStickedWithSwipeClose>
    );
};

const DesktopPopup = ({title, button, onAddDeviceClick, onPopupClose}) => {
    const [scrollContainer, setScrollContainer] = useState(null);

    return (
        <PopupRaw
            className='left-auto right-0 top-0 flex h-full w-[var(--devices-popup-sidebar-width)] translate-x-0 flex-col rounded-l-3xl p-0'
            overlayClassName='left-[var(--sidebar-width)] w-[calc(100%-var(--sidebar-width))]'
            onClose={onPopupClose}
        >
            <div
                ref={(ref) => setScrollContainer(ref)}
                className='flex max-h-[calc(100%-98px)] flex-col overflow-y-auto'
            >
                <StickyHeader
                    title={title}
                    scrollElement={scrollContainer}
                    endIcon={<CloseButton onClick={onPopupClose} />}
                />
                <div className='flex flex-col px-8 pt-[calc(var(--sticky-header-height)+8px)]'>
                    <YourDevicesHeader className='text-left leading-13'>{title}</YourDevicesHeader>
                    <MissingDeviceNotification className='mt-8' />
                    <DevicesList className='mt-4' onClose={onPopupClose} />
                </div>
            </div>
            <div className='mt-auto px-4 pb-[34px]'>
                <AddDeviceButton className='m-0 w-full min-w-[312px]' onClick={onAddDeviceClick}>
                    {button}
                </AddDeviceButton>
            </div>
        </PopupRaw>
    );
};

export default DevicesListPopup;
