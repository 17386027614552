import {deleteInboxMessagesAction} from '@/state/slices/inboxSlice';
import {dispatch} from '@/state/store';

import iccNotificationService from './icc/iccNotificationService';

const fetchInboxMessages = ({nextPageStartKey} = {}) => iccNotificationService.getInboxMessages({nextPageStartKey});

const deleteInboxMessage = (messageId) => iccNotificationService.deleteInboxMessage(messageId);

const deleteInboxMessages = () => {
    dispatch(deleteInboxMessagesAction());
};

const readInboxMessage = (messageId) => iccNotificationService.updateInboxMessageStatus({messageId});

const fetchDetailedInboxMessage = (messageId) => iccNotificationService.getSpecificInboxMessage(messageId);

export default {
    fetchInboxMessages,
    deleteInboxMessage,
    deleteInboxMessages,
    fetchDetailedInboxMessage,
    readInboxMessage,
};
