import cn from 'classnames';
import React, {useId} from 'react';

import {SelectedIcon} from '@/components/Icons';
import helpers from '@/utils/helpers';
import {twx} from '@/utils/tailwindUtils';

const LanguageSelectContentSelectList = ({className, data = [], selectedValue, onChange, isDesktopView}) => {
    const name = useId();

    const onItemChange = (e) => {
        helpers.runFunction(onChange, e.target.value);
    };

    return (
        <div className={cn('flex w-full flex-col', className)}>
            {data.map((item) => {
                const {title, value} = item;
                const isChecked = selectedValue === value;

                return (
                    <div
                        key={value}
                        className={twx('flex w-full items-center justify-between border-b border-border py-4 md:p-0', {
                            'bg-secondary': isChecked && !isDesktopView,
                            'last:border-b-0': !isDesktopView,
                        })}
                    >
                        <label className='grow cursor-pointer px-4 text-left text-16 md:p-4'>
                            <input
                                className='hidden'
                                type='radio'
                                name={name}
                                value={value}
                                checked={isChecked}
                                onChange={onItemChange}
                            />
                            {title}
                        </label>
                        {isChecked && (
                            <div className='mr-5 cursor-pointer md:mr-0 md:p-4'>
                                <SelectedIcon />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default LanguageSelectContentSelectList;
