import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ContactUsIcon, GlobeIcon, PreferenceCenterIcon} from '@/components/Icons';
import StickyHeader from '@/components/Layout/StickyHeader';
import {CONSUMER_PROFILE_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appErrorService from '@/services/app/appErrorService';
import homePageNavigationService from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import tncService from '@/services/tncService';
import {selectAttributionNoticeUrl, selectConsumerName, selectPrivacyNoticeUrl} from '@/state/selectors/consumer';
import {showLanguageSelectModalAction} from '@/state/slices/modalSlice';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import ConsumerProfileHeader from './components/ConsumerProfileHeader';
import ConsumerProfileLogoutButton from './components/ConsumerProfileLogoutButton';
import ConsumerProfileSection from './components/ConsumerProfileSection';

const MobileConsumerProfilePage = () => {
    const isMobile = useIsMobileLayout();
    const dispatch = useDispatch();
    const isDesktop = useIsDesktopLayout();
    const privacyNoticeUrl = useSelector(selectPrivacyNoticeUrl);
    const attributionNoticeUrl = useSelector(selectAttributionNoticeUrl);
    const userName = useSelector(selectConsumerName);
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings.formatString(localizedStrings[CONSUMER_PROFILE_PAGE_HEADER_TITLE], userName));

    useEffect(() => {
        if (isDesktop) {
            appRouterService.replaceToProfilePreferenceCenterPage();
            return;
        }

        amplitudeProfileTracking.trackConsumerProfileScreen();
        tncService.getTncData(null, appErrorService.showGlobalError);
    }, []);

    const onPreferenceCenterClick = () => {
        appRouterService.forwardToProfilePreferenceCenterPage();
    };

    const onContactUsClick = () => {
        appRouterService.forwardToContactUsPage();
    };

    const onTermsOfUseClick = () => {
        amplitudeProfileTracking.trackTermsOfUseClick();
        googleAnalyticsService.trackTermsOfUseClick();
        appRouterService.forwardToProfileTermsOfUse();
    };

    const onPrivacyNoticeClick = () => {
        amplitudeProfileTracking.trackPrivacyNoticeClick();
        googleAnalyticsService.trackPrivacyNoticeClick();
        routerService.blankLinkOpen(privacyNoticeUrl);
    };

    const onAttributionNoticeClick = () => {
        amplitudeProfileTracking.trackAttributionNoticeClick();
        googleAnalyticsService.trackAttributionNoticeClick();
        routerService.blankLinkOpen(attributionNoticeUrl);
    };

    return (
        <div className='flex flex-1 flex-col'>
            <div className='ica-page pt-safe-offset-sticky-header w-full overflow-hidden bg-romance pb-8 text-start'>
                {isMobile && (
                    <StickyHeader
                        onBack={homePageNavigationService.forwardToSourcePage}
                        title={localizedStrings.formatString(
                            localizedStrings[CONSUMER_PROFILE_PAGE_HEADER_TITLE],
                            userName
                        )}
                    />
                )}
                <div className='px-layout'>
                    <ConsumerProfileHeader userName={userName} />
                    <ConsumerProfileSection
                        headerItemName='accountSettingsHeader'
                        items={[
                            {
                                icon: <PreferenceCenterIcon />,
                                itemName: 'preferenceCentreButton',
                                onClick: onPreferenceCenterClick,
                            },
                            {
                                icon: <GlobeIcon className='h-8 w-8' />,
                                itemName: 'languageButton',
                                onClick: () => dispatch(showLanguageSelectModalAction()),
                            },
                        ]}
                    />
                    <ConsumerProfileSection
                        className='mt-12'
                        headerItemName='supportHeader'
                        items={[
                            {
                                icon: <ContactUsIcon />,
                                itemName: 'contactUsButton',
                                onClick: onContactUsClick,
                            },
                        ]}
                    />
                    <ConsumerProfileSection
                        headerItemName='legalHeader'
                        items={[
                            {
                                itemName: 'termsOfUseButton',
                                onClick: onTermsOfUseClick,
                            },
                            {
                                itemName: 'privacyNoticeButton',
                                onClick: onPrivacyNoticeClick,
                            },
                            {
                                itemName: 'attributionNoticeButton',
                                onClick: onAttributionNoticeClick,
                            },
                        ]}
                    />
                    <div className='mt-18 flex justify-center'>
                        <ConsumerProfileLogoutButton />
                    </div>
                </div>
            </div>
            <WarningBanners source={WarningBanners.SOURCES.PROFILE} />
        </div>
    );
};

export default MobileConsumerProfilePage;
