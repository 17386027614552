import cn from 'classnames';
import React, {useState} from 'react';

import routePaths from '@/consts/route/routePaths';
import {twx} from '@/utils/tailwindUtils';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import EditableFormInput from '../../../../components/Aem/EditableFormInput/EditableFormInput';
import EditableFormInputPassword from '../../../../components/Aem/EditableFormInputPassword/EditableFormInputPassword';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../../components/Button';
import {ErrorIcon} from '../../../../components/Icons';
import {ContentWrapper} from '../../../../components/Layout';
import appConfig from '../../../../config/appConfig';
import useExternalLink from '../../../../hooks/useExternalLink';
import useLoginForm from './hooks/useLoginForm';

const LOGIN_INPUT_CLASSNAME = 'ica-login-input';

const LoginForm = ({onLogin, isLoginError, setIsLoginError}) => {
    const [loginCredentials, setLoginCredentials] = useState({
        username: '',
        password: '',
    });
    const {username, password} = loginCredentials || {};

    const {isUsernameValid, isPasswordValid, isUsernameEntered, isPasswordEntered, validateCredentials} = useLoginForm(
        username,
        password
    );

    const registerLinkCode = appConfig.getRegisterLinkCode();
    const forgotPasswordLinkCode = appConfig.getForgotPasswordLinkCode();
    const {redirect: redirectToRegister} = useExternalLink({externalLinkCode: registerLinkCode});
    const {redirect: redirectToForgotPassword} = useExternalLink({externalLinkCode: forgotPasswordLinkCode});

    const getClassNames = (isValid, isEntered) => {
        return cn(
            'text-16 text-left h-13 w-full pr-10 border-2 placeholder-shown:text-ellipsis placeholder-shown:overflow-hidden focus:border-primary rtl:text-right rtl:pr-3 rtl:pl-10',
            LOGIN_INPUT_CLASSNAME,
            {
                'border-error': !isValid || !isEntered,
            }
        );
    };

    const inputUsernameClassName = getClassNames(isUsernameValid, isUsernameEntered);
    const inputPasswordClassName = getClassNames(isPasswordValid, isPasswordEntered);

    const clearInput = () => {
        const emptyUsername = '';

        setIsLoginError(false);
        setLoginCredentials({...loginCredentials, username: emptyUsername});
    };

    const setEmailValue = ({target}) => {
        setIsLoginError(false);
        setLoginCredentials({...loginCredentials, username: target.value});
    };

    const setPasswordValue = ({target}) => {
        setIsLoginError(false);
        setLoginCredentials({...loginCredentials, password: target.value});
    };

    const onLoginBtnClick = () => {
        const isCredentialsValid = validateCredentials();

        if (isCredentialsValid) {
            onLogin({username, password});
        }
    };

    return (
        <ContentWrapper className='pb-4 ml:pb-6 lg:mx-16'>
            <div className='m-auto mt-6 w-full md:max-w-[672px] ml:mt-0'>
                <div className='flex flex-col gap-6'>
                    {isLoginError && (
                        <div className='flex w-full items-center gap-6 rounded bg-secondary px-6 py-5'>
                            <ErrorIcon className='h-6 w-6 shrink-0' />
                            <span className='leading-14_3 flex-1 text-start text-14 tracking-04 text-primary'>
                                <AemEditableText pageName={routePaths.LOGIN} itemName='warningDoesNotMatch' />
                            </span>
                        </div>
                    )}
                    <div className='flex min-h-[260px] w-full flex-col text-left md:min-h-min md:flex-wrap md:justify-between md:gap-0 md:[&>*]:basis-[calc(50%_-_12px)]'>
                        <AemComponent
                            component={EditableFormInput}
                            pageName={routePaths.LOGIN}
                            itemName='forminput'
                            inputClassName={inputUsernameClassName}
                            onInputChange={setEmailValue}
                            type='text'
                            clearInput={clearInput}
                            isEntered={isUsernameEntered}
                            isValid={isUsernameValid}
                            value={loginCredentials.username}
                        />
                        <AemComponent
                            component={EditableFormInputPassword}
                            pageName={routePaths.LOGIN}
                            itemName='forminputpassword'
                            inputClassName={inputPasswordClassName}
                            onInputChange={setPasswordValue}
                            isEntered={isPasswordEntered}
                            isValid={isPasswordValid}
                            value={loginCredentials.password}
                            customIconClassName='text-start'
                            onClick={redirectToForgotPassword}
                            forgotPasswordLinkCode={forgotPasswordLinkCode}
                        />
                    </div>
                </div>

                <AppButton
                    primary
                    light
                    className={twx('m-auto mb-4 mt-7 py-3 ml:mt-6 ml:w-full', {'mb-5': isLoginError})}
                    onClick={onLoginBtnClick}
                    showArrow
                >
                    <AemComponent component={EditableText} pageName={routePaths.LOGIN} itemName='loginbutton' />
                </AppButton>
                {registerLinkCode && <ExternalLinkSection onClick={redirectToRegister} />}
            </div>
        </ContentWrapper>
    );
};

const ExternalLinkSection = ({onClick, isCenterMode = true}) => {
    return (
        <div
            className={cn('flex flex-wrap items-center justify-center gap-2 text-14 leading-[24px]', {
                'text-start md:text-center': !isCenterMode,
            })}
        >
            <span>
                <AemComponent component={EditableText} pageName={routePaths.LOGIN} itemName='newtoiqos' />
            </span>
            <AppButton
                className='m-0 flex w-fit min-w-fit rounded-none p-0 text-14 font-bold underline'
                onClick={onClick}
            >
                <AemComponent component={EditableText} pageName={routePaths.LOGIN} itemName='registerhere' />
            </AppButton>
        </div>
    );
};

export default LoginForm;
