import React from 'react';

import {twx} from '@/utils/tailwindUtils';

const PageSection = ({children, className, ...rest}) => {
    return (
        <section
            className={twx(
                'mb-2 bg-romance px-4 py-8 md:mb-0 md:bg-transparent md:px-8 md:py-4 lg:px-16 lg:py-6',
                className
            )}
            {...rest}
        >
            {children}
        </section>
    );
};

const PageSectionTitle = ({text, className}) => {
    return (
        text && (
            <h2
                className={twx(
                    'mb-0 text-24 font-bold leading-13 md:text-28 md:leading-128 lg:text-32 lg:leading-13125',
                    className
                )}
            >
                {text}
            </h2>
        )
    );
};

PageSection.Title = PageSectionTitle;

export default PageSection;
