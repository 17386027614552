import {useSelector} from 'react-redux';

import {AemEcoBlueIcon, AemPerformancePauseOffBlueIcon, AemPerformancePauseOnBlueIcon} from '@/components/Icons';
import {ECO_MODE, PERFORMANCE_MODE} from '@/consts/uam/aemFlexBatteryAdaptiveExpTypes';
import {ON} from '@/consts/uam/onOffTypes';
import {selectDeviceSettings} from '@/state/selectors/device';

const getPerformanceIcon = (isPause) => {
    if (isPause) {
        return AemPerformancePauseOnBlueIcon;
    }

    return AemPerformancePauseOffBlueIcon;
};

const useAemFlexBatteryData = () => {
    const {adaptiveExpManagement, pauseHeating} = useSelector(selectDeviceSettings) || {};

    if (!adaptiveExpManagement || !pauseHeating) return;

    const {mode} = adaptiveExpManagement;
    const {featureState} = pauseHeating;
    const isPause = featureState === ON;
    const isEco = mode === ECO_MODE;
    const isPerformanceMode = mode === PERFORMANCE_MODE;

    if (!isEco && !isPerformanceMode) return;

    const itemName = isPerformanceMode ? 'aemFlexBatteryPerformanceMode' : 'aemFlexBatteryEcoMode';
    const icon = isPerformanceMode ? getPerformanceIcon(isPause) : AemEcoBlueIcon;

    return {
        itemName,
        icon,
    };
};

export default useAemFlexBatteryData;
