const ANALYTICS_EVENT_NAMES = {
    ENABLE_NOTIFICATION: 'Enable Notification Screen Viewed',
    NEXT_BUTTON_CLICKED: 'Next Button Clicked',
    NOTIFICATION_ALLOWED: 'Notification Allowed',
    HOME_SCREEN_VIEWED: 'Home Screen Viewed',
    HOME_PAGE_TOP_BANNER_CLICKED: 'Homepage Top Banner Clicked',
    LOYALTY_BUTTONS_BAR_CLICKED: 'Loyalty Buttons Bar Clicked',
    HIGHLIGHT_BANNER_CLICKED: 'Highlight Banner Is Clicked',
    FOR_YOU_TILE_OPENED: 'For You Tile Opened',
    SHOP_SECTION_VIEWED: 'Shop Section Viewed',
    QUICK_ORDER_BANNER_CLICKED: 'Quick Order Banner Clicked',
    QUICK_ORDER_BUTTON_CLICKED: 'Quick Order Button Clicked',
    YOUR_DEVICES_BUTTON_CLICKED: 'Your Devices Button Clicked',
    SHOP_BY_PRODUCT_CLICKED: 'Shop By Product Clicked',
    DEVICE_SECTION_VIEWED: 'Device Section Viewed',
    TRY_YOUR_FIRST_IQOS_BUTTON_CLICKED: 'Try Your First IQOS Button Clicked',
    ABOUT_MY_DEVICE_SCREEN_VIEWED: 'About My Device Screen Viewed',
    TIPS_AND_TUTORIAL_SCREEN_VIEWED: 'Tips And Tutorial Screen Viewed',
    UNREGISTER_DEVICE_CLICKED: 'Unregister Device Clicked',
    INBOX_SCREEN_VIEWED: 'Inbox Screen Viewed',
    INBOX_MESSAGE_OPENED: 'Inbox Message Opened',
    INBOX_MESSAGE_BUTTON_CLICKED: 'Inbox Action button clicked',
    ACTION_SCREEN_VIEWED: 'Action Screen Viewed',
    PROFILE_SCREEN_VIEWED: 'Profile Screen Viewed',
    PREFERENCE_CENTER_VIEWED: 'Preference Center Viewed',
    PREFERENCE_CENTER_UPDATE_VIEWED: 'Preference Center Update Viewed',
    CONTACT_US_VIEWED: 'Contact Us Viewed',
    SHOW_PROFILE_CLICKED: 'Show Profile Clicked',
    LEGAL_VIEWED: 'Legal Viewed',
    ADD_NEW_DEVICE_CLICKED: 'Add New Device clicked',
    PAIR_DEVICE_CLICKED: 'Pair Device Clicked',
    START_WITH_BLUEFY_CLICKED: 'Start With Bluefy Clicked',
    RENAME_MY_DEVICE_VIEWED: 'Rename My Device Viewed',
    RENAME_DEVICE: 'Device Renamed',
    QUESTIONNAIRE_SELECT_BUTTON_CLICKED: 'Questionnaire Select Button Clicked',
    QUESTIONNAIRE_SCROLLED: 'Questionnaire Scrolled',
    CLEAN_MY_DEVICE_BUTTON_CLICKED: 'Clean My Device Button Clicked',
    REMOVE_STICK_INSTRUCTIONS_SCROLLED: 'Remove Stick Instructions Scrolled',
    DIAGNOSE_SCREEN_VIEWED: 'Diagnose Screen Viewed',
    AD_INITIAL_QUESTIONNAIRE_VIEWED: 'Advanced Diagnostics Initial Questionnaire Viewed',
    NONE_OF_THE_ABOVE_BUTTON_CLICKED: 'None of the Above Button Clicked',
    AUTODELIVERY_BANNER_CLICKED: 'Autodelivery Banner Clicked',
    AUTODELIVERY_DASHBOARD_BUTTON_CLICKED: 'Autodelivery Dashboard Button Clicked',
    AUTODELIVERY_GET_STARTED_SCREEN_VIEWED: 'Autodelivery Get Started Screen Viewed',
    AUTODELIVERY_GET_STARTED_BUTTON_CLICKED: 'Autodelivery Get Started Button Clicked',
    ORDER_SCREEN_VIEWED: 'Order Screen Viewed',
    ORDER_SUMMARY_LINK_CLICKED: 'Order Summary Link Clicked',
    DELIVER_ASAP_STARTED: 'Deliver ASAP Started',
    DELIVER_ASAP_COMPLETED: 'Deliver ASAP Completed',
    ORDER_BUTTON_CLICKED: 'Order Button Clicked',
    SKIP_DELIVER_NOT_AVAILABLE: 'Skip Delivery Not Available',
    SKIP_DELIVERY_STARTED: 'Skip Delivery Started',
    SKIP_DELIVERY_COMPLETED: 'Skip Delivery Completed',
    LOG_OUT: 'Log Out',
};

export default ANALYTICS_EVENT_NAMES;
