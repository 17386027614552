import {twx} from '@/utils/tailwindUtils';

const HighlightsContentContainer = ({className, isFullscreen, gradient, children}) => {
    gradient = gradient || 'none';
    const gradients = {
        none: 'none',
        top: 'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)',
        bottom: 'linear-gradient(180deg, rgba(52, 48, 61, 0.00) 42.18%, #1C1A21 82.12%)',
    };

    return (
        <div
            className={twx(
                'relative z-[1] flex h-full flex-col px-4 text-start md:p-6',
                {'py-4': !isFullscreen},
                {'pt-safe-offset-5 pb-safe-or-8': isFullscreen},
                className
            )}
            style={{background: gradients[gradient]}}
        >
            {children}
        </div>
    );
};

export default HighlightsContentContainer;
