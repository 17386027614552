import useHighlightsSlideButtonGsap from './useHighlightsSlideButtonGsap';

const useHighlightsSlideButtonAnimation = ({progressLineRef, duration, playState, onEnded}) => {
    const {timelineRef} = useHighlightsSlideButtonGsap({progressLineRef, duration, playState, onEnded});

    const getProgress = () => timelineRef.current.progress();

    const setProgress = (value) => {
        timelineRef.current.progress(value);
    };

    const resetProgress = () => {
        timelineRef.current.progress(0);
    };

    return {getProgress, setProgress, resetProgress};
};

export default useHighlightsSlideButtonAnimation;
