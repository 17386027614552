import React, {useEffect, useState} from 'react';

import {removeNotification} from '@/state/slices/notificationsSlice';
import {dispatch} from '@/state/store';
import {twx} from '@/utils/tailwindUtils';

import useHideNotification from './useHideNotification';

const NotificationBottom = ({notification}) => {
    const {notificationId, text, autoFocus} = notification;
    const DISPLAY_TIME = 800;

    const [isShow, setIsShow] = useState(false);

    const hideNotification = () => {
        dispatch(removeNotification(notificationId));
    };

    useEffect(() => {
        setIsShow(true);
    }, []);

    useHideNotification({
        displayTime: DISPLAY_TIME,
        hideNotification: () => {
            setIsShow(false);
            //time should be aligned with duration class
            setTimeout(hideNotification, 300);
        },
    });

    return (
        <div
            className={twx(
                'relative flex w-full items-center gap-4 rounded-lg bg-primary py-3 pl-6 pr-11 shadow-4px transition-opacity duration-300 ml:pr-6',
                {
                    'opacity-0': !isShow,
                    'opacity-100': isShow,
                }
            )}
        >
            <div className='flex-1 text-start text-14 tracking-04 text-white' role={autoFocus ? '' : 'status'}>
                {text}
            </div>
        </div>
    );
};

export default NotificationBottom;
