import React from 'react';

import {AppButton} from '@/components/Button';
import Image from '@/components/Image/Image';
import PromotedBadge from '@/components/PromotedBadge/PromotedBadge';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import amplitudeShopPageTracking from '@/services/analytics/amplitude/amplitudeShopPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import productHelpers from '@/services/product/productHelpers';
import helpers from '@/utils/helpers';

const DeviceItem = ({device, promotedTitleKey, externalLinkData, actionLocalizationKey, amplitudeButtonType}) => {
    const localizedStrings = getLocalizedStrings();
    const {defaultProductName, type, image} = device || {};
    const name = productHelpers.getProductName(device);

    const redirectToExternalLink = useConfigExternalLink(externalLinkData);
    const onClick = () => {
        amplitudeShopPageTracking.trackYourDeviceButtonClick(amplitudeButtonType, defaultProductName);
        googleAnalyticsService.trackYourDeviceButtonClick(amplitudeButtonType, type);
        helpers.runFunction(redirectToExternalLink);
    };

    return (
        <div className='relative flex h-full w-65 flex-col items-center rounded-2xl bg-secondary pt-[50px] md:w-[304px] md:rounded-3xl md:bg-romance md:pt-8 ml:w-[320px] lg:w-[370px]'>
            <div className='absolute left-4 top-4'>
                <PromotedBadge badgeText={localizedStrings[promotedTitleKey]} />
            </div>
            <Image className='h-auto w-full max-w-[200px] md:max-w-[264px]' src={image} alt={name} ariaHidden />
            <div className='mt-2.5 flex w-full flex-col px-4 pb-4 md:mt-8 md:px-8 md:pb-8'>
                <div className='mb-4 flex justify-center'>
                    <b className='overflow-hidden text-ellipsis text-center text-14 leading-143 tracking-02'>{name}</b>
                </div>
                <AppButton secondary dark className='m-0 w-full min-w-0 md:max-w-64 ml:max-w-none' onClick={onClick}>
                    {localizedStrings[actionLocalizationKey]}
                </AppButton>
            </div>
        </div>
    );
};

export default DeviceItem;
