import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import domIdentifiers from '@/consts/app/domIdentifiers';
import useOnScroll from '@/hooks/useOnScroll';
import reactUtils from '@/utils/reactUtils';
import {twx} from '@/utils/tailwindUtils';

const DesktopStickyHeader = ({
    endIcon: endIconOrFunc,
    className,
    fixedClassName,
    childRenderer,
    scrollElement = window,
}) => {
    const {isFixed, isVisible} = useHeaderVisibility({scrollElement});
    const isBgVisible = isFixed && isVisible;

    const endIcon = reactUtils.getOrCreateComponent({
        component: endIconOrFunc,
        parameters: {isSticked: isFixed},
    });

    return (
        <header
            id={domIdentifiers.STICKY_HEADER_ID}
            className={twx(
                'right-0 top-0 isolate z-30 w-full transition-transform duration-300 ease-in-out',
                isFixed ? 'fixed' : 'absolute',
                isVisible ? 'translate-y-0' : '-translate-y-full',
                className,
                {[fixedClassName]: isFixed}
            )}
        >
            <div
                className={cn(
                    'absolute left-0 top-0 h-full w-full bg-romance shadow-[0_4px_19px_0px_rgba(0,0,0,0.4)] transition-opacity duration-300 ease-in-out',
                    isBgVisible ? 'opacity-100' : 'opacity-0'
                )}
            />
            <div className='relative z-10 flex h-24 items-center justify-between gap-4 px-16 md:px-8 lg:px-16'>
                <div>{typeof childRenderer === 'function' && childRenderer(isBgVisible)}</div>
                <div className='relative flex w-16 justify-end'>{endIcon}</div>
            </div>
        </header>
    );
};

const SCROLL_TOP_OFFSET = 96;

const useHeaderVisibility = ({scrollElement}) => {
    const scrollTop = useOnScroll({scrollElement});
    const [isFixed, setIsFixed] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        if (scrollTop === 0) {
            setIsFixed(false);
        }

        if (scrollTop < SCROLL_TOP_OFFSET) {
            setIsVisible(true);
        } else {
            const isScrollingUp = scrollTop < lastScrollY;

            setIsFixed(isFixed ? true : isScrollingUp);
            setIsVisible(isScrollingUp);
        }

        setLastScrollY(scrollTop);
    }, [scrollTop]);

    return {isFixed, isVisible};
};

export default DesktopStickyHeader;
