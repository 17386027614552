import React, {useState} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '@/components/Button';
import LanguageSelect from '@/components/LanguageSelect/LanguageSelect';
import appConfig from '@/config/appConfig';
import routePaths from '@/consts/route/routePaths';
import storageService from '@/services/storage/storageService';

const InstallPwaLanguageSelect = () => {
    const isEnabled = appConfig.getIsGetStartedLanguageSelectEnabled();
    const isDismissed = storageService.getIsGetStartedLanguageSelectDismissed();
    const [isVisible, setIsVisible] = useState(!isDismissed);

    if (!isEnabled || !isVisible) return;

    const handleDismissClick = () => {
        setIsVisible(false);
        storageService.setIsGetStartedLanguageSelectDismissed(true);
    };

    return (
        <>
            <div className='sticky top-0 z-30 bg-primary text-romance pt-safe'>
                <div className='flex justify-between px-6 py-4 ml:mx-auto ml:max-w-[1024px] ml:px-0'>
                    <LanguageSelect popoverClassName='-ml-4' popoverAlign='start' popoverArrowHeight={17} />
                    <ButtonRaw
                        className='text-14 font-bold leading-[20px] tracking-02 text-tertiary'
                        onClick={handleDismissClick}
                    >
                        <AemEditableText pageName={routePaths.INSTALL_PWA} itemName='languageSelectDismissButton' />
                    </ButtonRaw>
                </div>
            </div>
        </>
    );
};

export default InstallPwaLanguageSelect;
