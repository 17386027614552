import analyticsEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import deviceTypesService from '@/services/device/deviceTypesService';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import {getState} from '@/state/store';

import amplitude from './amplitude';

const trackDeviceEvent = (eventType, serialNumber, deviceType, properties) => {
    const modelName = deviceTypesService.getDeviceModelByType(deviceType);

    amplitude.trackEvent({eventType, serialNumber, modelName, properties});
};

const trackDeviceFromStateEvent = (eventType, properties) => {
    const {deviceSerialNumber, type} = selectCurrentProductInfo(getState());

    trackDeviceEvent(eventType, deviceSerialNumber, type, properties);
};

const trackAddNewDeviceClick = () => {
    amplitude.trackWithSource(analyticsEventNames.ADD_NEW_DEVICE_CLICKED);
};

const trackPairDeviceClick = (serialNumber, deviceType) => {
    trackDeviceEvent(analyticsEventNames.PAIR_DEVICE_CLICKED, serialNumber, deviceType);
};

const trackStartWithBluefyClick = () => {
    const device = selectCurrentProductInfo(getState());
    const eventType = analyticsEventNames.START_WITH_BLUEFY_CLICKED;

    if (device && device.deviceSerialNumber && device.type) {
        trackDeviceFromStateEvent(analyticsEventNames.START_WITH_BLUEFY_CLICKED);
    } else {
        amplitude.trackWithSource(eventType);
    }
};

const trackRenameDeviceScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.RENAME_MY_DEVICE_VIEWED);
};

const trackRenameDeviceSave = () => {
    amplitude.trackWithSource(analyticsEventNames.RENAME_DEVICE);
};

const trackUnregisterDeviceClick = (deviceSerialNumber, type, success) => {
    trackDeviceEvent(analyticsEventNames.UNREGISTER_DEVICE_CLICKED, deviceSerialNumber, type, {
        [analyticsEventProperties.SUCCESS]: success,
    });
};

const trackUnregisterHolderClick = (deviceSerialNumber, type, success) => {
    const holderDefaultData = deviceTypesService.getDeviceTypesDataByType(type);
    const modelName = holderDefaultData?.modelName;

    amplitude.trackEvent({
        eventType: analyticsEventNames.UNREGISTER_DEVICE_CLICKED,
        serialNumber: deviceSerialNumber,
        modelName,
        properties: {
            [analyticsEventProperties.SUCCESS]: success,
        },
    });
};

export default {
    trackAddNewDeviceClick,
    trackDeviceFromStateEvent,
    trackPairDeviceClick,
    trackStartWithBluefyClick,
    trackRenameDeviceScreen,
    trackRenameDeviceSave,
    trackUnregisterDeviceClick,
    trackUnregisterHolderClick,
};
