import domIdentifiers from '../consts/app/domIdentifiers';

const getHtmlElement = () => document.documentElement;
const getElementBySelector = (selector) => document.querySelector(selector);
const getAllElementsBySelector = (selector) => document.querySelectorAll(selector);
const getRoot = () => document.getElementById('root');
const getComponentsWrapperElement = () => getElementBySelector(`.${domIdentifiers.COMPONENTS_CONTAINER_CLASS_NAME}`);
const getPageElement = () => getElementBySelector(`.${domIdentifiers.PAGE_CLASSNAME}`);
const getStickyHeaderElement = () => getElementBySelector(`#${domIdentifiers.STICKY_HEADER_ID}`);
const getProfileSidebarContentElement = () => getElementBySelector(`#${domIdentifiers.PROFILE_SIDEBAR_CONTENT}`);
const setFocusToDomElement = (domElement) => domElement?.focus();
const handleBtnKeyPress = (event, postFunc) => {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
        event.preventDefault();
        postFunc(event);
    }
};
const handleCheckboxKeyPress = (event, postFunc) => {
    if (event.key === ' ' || event.key === 'Spacebar') {
        event.preventDefault();
        postFunc(event);
    }
};

const setCssVariable = (name, value) => {
    document.documentElement.style.setProperty(name, value);
};

export default {
    getAllElementsBySelector,
    getComponentsWrapperElement,
    getElementBySelector,
    getHtmlElement,
    getRoot,
    setFocusToDomElement,
    handleBtnKeyPress,
    handleCheckboxKeyPress,
    getPageElement,
    getStickyHeaderElement,
    getProfileSidebarContentElement,
    setCssVariable,
};
