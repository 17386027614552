import {Toaster as Sonner} from 'sonner';

import styles from './Toaster.module.scss';

const Toaster = (props) => {
    return (
        <Sonner
            className={styles.Toaster}
            toastOptions={{
                style: {
                    '--width': '358px',
                },
                classNames: {
                    toast: 'bg-primary text-white border-none text-14 tracking-04 font-normal shadow-4px py-3 px-6',
                    actionButton: 'bg-transparent text-tertiary text-14 tracking-02 font-bold',
                },
            }}
            offset={{bottom: '64px'}}
            mobileOffset={{bottom: '34px'}}
            position='bottom-center'
            {...props}
        />
    );
};

export default Toaster;
