import React from 'react';

import Icon from '../Icon/Icon';

const InfoBlueIcon = (props) => {
    return (
        <Icon width='36' height='36' fill='none' {...props}>
            <circle cx='18' cy='18' r='17' stroke='#34303D' strokeWidth='2' />
            <circle cx='18' cy='18' r='14.0625' fill='#00D1D2' />
            <circle cx='18' cy='11.0625' r='1.3125' fill='#34303D' />
            <rect x='16.875' y='14.5566' width='2.25' height='12' rx='1.125' fill='#34303D' />
        </Icon>
    );
};

export default InfoBlueIcon;
