import {ICC_PRODUCT_STATUS_TYPES} from '@/consts/icc/iccProductStatusTypes';
import {
    CHARGER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT,
    DEVICE_UNREGISTERED_NOTIFICATION_TEXT,
    HOLDER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT,
} from '@/consts/localization/localizationKeys';
import {makeSelectConsumerProductToRegUnreg} from '@/state/selectors/consumer';
import {
    addConsumerProductToRegUnreg,
    removeConsumerProduct,
    removeConsumerProductFromRegUnreg,
} from '@/state/slices/consumerSlice';
import {setNotificationError, setNotificationInfo} from '@/state/slices/notificationsSlice';
import {dispatch, getState} from '@/state/store';

import iccMarketService from '../icc/iccMarketService';
import {getLocalizedStrings} from '../localization/localizationService';
import log from '../logger/log';

const iccUnregistration = async (device) => {
    const {productId, codentify, isHolder} = device;

    log.info(`Start unregistration process for codentify: ${codentify}.`);

    const productIdentify = productId ? {productId} : {codentifyId: codentify};
    const response = await iccMarketService.removeConsumerProduct({data: productIdentify});

    if (response) {
        dispatch(removeConsumerProduct(codentify));
        dispatch(addConsumerProductToRegUnreg({codentify, isHolder}));
        return true;
    }

    return false;
};

const unregisterConsumerProduct = async (device) => {
    const state = getState();
    const {codentify, holder} = device || {};
    const {codentify: holderCodentify} = holder || {};
    const productStatus = makeSelectConsumerProductToRegUnreg(codentify)(state);
    const holderProductStatus = makeSelectConsumerProductToRegUnreg(codentify)(state);
    const isChargerUnregNeeded = codentify && !productStatus;
    const isHolderUnregNeeded = holderCodentify && !holderProductStatus;
    const unregResult = {};

    const unregisterDevice = async (device) => {
        try {
            const isRequestSent = await iccUnregistration(device);

            return isRequestSent;
        } catch (e) {
            log.info(`End unregistration process. Error: ${e}.`);
            return false;
        }
    };

    //first unreg charger then start holder unreg
    if (isChargerUnregNeeded) {
        unregResult.isChargerRequestSent = await unregisterDevice(device);
    }

    const shouldSendHolderRequest =
        isHolderUnregNeeded && (!isChargerUnregNeeded || (isChargerUnregNeeded && unregResult.isChargerRequestSent));

    if (shouldSendHolderRequest) {
        unregResult.isHolderRequestSent = await unregisterDevice(holder);
    }

    return unregResult;
};

const fetchProductsStatusAndCheckThem = async (products) => {
    if (!products?.length) return;

    const response = await iccMarketService.getConsumerProductsStatus();

    if (!response?.length) return;

    let isNeedCallAsync = false;
    const unregistrationNotifications = [];

    products.forEach((product) => {
        const {isReg, codentify, isHolder} = product;
        const productResponse = response.find((x) => x.codentify === codentify);
        const {status: responseStatus} = productResponse || {};

        if (isReg) {
            const isRegistrationSucceeded = isStatusRegistered(responseStatus);
            const isRegistrationFailed = isStatusRegisterFailed(responseStatus);

            if (isRegistrationSucceeded || isRegistrationFailed) {
                isNeedCallAsync = true;
                dispatch(removeConsumerProductFromRegUnreg(codentify));
            }
        } else {
            const isUnregistrationSucceeded = isStatusUnregistered(responseStatus);
            const isUnregistrationFailed = responseStatus && !isStatusUnregistering(responseStatus);

            if (isUnregistrationSucceeded || isUnregistrationFailed) {
                const localizedStrings = getLocalizedStrings();
                const logStatus = isUnregistrationSucceeded ? 'Success' : 'Failed';
                const notificationText = isUnregistrationSucceeded
                    ? localizedStrings[DEVICE_UNREGISTERED_NOTIFICATION_TEXT]
                    : isHolder
                      ? localizedStrings[HOLDER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT]
                      : localizedStrings[CHARGER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT];

                log.info(`End unregistration process for codentify: ${codentify}. Result: ${logStatus}`);
                unregistrationNotifications.push({text: notificationText, isInfo: isUnregistrationSucceeded});
                isNeedCallAsync = true;
                dispatch(removeConsumerProductFromRegUnreg(codentify));
            }
        }
    });

    const showNotifications = (notifications) => {
        for (const notification of notifications) {
            const {text, isInfo} = notification;

            if (isInfo) {
                dispatch(setNotificationInfo(text));
            } else {
                dispatch(setNotificationError(text));
            }
        }
    };

    if (isNeedCallAsync) {
        await iccMarketService.getConsumerProducts();

        showNotifications(unregistrationNotifications);
    }
};

const isStatusRegistered = (status) => status === ICC_PRODUCT_STATUS_TYPES.REGISTERED;

const isStatusRegistering = (status) => status === ICC_PRODUCT_STATUS_TYPES.REGISTERING;

const isStatusUnregistered = (status) => status === undefined || status === ICC_PRODUCT_STATUS_TYPES.UNREGISTERED;

const isStatusUnregistering = (status) => status === ICC_PRODUCT_STATUS_TYPES.UNREGISTERING;

const isStatusRegisterFailed = (status) => status === ICC_PRODUCT_STATUS_TYPES.FAILED;

const proceedDeviceStatus = (statusFunction, status) => statusFunction(status);

const getIsRegistered = (status) => proceedDeviceStatus(isStatusRegistered, status);

const getIsRegistering = (status) => proceedDeviceStatus(isStatusRegistering, status);

const getIsUnregistered = (status) => proceedDeviceStatus(isStatusUnregistered, status);

const getIsRegisterFailed = (status) => proceedDeviceStatus(isStatusRegisterFailed, status);

const getIsUnregistering = (status) => proceedDeviceStatus(isStatusUnregistering, status);

export default {
    unregisterConsumerProduct,
    fetchProductsStatusAndCheckThem,
    getIsRegistered,
    getIsRegistering,
    getIsUnregistered,
    getIsRegisterFailed,
    getIsUnregistering,
};
