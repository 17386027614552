import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import {MY_DEVICES_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import ROUTE_PATHS from '@/consts/route/routePaths';
import AboutDevicePage from '@/containers/AboutDevicePage/AboutDevicePage';
import DeviceRenamePage from '@/containers/DeviceRenamePage/DeviceRenamePage';
import DeviceSettingPage from '@/containers/DeviceSettingPage/DeviceSettingPage';
import DevicesSettingsSection from '@/containers/DevicesPage/components/DevicesSettingsSection/DevicesSettingsSection';
import MyDevicesManageDevice from '@/containers/DevicesPage/components/MyDevicesView/components/MyDevicesManageDevice';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import useLocationPathname from '@/hooks/useLocationPathname';
import useWithLocale from '@/hooks/useWithLocale';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import productHelpers from '@/services/product/productHelpers';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import HomePageLayout from '@/views/Home/HomePageLayout';

import MyDevicesImageSection from './components/MyDevicesImageSection';

const FULL_PAGE_ROUTES = [ROUTE_PATHS.DEVICE_RENAME, ROUTE_PATHS.ABOUT_DEVICE];

const MyDevicesView = () => {
    const withLocale = useWithLocale();
    const localizedStrings = getLocalizedStrings();
    const device = useSelector(selectCurrentProductInfo);
    const name = productHelpers.getProductName(device);

    useDocumentTitle(localizedStrings.formatString(localizedStrings[MY_DEVICES_PAGE_HEADER_TITLE], name));

    return (
        <Container device={device} deviceName={name}>
            <Switch>
                <Route path={withLocale(ROUTE_PATHS.DEVICE_RENAME)} component={DeviceRenamePage} />
                <Route path={withLocale(ROUTE_PATHS.ABOUT_DEVICE)} component={AboutDevicePage} />
                <Route path={withLocale(ROUTE_PATHS.AEM_FLEX_BATTERY_SETTING)} component={DeviceSettingPage} />
                <Route path={withLocale(ROUTE_PATHS.AUTO_START_SETTING)} component={DeviceSettingPage} />
                <Route path={withLocale(ROUTE_PATHS.FLEX_PUFF_SETTING)} component={DeviceSettingPage} />
                <Route path={withLocale(ROUTE_PATHS.SMART_GESTURES_SETTING)} component={DeviceSettingPage} />
                <Route path={withLocale(ROUTE_PATHS.ILLUMINATION_MODE_SETTING)} component={DeviceSettingPage} />
                <Route path={withLocale(ROUTE_PATHS.VIBRATIONS_SETTING)} component={DeviceSettingPage} />
                <Route component={MyDevicesManageDevice} />
            </Switch>
        </Container>
    );
};

const Container = ({children, device, deviceName}) => {
    const {pathname} = useLocationPathname();
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();
    const isFullPage = FULL_PAGE_ROUTES.includes(pathname);

    if (isMobile && isFullPage) return <>{children}</>;

    return (
        <HomePageLayout className='bg-secondary'>
            <HomePageTopNavigation
                title={localizedStrings.formatString(localizedStrings[MY_DEVICES_PAGE_HEADER_TITLE], deviceName)}
                source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.DEVICES}
                profileControlsClassName='bg-romance'
            />
            <div className='mt-safe-offset-sticky-header flex min-h-[calc(100vh-96px)] w-full flex-col gap-8 pb-safe md:mt-safe-offset-[calc(var(--sticky-header-height)+32px)] ml:flex-row ml:gap-6 ml:px-8 lg:gap-8 lg:px-16'>
                <MyDevicesImageSection device={device} deviceName={deviceName} />
                <DevicesSettingsSection>{children}</DevicesSettingsSection>
            </div>
        </HomePageLayout>
    );
};

export default MyDevicesView;
