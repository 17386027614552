import cn from 'classnames';
import React from 'react';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import useLayout from '@/hooks/inbox/useLayout';
import amplitudeShopPageTracking from '@/services/analytics/amplitude/amplitudeShopPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';

const YourUsualSectionBanner = ({isAdditionalInfoVisible, banner}) => {
    const {isTabletAndDesktop} = useLayout();
    const {imageMedia, videoPath, isGradient, titleKey, descriptionKey, isDarkBannerText, buttonActionKey} =
        banner || {};
    const localizedStrings = getLocalizedStrings();
    const onButtonClick = () => {
        amplitudeShopPageTracking.trackQuickOrderBannerClick();
        googleAnalyticsService.trackQuickOrderBannerClick();
        appRouterService.forwardToQuickOrderPage();
    };

    const buttonText = buttonActionKey && localizedStrings[buttonActionKey];

    return (
        <Banner
            className={cn(
                'h-60 cursor-pointer overflow-hidden rounded-xl bg-tertiary text-romance md:h-[327px] md:cursor-default md:rounded-3xl ml:h-[348px]',
                isAdditionalInfoVisible ? 'lg:h-[370px]' : ' lg:h-[406px]'
            )}
            imageMedia={imageMedia}
            videoPath={videoPath}
            isGradient={isGradient}
            isDarkBannerText={isDarkBannerText}
            gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
            onClick={isTabletAndDesktop ? undefined : onButtonClick}
        >
            <div className='relative flex h-full flex-col justify-end p-4 md:p-8'>
                <h2
                    className={cn(
                        'mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-20 font-bold md:text-24 md:leading-13 ml:text-42',
                        isAdditionalInfoVisible && 'lg:text-24'
                    )}
                >
                    {localizedStrings[titleKey]}
                </h2>
                <p
                    className={cn(
                        'line-clamp-2 whitespace-pre-line text-16 leading-125 ml:text-18 ml:font-bold ml:leading-13',
                        isAdditionalInfoVisible && 'lg:text-16 lg:font-normal lg:leading-125'
                    )}
                >
                    {localizedStrings[descriptionKey]}
                </p>
                {buttonText && isTabletAndDesktop && (
                    <AppButton
                        primary
                        dark
                        className={cn(
                            'mt-2 whitespace-nowrap ml:mt-6 ml:self-start [&>*]:overflow-hidden [&>*]:text-ellipsis',
                            isAdditionalInfoVisible && 'lg:mt-2 lg:self-stretch'
                        )}
                        onClick={onButtonClick}
                    >
                        {buttonText}
                    </AppButton>
                )}
            </div>
        </Banner>
    );
};

export default YourUsualSectionBanner;
