import cn from 'classnames';
import React, {forwardRef} from 'react';

const HighlightsSlideButton = forwardRef(function HighlightsCarouselButton(
    {animationClass, animationDuration, isAnimationPlaying, isAnimationEnded, onAnimationEnd, ...props},
    ref
) {
    return (
        <ButtonClickableArea {...props}>
            <div className='h-1 w-full overflow-hidden rounded-lg bg-white/50'>
                <span
                    ref={ref}
                    onAnimationEnd={onAnimationEnd}
                    className={cn('block h-full bg-white', {
                        'w-full': isAnimationEnded,
                        [animationClass]: !!animationDuration,
                        paused: !isAnimationPlaying,
                    })}
                    style={{animationDuration: `${animationDuration}s`}}
                />
            </div>
        </ButtonClickableArea>
    );
});

const ButtonClickableArea = ({children, ...props}) => {
    return (
        <div className='flex h-5 cursor-pointer items-center' {...props}>
            {children}
        </div>
    );
};

export default HighlightsSlideButton;
