import React from 'react';

import arrayUtils from '@/utils/arrayUtils';

import appConfig from '../../config/appConfig';
import urlUtils from '../../utils/urlUtils';

const RemoteImage = ({imageMedia, className}) => {
    if (!imageMedia?.length) return null;

    const groupedImageMedia = getGroupedImageMedia(imageMedia);
    const defaultImageMedia = groupedImageMedia.find((x) => isDefaultMedia(x))?.item;

    //default path for image should be existed to show image
    if (!defaultImageMedia?.length) return null;

    const defaultImageSet = getImageMedia(defaultImageMedia);
    const otherImageMedia = groupedImageMedia
        .filter((x) => !isDefaultMedia(x))
        .sort((a, b) => parseInt(b.key) - parseInt(a.key));

    return (
        <picture className={className}>
            {otherImageMedia.map((media) => {
                const {key, item} = media || {};

                if (!key || !item) return undefined;

                const maxWidth = item[0]?.viewport?.maxWidth;
                const mediaWidth = maxWidth
                    ? `(min-width: ${key}px) and (max-width: ${maxWidth}px)`
                    : `(min-width: ${key}px)`;

                return <source key={key} srcSet={getImageMedia(item)} media={mediaWidth} />;
            })}
            <img className={className} srcSet={defaultImageSet} alt='' />
        </picture>
    );
};

const getGroupedImageMedia = (imageMedia) => {
    const mappedOtherMedia = imageMedia.map((x) => {
        return {...x, width: x.viewport.width};
    });
    const groupedObject = arrayUtils.groupBy(mappedOtherMedia, 'width');

    return arrayUtils.objectToArray(groupedObject);
};

const isDefaultMedia = (item) => {
    return item.key === 0 || item.key === '0';
};

const getImageMedia = (media) => {
    if (!media?.length) return '';

    return media
        .map((image) => {
            const {path, ppi} = image;
            const srcPath = urlUtils.join(appConfig.getContentPath(), path);

            return `${srcPath} ${ppi}x`;
        })
        .join(', ');
};

export default RemoteImage;
