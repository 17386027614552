import React, {forwardRef, useImperativeHandle} from 'react';

import HighlightsSlideButtonContainer from '../../components/HighlightsSlideButtonContainer';
import HighlightsSlide from '../HighlightsSlide/HighlightsSlide';
import HighlightsSlider from '../HighlightsSlider/HighlightsSlider';
import HighlightsSlideButton from './components/HighlightsSlideButton';
import useHighlightsSlideButton from './hooks/useHighlightsSlideButton';
import useHighlightsSlideProgress from './hooks/useHighlightsSlideProgress';

const HighlightsCarousel = forwardRef(function HighlightsCarousel(
    {
        highlights,
        isAutoPlayEnabled,
        isFullscreen,
        isPlaying,
        isEnded,
        currentSlide,
        onSlideChange,
        onCarouselClick,
        onCarouselEnded,
    },
    ref
) {
    const {
        getButtonAnimationDuration,
        getButtonPlayState,
        handleLoadedMetadata,
        handleVideoWaiting,
        handleVideoCanPlay,
        handleVideoError,
    } = useHighlightsSlideButton({
        highlights,
        isAutoPlayEnabled,
        isCarouselPlaying: isPlaying,
        isCarouselEnded: isEnded,
        currentSlide,
    });
    const {setButtonRef, setVideoRef, getSlideProgress, setSlideProgress, resetSlideProgress} =
        useHighlightsSlideProgress({isAutoPlayEnabled, currentSlide});

    useImperativeHandle(ref, () => ({
        getSlideProgress,
        setSlideProgress,
        resetSlideProgress,
    }));

    const handleSlideButtonClick = (i) => {
        onSlideChange?.(currentSlide, i);
    };

    const handleSlideEnded = (i) => {
        const isLastSlide = i === highlights.length - 1;

        if (isLastSlide) {
            onCarouselEnded?.();
        } else {
            const nextSlide = i + 1;

            onSlideChange?.(i, nextSlide);
        }
    };

    return (
        <HighlightsSlider currentSlide={currentSlide} onClick={onCarouselClick}>
            <HighlightsSlider.Buttons asChild>
                <HighlightsSlideButtonContainer>
                    {highlights.map((_, i) => (
                        <HighlightsSlideButton
                            key={i}
                            ref={(ref) => setButtonRef(i, ref)}
                            duration={getButtonAnimationDuration(i)}
                            playState={getButtonPlayState(i)}
                            onClick={() => handleSlideButtonClick(i)}
                            onEnded={() => handleSlideEnded(i)}
                        />
                    ))}
                </HighlightsSlideButtonContainer>
            </HighlightsSlider.Buttons>

            <HighlightsSlider.Slides>
                {highlights.map((highlight, i) => (
                    <HighlightsSlider.Slide key={i} index={i}>
                        <HighlightsSlide
                            videoRef={(ref) => setVideoRef(i, ref)}
                            loop={!isAutoPlayEnabled}
                            isFullscreen={isFullscreen}
                            isActiveSlide={i === currentSlide}
                            isPaused={!isPlaying}
                            onLoadedMetadata={(metadata) => handleLoadedMetadata(metadata, i)}
                            onVideoCanPlay={() => handleVideoCanPlay(i)}
                            onVideoWaiting={() => handleVideoWaiting(i)}
                            onVideoError={() => handleVideoError(i)}
                            {...highlight}
                        />
                    </HighlightsSlider.Slide>
                ))}
            </HighlightsSlider.Slides>
        </HighlightsSlider>
    );
});

export default HighlightsCarousel;
