import React, {useState} from 'react';

import appConfig from '@/config/appConfig';
import amplitudeHomePageTracking from '@/services/analytics/amplitude/amplitudeHomePageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {filterSectionsById} from '@/services/pageSectionService';

import {useHighlightsSectionId} from '../../contexts/HighlightsSectionIdContext';
import HighlightsContainer from '../common/components/HighlightsContainer';
import HighlightsHeader from '../common/components/HighlightsHeader';
import FullscreenHighlightsCarousel from './components/FullscreenHighlightsCarousel';
import HighlightsCarousel from './components/HighlightsCarousel';

const getHighlights = (id) => {
    const highlightsFromConfig = appConfig.getHighlights();
    const highlights = filterSectionsById({list: highlightsFromConfig, id});

    return highlights?.sort(({banner: a}, {banner: b}) => a?.order - b?.order);
};

const MobileHighlights = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const id = useHighlightsSectionId();

    const trackBannerClick = () => {
        const currentHighlight = highlights[currentIndex];

        if (!currentHighlight) {
            return null;
        }

        const trackingValue = currentHighlight.banner?.amplitudeTrackingValue;

        amplitudeHomePageTracking.trackHighlightBannerClick(trackingValue);
        googleAnalyticsService.trackHighlightsBannerClick(trackingValue);
    };

    const onSlideClick = () => {
        trackBannerClick();
        setIsFullscreen(true);
    };
    const onSlideChange = (index) => {
        setCurrentIndex(index);
    };
    const highlights = getHighlights(id);
    const highlightsAutoplay = appConfig.getHighlightsAutoplay();

    if (!highlights?.length) return;

    return (
        <HighlightsContainer>
            {isFullscreen ? (
                <FullscreenHighlightsCarousel
                    setIsFullscreen={setIsFullscreen}
                    currentIndex={currentIndex}
                    highlights={highlights}
                    autoplay={highlightsAutoplay}
                    onSlideChange={onSlideChange}
                    isInfinite
                />
            ) : (
                <>
                    <HighlightsHeader />
                    <HighlightsCarousel
                        initialSlide={currentIndex}
                        currentIndex={currentIndex}
                        onSlideClick={onSlideClick}
                        highlights={highlights}
                        autoplay={highlightsAutoplay}
                        onSlideChange={onSlideChange}
                        isInfinite
                    />
                </>
            )}
        </HighlightsContainer>
    );
};

export default MobileHighlights;
