import React from 'react';

import {twx} from '@/utils/tailwindUtils';

import AppPopup from './AppPopup';

const PopupFullPage = (props) => {
    const {className, ...restProps} = props;

    return (
        <AppPopup
            bodyOpenClassName='modal__Body--open'
            overlayClassName='bg-romance fixed overflow-y-auto w-full z-[104] h-full ml:z-[102] ml:block ml:relative ml:bg-none'
            isOpen
            className={twx('fixed left-0 right-0 h-screen w-full', className)}
            {...restProps}
        />
    );
};

export default PopupFullPage;
