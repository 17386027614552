import amplitudeNotificationTracking from '@/services/analytics/amplitude/amplitudeNotificationTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {selectConsumerId} from '@/state/selectors/consumer';
import {getState} from '@/state/store';

import iccNotificationService from './icc/iccNotificationService';
import log from './logger/log';
import serviceWorkerService from './serviceWorkerService';
import storageService from './storage/storageService';

const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};

const checkIfNotificationIsGranted = () => Notification.permission === 'granted';
const checkIfNotificationIsDenied = () => Notification.permission === 'denied';

const askPermission = async () => {
    if (checkIfNotificationIsGranted()) return;

    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
        amplitudeNotificationTracking.trackSubscribeToReceiveMessages();
        googleAnalyticsService.trackAllowPushNotifications();
        log.debug('Notifications are allowed now.');
    } else {
        amplitudeNotificationTracking.trackUnsubscribeToReceiveMessages();
        googleAnalyticsService.trackDisallowPushNotifications();
        throw new Error('Notifications are denied.');
    }
};

let subscriptionInfo;

const getSubscriptionInfo = () => {
    if (subscriptionInfo) return subscriptionInfo;

    try {
        const subscriptionInfoRaw = storageService.getSubscriptionFromLocalStorage();
        const subscriptionInfoParsed = JSON.parse(subscriptionInfoRaw);

        return subscriptionInfoParsed;
    } catch (e) {
        return undefined;
    }
};

let getSubscriptionTimeout;
let subscription;
let getSubscriptionAttemptsLeft = 5;

const initGetSubscriptionTimeout = (swReg) => {
    if (getSubscriptionAttemptsLeft <= 0) {
        log.debug('initGetSubscriptionTimeout cancelled, attempts are over');
        return;
    }
    const GET_SUBSCRIPTION_TIMEOUT_MS = 10_000;

    getSubscriptionTimeout = setTimeout(() => {
        if (getSubscriptionInfo()?.subscriptionId) return;

        getSubscriptionAttemptsLeft = getSubscriptionAttemptsLeft - 1;
        log.debug('initGetSubscriptionTimeout: call getSubscription after timeout');
        getSubscription(swReg);
    }, GET_SUBSCRIPTION_TIMEOUT_MS);
};

const getSubscription = async (swReg) => {
    try {
        initGetSubscriptionTimeout(swReg);

        subscription = await swReg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
        });

        log.debug('getSubscription: push subscription data successfully received');

        const subscriptionId = await iccNotificationService.pushSubscription(subscription);

        clearTimeout(getSubscriptionTimeout);
        setSubscriptionInfo({data: subscription, subscriptionId});

        return true;
    } catch (e) {
        log.info(`pushNotificationService: subscribe error: ${e}`);
    }
};

const setSubscriptionInfo = ({data, subscriptionId}) => {
    const consumerId = selectConsumerId(getState());

    subscriptionInfo = {data, subscriptionId, consumerId};
    storageService.setSubscriptionToLocalStorage(JSON.stringify(subscriptionInfo));
};

const clearSubscriptionInfo = () => {
    storageService.removeSubscriptionFromLocalStorage();
    subscriptionInfo = null;
};

const subscribe = async () => {
    const swReg = await serviceWorkerService.registerServiceWorker();

    if (!swReg) return;

    try {
        await askPermission();

        getSubscription(swReg);
    } catch (e) {
        log.info(`pushNotificationService: subscribe error: ${e}`);
    }
};

const getExistingSubscription = async () => {
    const swReg = await serviceWorkerService.registerServiceWorker();

    if (!swReg) return;

    try {
        if (checkIfNotificationIsGranted()) {
            const subscription = await swReg.pushManager.getSubscription();

            return subscription;
        }
    } catch (e) {
        log.error(`An error occurred during the getting of existing subscription: ${e}`);
        return null;
    }
};

const clearAppNotifications = (notificationTypes) => {
    return serviceWorkerService.postMessage({type: 'REMOVE_NOTIFICATIONS_AND_BADGES', notificationTypes});
};

const updateAppBadges = () => {
    return serviceWorkerService.postMessage({type: 'UPDATE_BADGES_COUNT'});
};

const removeNotification = (messageId) => {
    return serviceWorkerService.postMessage({type: 'REMOVE_NOTIFICATION', messageId});
};

const unsubscribe = async () => {
    const subscription = await getExistingSubscription();

    if (subscription) {
        try {
            await subscription.unsubscribe();
            log.info(`pushNotificationService: unsubscribe success`);
        } catch (e) {
            log.info(`pushNotificationService: unsubscribe error: ${e}`);
        }
    }
};

const unsubscribeAndDeleteSubscription = async () => {
    await unsubscribe();
    await deleteSubscription();
};

const deleteSubscription = async () => {
    try {
        const {subscriptionId} = getSubscriptionInfo() || {};

        if (!subscriptionId) return;

        await iccNotificationService.deleteSubscription(subscriptionId);
    } catch (e) {
        log.info(`Error occurred during deleting of subscription data: ${e}`);
    } finally {
        clearSubscriptionInfo();
    }
};

const checkIfPushNotificationSupported = async () => {
    if (!('PushManager' in window)) {
        return false;
    }

    if (!('serviceWorker' in navigator)) {
        return false;
    }

    const swReg = await serviceWorkerService.registerServiceWorker();

    if (!swReg) {
        return false;
    }

    return true;
};

const checkIfNotificationScreenShouldBeDisplayed = async () => {
    const isPushNotificationSupported = await checkIfPushNotificationSupported();

    // Push notifications are not supported in the browser
    if (!isPushNotificationSupported) return false;

    // User denied Push Notifications before
    // TODO: add consumerId check, so askPermission for new consumer
    if (checkIfNotificationIsDenied()) return false;

    // Push subscription already created
    if (getSubscriptionInfo()) return false;

    // Permission is granted, get subscription in background
    if (checkIfNotificationIsGranted()) {
        subscribe();
        return false;
    }

    return true;
};

const checkSubscriptionInfoForNewUser = async () => {
    const {consumerId: subscriptionConsumerId} = getSubscriptionInfo() || {};

    if (!subscriptionConsumerId) return;

    const consumerId = selectConsumerId(getState());

    if (subscriptionConsumerId === consumerId) return;

    clearAppNotifications();
    await unsubscribe();
    clearSubscriptionInfo();
};

export default {
    subscribe,
    updateAppBadges,
    getSubscriptionInfo,
    unsubscribe,
    checkIfNotificationScreenShouldBeDisplayed,
    checkSubscriptionInfoForNewUser,
    unsubscribeAndDeleteSubscription,
    removeNotification,
};
