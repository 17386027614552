import React from 'react';

import Icon from '../Icon/Icon';

const AutoStartDisabledIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g transform='translate(8 9.5)' strokeWidth='2' fill='none' fillRule='evenodd'>
                <rect stroke='#34303D' x='12.75' y='9.5' width='10' height='10' rx='2' />
                <g stroke='#383839' strokeLinecap='round'>
                    <path d='M4.83 21.706A14.548 14.548 0 0 0 17.455 29C25.488 29 32 22.508 32 14.5S25.488 0 17.455 0C9.42 0 2.909 6.492 2.909 14.5' />
                    <path strokeLinejoin='round' d='M5.818 13.193 2.835 15.95 0 13.05' />
                </g>
            </g>
        </Icon>
    );
};

export default AutoStartDisabledIcon;
