import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import appConfig from '@/config/appConfig';
import {AUTO_DELIVERY_DETAILS_NONE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import {hideAutoDeliveryStatusNoneDetailsPopup} from '@/state/slices/modalSlice';

import AutoDeliveryDetailsStatusNoneTemplate from './components/AutoDeliveryDetailsStatusNoneTemplate';

const AutoDeliveryStatusNoneDetailsPopup = () => {
    const dispatch = useDispatch();
    const banner = appConfig.getAutoDeliveryDetailedBannerNone();
    const benefitsList = appConfig.getAutoDeliveryStatusNoneBenefitsIcons();
    const howItWorksList = appConfig.getAutoDeliveryStatusNoneHowItWorksIcons();

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryGetStartedButtonClick();
    };

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryGetStartedScreen();
    }, []);

    const onClose = () => dispatch(hideAutoDeliveryStatusNoneDetailsPopup());

    return (
        <AutoDeliveryDetailsStatusNoneTemplate
            banner={banner}
            headerKey={AUTO_DELIVERY_DETAILS_NONE_HEADER_TITLE}
            benefitsItemName='benefitsNoneTitle'
            howItWorksItemName='howItWorksNoneTitle'
            benefitsList={benefitsList}
            howItWorksList={howItWorksList}
            amplitudeEvent={amplitudeEvent}
            onClose={onClose}
        />
    );
};

export default AutoDeliveryStatusNoneDetailsPopup;
