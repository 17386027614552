import {useEffect} from 'react';

import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import {AUTO_DELIVERY_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';
import amplitudeAutoDeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import useOrderSummaryTracking from '../hooks/useOrderSummaryTracking';
import StatusActiveDesktop from './StatusActiveDesktop';
import StatusActiveMobile from './StatusActiveMobile';

const StatusActive = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AUTO_DELIVERY_HEADER_TITLE]);
    const orderSummaryTrackingValue = useOrderSummaryTracking();

    useEffect(() => {
        amplitudeAutoDeliveryTracking.trackAutoDeliveryOrderScreen(
            analyticsEventValues.ACTIVE,
            orderSummaryTrackingValue
        );
    }, []);

    return isDesktopLayout ? <StatusActiveDesktop /> : <StatusActiveMobile />;
};

export default StatusActive;
