import React from 'react';

import {twx} from '@/utils/tailwindUtils';

import {CancelIcon, CancelIcon12} from '../Icons';

const CloseButton = (props) => {
    const {onClick, className, size} = props;

    const isSmall = size === 'small';

    return (
        <button className={twx('cursor-pointer p-2.5 text-primary ml:p-3', className)} onClick={onClick}>
            {isSmall ? <CancelIcon12 /> : <CancelIcon />}
        </button>
    );
};

export default CloseButton;
