import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';

const AutoDeliveryEditOrderButton = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const autoDeliveryEditOrderExternalLink = appConfig.getAutoDeliveryEditOrderExternalLink();
    const redirectToExternalLink = useConfigExternalLink(autoDeliveryEditOrderExternalLink);

    if (!redirectToExternalLink) return;

    return (
        <AppButton
            className='shrink-0 text-16 font-bold text-scarpa-flow md:text-14 md:font-normal'
            onClick={redirectToExternalLink}
            tertiary={isDesktopLayout}
            raw={!isDesktopLayout}
        >
            <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='editOrderButton' />
        </AppButton>
    );
};

export default AutoDeliveryEditOrderButton;
