import cryptoService from '@/services/cryptoService';
import deviceAssetService from '@/services/device/deviceAssetService';
import mediaService from '@/services/mediaService';
import productHelpers from '@/services/product/productHelpers';
import {
    selectConsumerProducts,
    selectConsumerProductsInfo,
    selectConsumerProductsMergedInfo,
} from '@/state/selectors/consumer';
import {
    setConsumerProductsFailed,
    setConsumerProductsInfo,
    updateConsumerProductsInfo,
} from '@/state/slices/consumerSlice';
import {addSignedMedia} from '@/state/slices/mediaSlice';
import {dispatch, getState} from '@/state/store';
import arrayUtils from '@/utils/arrayUtils';
import dateTimeUtils from '@/utils/dateTimeUtils';

import iccMarketService from './iccMarketService';

const MAX_PRODUCTS_COUNT_PER_ONE_REQUEST = 10;

const getDetailedProducts = async (throwException = false) => {
    const consumerProducts = await iccMarketService.getConsumerProducts();

    if (!consumerProducts) {
        dispatch(setConsumerProductsFailed(true));
        return;
    }

    const newProducts = filterNewProducts(consumerProducts);

    await getProductsInfoByConsumerProducts(newProducts, throwException);
    await getProductsAssetsByProducts();
};

const filterNewProducts = (consumerProducts) => {
    const productsInfo = selectConsumerProductsInfo(getState());
    const productsInfoIds = new Set(productsInfo?.map(({deviceSerialNumber}) => deviceSerialNumber));
    const newProducts = consumerProducts?.filter(({deviceSerialNumber}) => !productsInfoIds.has(deviceSerialNumber));

    return newProducts;
};

const getProductsInfoByConsumerProducts = async (consumerProducts, throwException) => {
    if (!consumerProducts?.length) return;

    const codentifyIds = consumerProducts
        .filter(({codentify}) => productHelpers.isOriginalCodentifyValid(codentify))
        .map(({codentify}) => codentify);

    const encryptedProductsIds = consumerProducts
        .filter(({codentify}) => !codentifyIds.includes(codentify))
        .map(({deviceSerialNumber}) => cryptoService.encryptDeviceSerialNumber(deviceSerialNumber))
        .filter((id) => id);

    if (!codentifyIds.length && !encryptedProductsIds.length) {
        dispatch(setConsumerProductsInfo([]));
        return;
    }

    await getProductsInfo({productsIds: codentifyIds, throwException, isCodentify: true});
    await getProductsInfo({productsIds: encryptedProductsIds, throwException});
};

const getProductsInfo = async ({productsIds, throwException, isCodentify}) => {
    const productsIdsSplitted = arrayUtils.splitArray(productsIds, MAX_PRODUCTS_COUNT_PER_ONE_REQUEST);

    const productsInfoRequestsList = productsIdsSplitted.map((consumerProductsIdsChunk) =>
        isCodentify
            ? iccMarketService.getProductInfoListV2({codentifyIds: consumerProductsIdsChunk, throwException})
            : iccMarketService.getProductInfoList({serialNumbers: consumerProductsIdsChunk, throwException})
    );

    return Promise.all(productsInfoRequestsList).then((productsInfoList) => {
        const productsInfoListMerged = [].concat(...productsInfoList);
        const productsInfoFiltered = productsInfoListMerged.filter((productInfo) => !!productInfo);

        dispatch(updateConsumerProductsInfo(productsInfoFiltered));

        return productsInfoListMerged;
    });
};

const getDetailedProductsInfoByCodentifyIds = async (codentifyIds, throwException) => {
    if (!codentifyIds?.length) return;

    return getProductsInfo({productsIds: codentifyIds, throwException, isCodentify: true});
};

const getDetailedProductsInfoBySerialNumbers = async (deviceSerialNumbers, throwException) => {
    if (!deviceSerialNumbers?.length) return;

    const encryptedProductsIds = deviceSerialNumbers
        .map((deviceSerialNumber) => cryptoService.encryptDeviceSerialNumber(deviceSerialNumber))
        .filter((id) => id);

    return getProductsInfo({productsIds: encryptedProductsIds, throwException});
};

const getDetailedProduct = async (deviceSerialNumber, throwException) => {
    const state = getState();
    const consumerProducts = selectConsumerProducts(state);

    if (!consumerProducts) return;

    const consumerProduct = consumerProducts.find((device) => device.deviceSerialNumber === deviceSerialNumber);

    if (!consumerProduct) return;

    const encryptedProductSerialNumber = cryptoService.encryptDeviceSerialNumber(consumerProduct.deviceSerialNumber);
    const productInfoList = await iccMarketService.getProductInfoList({
        serialNumbers: [encryptedProductSerialNumber],
        throwException,
    });
    const productInfo = productInfoList[0];

    if (!productInfo) return;

    const consumerProductsInfoList = selectConsumerProductsInfo(state);
    const consumerProductIndex = consumerProductsInfoList.findIndex(
        (product) => product.deviceSerialNumber === productInfo.deviceSerialNumber
    );

    if (consumerProductIndex >= 0) {
        consumerProductsInfoList.splice(consumerProductIndex, 1);
    }

    consumerProductsInfoList.push(productInfo);

    dispatch(setConsumerProductsInfo(consumerProductsInfoList));
};

const getProductsAssetsByProducts = async () => {
    const consumerProducts = selectConsumerProductsMergedInfo(getState());

    if (!consumerProducts) return [];

    const mediaNamesList = consumerProducts.map(({mediaId}) => mediaId);
    const mediaNamesWithThumbnailsList = deviceAssetService.getDeviceAssetsList(mediaNamesList);

    return getProductsAssets(mediaNamesWithThumbnailsList);
};

const getProductsAssets = async (mediaNames) => {
    const assetsFromState = mediaService.getSignedMediaList();
    const mediaNamesList = Array.isArray(mediaNames) ? arrayUtils.unique(mediaNames) : [mediaNames];
    const mediaNamesToSign = mediaNamesList.filter(
        (mediaName) =>
            !assetsFromState.some(
                (asset) => asset.mediaId === mediaName && !dateTimeUtils.isExpired(asset.expirationDate)
            )
    );

    if (mediaNamesToSign.length) {
        const productAssets = await iccMarketService.getMediaUrl({mediaNameList: mediaNamesToSign});

        if (productAssets) {
            dispatch(addSignedMedia(productAssets));
        } else {
            dispatch(setConsumerProductsInfo([]));
        }

        return productAssets;
    } else {
        return [];
    }
};

export default {
    getDetailedProducts,
    getProductsAssetsByProducts,
    getProductsAssets,
    getDetailedProduct,
    getDetailedProductsInfoByCodentifyIds,
    getDetailedProductsInfoBySerialNumbers,
};
