import {useSelector} from 'react-redux';

import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import {selectAttributionNoticeUrl, selectConsumerName, selectPrivacyNoticeUrl} from '@/state/selectors/consumer';

const useProfilePageSidebar = ({onPreferenceCenterClick, onTermsOfUseClick}) => {
    const userName = useSelector(selectConsumerName);
    const privacyNoticeUrl = useSelector(selectPrivacyNoticeUrl);
    const attributionNoticeUrl = useSelector(selectAttributionNoticeUrl);

    const handlePreferenceCenterClick = () => {
        if (onPreferenceCenterClick) {
            onPreferenceCenterClick();
            return;
        }

        appRouterService.replaceToProfilePreferenceCenterPage();
    };

    const handleLanguageSelectClick = () => {
        appRouterService.replaceToProfileLanguageSelectPage();
    };

    const handleContactUsClick = () => {
        appRouterService.replaceToContactUsPage();
    };

    const handleTermsOfUseClick = () => {
        if (onTermsOfUseClick) {
            onTermsOfUseClick();
            return;
        }

        amplitudeProfileTracking.trackTermsOfUseClick();
        googleAnalyticsService.trackTermsOfUseClick();
        appRouterService.replaceToProfileTermsOfUse();
    };

    const handlePrivacyNoticeClick = () => {
        amplitudeProfileTracking.trackPrivacyNoticeClick();
        googleAnalyticsService.trackPrivacyNoticeClick();
        routerService.blankLinkOpen(privacyNoticeUrl);
    };

    const handleAttributionNoticeClick = () => {
        amplitudeProfileTracking.trackAttributionNoticeClick();
        googleAnalyticsService.trackAttributionNoticeClick();
        routerService.blankLinkOpen(attributionNoticeUrl);
    };

    return {
        userName,
        handlePreferenceCenterClick,
        handleContactUsClick,
        handleLanguageSelectClick,
        handleTermsOfUseClick,
        handlePrivacyNoticeClick,
        handleAttributionNoticeClick,
    };
};

export default useProfilePageSidebar;
