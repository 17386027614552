import {useState} from 'react';

const useHighlightsAnimationDuration = ({highlights, autoplay, currentIndex}) => {
    const [slidesDuration, setSlidesDuration] = useState({});

    const getAnimationDuration = (i) => {
        if (autoplay) {
            if (highlights[i].banner.videoPath) {
                if (slidesDuration[i]) {
                    return slidesDuration[i];
                } else {
                    return 0;
                }
            } else {
                return highlights[i]?.duration;
            }
        }
    };

    const handleLoadedMetadata = (metadata, slideIndex) => {
        if (metadata) {
            setSlidesDuration({[slideIndex]: metadata.duration, ...slidesDuration});
        }
    };

    const handleVideoError = (slideIndex) => {
        setSlidesDuration({[slideIndex]: highlights[currentIndex]?.duration, ...slidesDuration});
    };

    return {getAnimationDuration, handleLoadedMetadata, handleVideoError};
};

export default useHighlightsAnimationDuration;
