import cn from 'classnames';
import React from 'react';
import {RemoveScroll} from 'react-remove-scroll';

import {twx} from '@/utils/tailwindUtils';

import AppPopup from './AppPopup';
import styles from './Popup.module.scss';
import useOneModalOnPageAtATime from './useOneModalOnPageAtATime';

const PopupSticked = (props) => {
    const {className, children, onClose, ...rest} = props;
    const isVisible = useOneModalOnPageAtATime();

    if (!isVisible) return null;

    return (
        <RemoveScroll>
            <AppPopup
                isOpen
                className={twx(styles.PopupContainer, 'rounded-t-3xl px-4 pt-0 ml:rounded-3xl', className)}
                overlayClassName={cn(styles.Overlay)}
                onClose={onClose}
                {...rest}
                isCloseVisible={false}
            >
                {children}
            </AppPopup>
        </RemoveScroll>
    );
};

export default PopupSticked;
