import React, {useEffect} from 'react';

import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import PopupOutline from '@/components/Popup/PopupOutline';
import {LEVIA_POPUP_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import amplitudeInboxTracking from '@/services/analytics/amplitude/amplitudeInboxTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import LeviaLearnMoreArticle from './LeviaLearnMoreArticle/LeviaLearnMoreArticle';
import LeviaLearnMoreButtons from './LeviaLearnMoreArticle/LeviaLearnMoreButtons';

const LeviaLearnMorePopup = ({modalType, source}) => {
    const isMobile = useIsMobileLayout();
    const onClose = () => modalService.hideModal(modalType);

    useEffect(() => {
        amplitudeInboxTracking.trackActionScreen(source);
        googleAnalyticsService.trackLeviaScreenView(source);
    }, []);

    return isMobile ? <MobileLeviaLearnMore onClose={onClose} /> : <DesktopLeviaLearnMore onClose={onClose} />;
};

const MobileLeviaLearnMore = ({onClose}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupFullPage className='z-20 flex flex-col bg-romance'>
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[LEVIA_POPUP_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={<LeviaLearnMoreButtons onCancel={onClose} />}
            >
                <LeviaLearnMoreArticle />
                <WarningBanners source={WarningBanners.SOURCES.LEVIA_POPUP} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

const DesktopLeviaLearnMore = ({onClose}) => {
    return (
        <PopupOutline className='w-full max-w-[457px] rounded-3xl p-8' hasNavbarOffset onClose={onClose}>
            <LeviaLearnMoreArticle />
            <LeviaLearnMoreButtons onCancel={onClose} />
        </PopupOutline>
    );
};

export default LeviaLearnMorePopup;
