import React from 'react';

import SwitchToggle from '@/components/SwitchToggle/SwitchToggle';

import ExpansionPanel from './ExpansionPanel';

const DCExpansionPanel = ({index, title, innerText, onChange, isActive, onTncLinkClick}) => {
    return (
        <ExpansionPanel
            className='w-full'
            innerText={innerText}
            title={title}
            index={index}
            onTncLinkClick={onTncLinkClick}
        >
            <SwitchToggle
                aria-describedby={index}
                labelId={index}
                isActive={isActive}
                onChange={onChange}
                className='shrink-0'
            />
        </ExpansionPanel>
    );
};

export default DCExpansionPanel;
