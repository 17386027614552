import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {WEB_PREFERENCE_CENTER} from '@/consts/tnc/tncTypes';
import {hideLoader} from '@/state/slices/loadersSlice';
import TncDetailsContent from '@/views/Tnc/TncDetailsContent/TncDetailsContent';

const ProfilePreferenceCenterPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Hide loader that was shown before navigating to this page (see HomePageTopNavigation)
        dispatch(hideLoader());
    }, []);

    return <TncDetailsContent pageType={WEB_PREFERENCE_CENTER} />;
};

export default ProfilePreferenceCenterPage;
