import {useSelector} from 'react-redux';

import {selectIsLgLayout} from '@/state/selectors/global';

const useIsLgLayout = () => {
    const isLgLayout = useSelector(selectIsLgLayout);

    return isLgLayout;
};

export default useIsLgLayout;
