import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import LanguageSelect from '@/components/LanguageSelect/LanguageSelect';
import {ContentWrapper, MaxWidthContainer, StickyHeader} from '@/components/Layout';
import {LOGIN_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const LoginHeader = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[LOGIN_PAGE_HEADER_TITLE]);

    return (
        <ContentWrapper className='pt-safe-offset-sticky-header gap-[18px] pb-0 ml:mx-8 ml:pt-safe-offset-8 lg:mx-8'>
            {isDesktopLayout ? (
                <LoginLanguageSelect />
            ) : (
                <StickyHeader title={localizedStrings[LOGIN_PAGE_HEADER_TITLE]} endIcon={<LoginLanguageSelect />} />
            )}
            <MaxWidthContainer className='gap-[10px] ml:px-8'>
                <h1 className='mb-0 leading-[40px] md:mb-6 ml:text-28'>
                    <AemComponent component={EditableText} pageName={routePaths.LOGIN} itemName='title' />
                </h1>
                <AemComponent component={EditableText} pageName={routePaths.LOGIN} itemName='description' />
            </MaxWidthContainer>
        </ContentWrapper>
    );
};

const LoginLanguageSelect = () => <LanguageSelect isDark popoverAlign='end' popoverArrowHeight={12} />;

export default LoginHeader;
