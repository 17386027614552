import React from 'react';

import Icon from '../Icon/Icon';

const SmallBlueSunIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M24.2 31.59a7.391 7.391 0 1 0 0-14.782 7.391 7.391 0 0 0 0 14.783z'
                    stroke='#272A33'
                    strokeWidth='2'
                />
                <path
                    d='M24.2 27.156a2.957 2.957 0 1 0 0-5.913 2.957 2.957 0 0 0 0 5.913z'
                    fill='#00D1D2'
                    fillRule='nonzero'
                />
                <path
                    d='M24.2 29.2a5 5 0 1 0 0-10 5 5 0 0 0 0 10z'
                    fill='#00D1D2'
                    fillRule='nonzero'
                    className='icon-accent-stroke icon-accent-fill'
                />
                <g stroke='#272A33' strokeLinecap='round' strokeWidth='2'>
                    <path d='M24.2 34.899v2.5M24.2 13.5V11' />
                </g>
                <g stroke='#272A33' strokeLinecap='round' strokeWidth='2'>
                    <path d='m16.634 31.765-1.768 1.768M31.765 16.634l1.768-1.768' />
                </g>
                <g stroke='#272A33' strokeLinecap='round' strokeWidth='2'>
                    <path d='M13.5 24.2H11M34.899 24.2h2.5' />
                </g>
                <g stroke='#272A33' strokeLinecap='round' strokeWidth='2'>
                    <path d='m16.633 16.634-1.767-1.768M31.765 31.765l1.768 1.768' />
                </g>
            </g>
        </Icon>
    );
};

export default SmallBlueSunIcon;
