import {useDispatch} from 'react-redux';
import {toast} from 'sonner';

import * as localizationKeys from '@/consts/localization/localizationKeys';
import inboxMessageService from '@/services/inboxMessageService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {markInboxMessageAsPendingDeletionAction} from '@/state/slices/inboxSlice';

const useMessageDeletionWithUndo = ({messageId}) => {
    const dispatch = useDispatch();
    const localizedStrings = getLocalizedStrings();

    const markMessageAsPendingDeletion = () => {
        dispatch(markInboxMessageAsPendingDeletionAction({messageId, isPendingDeletion: true}));
    };
    const undoMessageDeletion = () => {
        dispatch(markInboxMessageAsPendingDeletionAction({messageId, isPendingDeletion: false}));
    };
    const finalizeMessageDeletion = () => {
        inboxMessageService.deleteInboxMessage(messageId);
    };
    const showUndoToast = () => {
        toast(localizedStrings[localizationKeys.DELETE_INBOX_MESSAGE_TOAST_TITLE], {
            action: {
                label: localizedStrings[localizationKeys.DELETE_INBOX_MESSAGE_TOAST_UNDO_BUTTON],
                onClick: undoMessageDeletion,
            },
            onDismiss: finalizeMessageDeletion,
            onAutoClose: finalizeMessageDeletion,
        });
    };

    const handleMessageDeleteClick = () => {
        showUndoToast();
        markMessageAsPendingDeletion();
    };

    return handleMessageDeleteClick;
};

export default useMessageDeletionWithUndo;
