import {useState} from 'react';

import useDidUpdate from '@/hooks/useDidUpdate';

import useHighlightsExtendedControls from './useHighlightsExtendedControls';

const useHighlightsCarousels = (carouselsFromConfig, visibleCount) => {
    const [carousels, setCarousels] = useState(limitCarousels(carouselsFromConfig, visibleCount));
    const [carouselStates, setCarouselStates] = useState({
        currentCarousel: 0,
        carousels: carousels.map(getInitialCarouselState),
    });
    const {setCarouselRef, switchToCarousel, endCarousel, changeCarouselSlide} = useHighlightsExtendedControls();

    useDidUpdate(() => {
        const updated = updateVisibleCarousels({
            carouselsFromConfig,
            carousels,
            carouselStates,
            visibleCount,
            switchToCarousel,
        });

        setCarousels(updated.carousels);
        setCarouselStates(updated.carouselStates);
    }, [visibleCount]);

    const handleCarouselClick = (i) => {
        setCarouselStates((carouselStates) => {
            const {currentCarousel, carousels} = carouselStates;

            if (i === currentCarousel) {
                return carouselStates;
            }

            const newCarousels = switchToCarousel(carousels, {from: currentCarousel, to: i});

            return {
                currentCarousel: i,
                carousels: newCarousels,
            };
        });
    };

    const handleSlideChange = (i, slide) => {
        setCarouselStates(({carousels, currentCarousel}) => {
            const newCarousels = changeCarouselSlide(carousels, i, {newSlide: slide});

            return {
                carousels: newCarousels,
                currentCarousel,
            };
        });
    };

    const handleCarouselEnded = () => {
        setCarouselStates((carouselStates) => {
            const {currentCarousel: current, carousels} = carouselStates;
            const isSingleCarousel = carousels.length === 1;

            if (isSingleCarousel) {
                const newCarousels = changeCarouselSlide(carousels, current, {newSlide: 0});

                return {
                    ...carouselStates,
                    carousels: newCarousels,
                };
            } else {
                const next = (current + 1) % carousels.length;
                let newCarousels = carousels;

                newCarousels = endCarousel(newCarousels, current);
                newCarousels = switchToCarousel(newCarousels, {from: current, to: next});

                return {
                    carousels: newCarousels,
                    currentCarousel: next,
                };
            }
        });
    };

    return {
        carousels,
        carouselStates: carouselStates.carousels,
        setCarouselRef,
        handleCarouselClick,
        handleSlideChange,
        handleCarouselEnded,
    };
};

const limitCarousels = (carousels, limit) => {
    return carousels.slice(0, limit);
};

const getInitialCarouselState = (carousel, carouselIndex) => {
    return {
        slideCount: carousel.highlights.length,
        currentSlide: 0,
        isPlaying: carouselIndex === 0,
        isEnded: false,
    };
};

const updateVisibleCarousels = ({carouselsFromConfig, carousels, carouselStates, visibleCount, switchToCarousel}) => {
    const currentCount = carousels.length;

    if (visibleCount === currentCount) return {carousels, carouselStates};

    const newCarousels = limitCarousels(carouselsFromConfig, visibleCount);
    const {carousels: states, currentCarousel} = carouselStates;
    let newCarouselStates;

    if (visibleCount < currentCount) {
        let newStates = states.slice(0, visibleCount);
        const lastCarousel = visibleCount - 1;
        const newCurrentCarousel = currentCarousel > lastCarousel ? 0 : currentCarousel;

        if (newCurrentCarousel !== currentCarousel) {
            newStates = switchToCarousel(newStates, {to: newCurrentCarousel});
        }

        newCarouselStates = {carousels: newStates, currentCarousel: newCurrentCarousel};
    } else {
        const addedCarousels = carouselsFromConfig.slice(currentCount, visibleCount);
        const addedCarouselStates = addedCarousels.map((carousel, i) =>
            getInitialCarouselState(carousel, currentCount + i)
        );
        const newStates = [...states, ...addedCarouselStates];

        newCarouselStates = {carousels: newStates, currentCarousel};
    }

    return {carousels: newCarousels, carouselStates: newCarouselStates};
};

export default useHighlightsCarousels;
