import React from 'react';
import {useDispatch} from 'react-redux';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import routePaths from '@/consts/route/routePaths';
import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import authService from '@/services/auth/authService';
import pushNotificationService from '@/services/pushNotificationService';
import {showLoader} from '@/state/slices/loadersSlice';

const ConsumerProfileLogoutButton = ({className}) => {
    const dispatch = useDispatch();

    const onLogoutClick = async () => {
        const LOADER_NAME = 'LOGOUT_LOADER';

        amplitudeProfileTracking.trackLogOutClick();
        dispatch(showLoader(LOADER_NAME));

        await pushNotificationService.unsubscribeAndDeleteSubscription();
        authService.logout();
    };

    return (
        <AppButton className={className} onClick={onLogoutClick} tertiary>
            <AemEditableText pageName={routePaths.CONSUMER_PROFILE} itemName='logoutButton' />
        </AppButton>
    );
};

export default ConsumerProfileLogoutButton;
