import {twx} from '@/utils/tailwindUtils';

const HighlightsCarouselContainer = ({className, children}) => {
    return (
        <div className={twx('overflow-hidden rounded-xl md:aspect-[0.71] ml:aspect-[0.62] lg:aspect-2/3', className)}>
            {children}
        </div>
    );
};

export default HighlightsCarouselContainer;
