import React, {useEffect} from 'react';

import useConsumerProductsLoader from '@/hooks/useConsumerProductsLoader';
import useRefetchConsumerProducts from '@/hooks/useRefetchConsumerProducts';
import amplitudeDevicesPageTracking from '@/services/analytics/amplitude/amplitudeDevicesPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';

import MyDevicesView from './components/MyDevicesView/MyDevicesView';
import NoDevicesView from './components/NoDevicesView/NoDevicesView';
import useGetDeviceSectionList from './hooks/useGetDeviceSectionList';

const DevicesPage = () => {
    const deviceList = useGetDeviceSectionList();
    const isDeviceListEmpty = !deviceList?.length;

    useEffect(() => {
        amplitudeDevicesPageTracking.trackDevicesPageScreen();
        googleAnalyticsService.trackDeviceScreenView();
    }, []);

    useRefetchConsumerProducts();
    useConsumerProductsLoader();

    return isDeviceListEmpty ? <NoDevicesView /> : <MyDevicesView />;
};

export default DevicesPage;
