import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {StickyPageLayout} from '@/components/Layout';
import PageHeaderMedia from '@/components/PageHeaderMedia/PageHeaderMedia';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import useOneModalOnPageAtATime from '@/components/Popup/useOneModalOnPageAtATime';
import appConfig from '@/config/appConfig';
import ModalTypes from '@/consts/app/modalTypes';
import amplitudeNotificationTracking from '@/services/analytics/amplitude/amplitudeNotificationTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import domService from '@/services/domService';
import pushNotificationService from '@/services/pushNotificationService';
import storageService from '@/services/storage/storageService';
import {selectIsMobileLayout} from '@/state/selectors/global';
import {hideModalAction} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';

import EnableNotificationModalButton from './EnableNotificationModalButton';
import EnableNotificationModalContent from './EnableNotificationModalContent';

const EnableNotificationModal = () => {
    const isMobile = useSelector(selectIsMobileLayout);
    const imageMedia = appConfig.getEnableNotificationPageHeaderImageMedia();
    const videoSrc = appConfig.getEnableNotificationPageHeaderVideoSrc();
    const videoThumbnailSrc = appConfig.getEnableNotificationPageHeaderVideoThumbnailSrc();

    useOneModalOnPageAtATime();

    const onClick = async () => {
        amplitudeNotificationTracking.trackEnableNotificationScreenNextButton();
        await pushNotificationService.subscribe();
        storageService.setPermissionDeniedLocalStorage(false);

        dispatch(hideModalAction(ModalTypes.ENABLE_NOTIFICATION));
    };

    const onClose = () => {
        storageService.setPermissionDeniedLocalStorage(true);
        dispatch(hideModalAction(ModalTypes.ENABLE_NOTIFICATION));
    };

    useEffect(() => {
        amplitudeNotificationTracking.trackEnableNotificationScreen();
        googleAnalyticsService.trackEnableNotificationScreenView();
    }, []);

    return (
        <PopupResponsive
            className='flex md:rounded-3xl md:p-8 md:text-center ml:max-w-[458px]'
            onClose={onClose}
            parentRef={domService.getPageElement()}
        >
            {isMobile ? (
                <StickyPageLayout
                    className='items-center'
                    contentClassName='flex flex-1 flex-col items-center'
                    footer={<EnableNotificationModalButton onClick={onClick} />}
                >
                    <PageHeaderMedia
                        isVideoAvailable={!!videoThumbnailSrc}
                        videoThumbnailSrc={videoThumbnailSrc}
                        videoSrc={videoSrc}
                        imageMedia={imageMedia}
                    />
                    <EnableNotificationModalContent onBtnClick={onClick} isMobile={isMobile} />
                </StickyPageLayout>
            ) : (
                <EnableNotificationModalContent>
                    <EnableNotificationModalButton onClick={onClick} />
                </EnableNotificationModalContent>
            )}
        </PopupResponsive>
    );
};

export default EnableNotificationModal;
