import cn from 'classnames';
import React, {forwardRef} from 'react';

import useIsMobileLayout from '@/hooks/useIsMobileLayout';

import InputComponent from './InputComponent';

// eslint-disable-next-line react/display-name
const RadioButtonChildComponent = forwardRef((props, ref) => {
    const {isDefaultMode, defaultDeviceName, deviceNameText, ...restProps} = props;
    const isMobile = useIsMobileLayout();

    return (
        <div className={cn('relative w-full items-start bg-cararra px-8 py-6 ml:rounded-xl ml:bg-secondary ml:p-4')}>
            <div className='text-start'>
                <p
                    className={cn(
                        'overflow-hidden text-ellipsis text-12 ml:text-16 ml:leading-15',
                        isMobile && isDefaultMode ? 'mb-3' : 'mb-4'
                    )}
                >
                    {deviceNameText}
                </p>
                {isDefaultMode ? (
                    <p className='w-full text-20'>{defaultDeviceName}</p>
                ) : (
                    <InputComponent {...restProps} ref={ref} />
                )}
            </div>
        </div>
    );
});

export default RadioButtonChildComponent;
