import useHighlightsCarouselControls from '../../common/hooks/useHighlightsCarouselControls';

const useHighlightsExtendedControls = () => {
    const controls = useHighlightsCarouselControls();
    const {playCarousel, pauseCarousel, restartCarousel} = controls;

    const switchToCarousel = (carousels, {from, to}) => {
        if (from === to) return carousels;

        let newCarousels = carousels;

        newCarousels = playCarousel(newCarousels, to);
        if (from !== null && from !== undefined) {
            newCarousels = pauseCarousel(newCarousels, from);
        }

        const newCarousel = newCarousels[to];

        if (newCarousel.isEnded) {
            newCarousels = restartCarousel(newCarousels, to);
        }

        return newCarousels;
    };

    return {...controls, switchToCarousel};
};

export default useHighlightsExtendedControls;
