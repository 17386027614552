import userDeviceService from '@/services/user/userDeviceService';

import uaUtils from '../../utils/uaUtils';

const SAFARI_MOBILE_NAME = 'Mobile Safari';
const CHROME_NAME = 'Chrome';
const EDGE_NAME = 'Edge';
const SAFARI_NAME = 'Safari';

const isIosChromeBrowser = () => uaUtils.getBrowserName() === CHROME_NAME && uaUtils.getIsIos();

const isIosSafariBrowser = () => uaUtils.getBrowserName() === SAFARI_MOBILE_NAME && uaUtils.getIsIos();

const isBluefyBrowser = () => !!navigator.userAgent.match(/Bluefy/i);

const isChromeBrowser = () => uaUtils.getBrowserName() === CHROME_NAME;

const isEdgeBrowser = () => uaUtils.getBrowserName() === EDGE_NAME;

const isSafariBrowser = () => uaUtils.getBrowserName() === SAFARI_NAME;

const getUserAgent = () => navigator.userAgent;

const isInStandaloneMode = () => {
    if (window.navigator.standalone) {
        return true;
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
        return true;
    }

    return false;
};

const isExtensionsAvailable = () => !!window.chrome && !!window.chrome.runtime;

const isBrowserTrusted = () => {
    const isEdgeBrowser = !!navigator.userAgent.match(/Edg/i);
    const isEdgeAndroidBrowser = !!navigator.userAgent.match(/EdgA/i);
    const isEdgeIOSBrowser = !!navigator.userAgent.match(/EdgiOS/i);
    const isBraveBrowser = !!navigator.brave;

    const isBrowserTrusted =
        (isBluefyBrowser() || (isChromeBrowser() && !!window.chrome) || isEdgeBrowser || isEdgeAndroidBrowser) &&
        !isBraveBrowser;

    if (userDeviceService.isMobileDevice()) {
        // RS: Check mobile browser on extensions availability
        // to avoid some mobile browsers (Kiwi Browser)
        return isBrowserTrusted && (isEdgeAndroidBrowser ? true : !isExtensionsAvailable()) && !isEdgeIOSBrowser;
    } else {
        return isBrowserTrusted;
    }
};

export default {
    getUserAgent,
    isBluefyBrowser,
    isInStandaloneMode,
    isIosSafariBrowser,
    isIosChromeBrowser,
    isChromeBrowser,
    isEdgeBrowser,
    isSafariBrowser,
    isBrowserTrusted,
};
