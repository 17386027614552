import analyticsEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';

import amplitude from './amplitude';

const trackConsumerProfileScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.PROFILE_SCREEN_VIEWED);
};

const trackPreferenceCenterScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.PREFERENCE_CENTER_VIEWED);
};

const trackUpdateYourDataScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.PREFERENCE_CENTER_UPDATE_VIEWED);
};

const trackContactUsScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.CONTACT_US_VIEWED);
};

const trackShowProfileClick = () => {
    amplitude.trackWithSource(analyticsEventNames.SHOW_PROFILE_CLICKED);
};

const trackTermsOfUseClick = () => {
    amplitude.trackWithSource(analyticsEventNames.LEGAL_VIEWED, {
        [analyticsEventProperties.LEGAL]: analyticsEventValues.TERMS_OF_USE,
    });
};

const trackPrivacyNoticeClick = () => {
    amplitude.trackWithSource(analyticsEventNames.LEGAL_VIEWED, {
        [analyticsEventProperties.LEGAL]: analyticsEventValues.PRIVACY_NOTICE,
    });
};

const trackAttributionNoticeClick = () => {
    amplitude.trackWithSource(analyticsEventNames.LEGAL_VIEWED, {
        [analyticsEventProperties.LEGAL]: analyticsEventValues.ATTRIBUTION_NOTICE,
    });
};

const trackLogOutClick = () => {
    amplitude.trackWithSource(analyticsEventNames.LOG_OUT);
};

export default {
    trackConsumerProfileScreen,
    trackPreferenceCenterScreen,
    trackUpdateYourDataScreen,
    trackContactUsScreen,
    trackShowProfileClick,
    trackTermsOfUseClick,
    trackPrivacyNoticeClick,
    trackAttributionNoticeClick,
    trackLogOutClick,
};
