import {add, Identify, identify, init, setOptOut, setUserId, track} from '@amplitude/analytics-browser';

import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import * as sourceTypes from '@/consts/sourceTypes';
import userBrowserService from '@/services/user/userBrowserService';
import cryptoUtils from '@/utils/cryptoUtils';

import EnricherPlugin from './plugins/EnricherPlugin';

const initAmplitude = (apiKey) => {
    add(new EnricherPlugin());
    init(apiKey, undefined, {
        serverZone: 'EU',
        appVersion: process.env.REACT_APP_VERSION,
        // Disable tracking while TNC is not fetched
        optOut: true,
    });
};

const setAmplitudeUserId = (userId) => {
    setUserId(userId);
};

const setMarket = (marketName) => {
    const identifyObj = new Identify();

    identifyObj.set(analyticsEventProperties.MARKET, marketName);

    identify(identifyObj);
};

const enableAmplitude = (isEnabled) => {
    setOptOut(!isEnabled);
};

const getPushSource = (source) => {
    switch (source) {
        case sourceTypes.HOMEPAGE:
            return analyticsEventValues.HOMEPAGE;
        case sourceTypes.INBOX:
            return analyticsEventValues.INBOX;
        default:
            return null;
    }
};

const trackEvent = ({eventType, serialNumber, modelName, properties}) => {
    trackWithSource(eventType, {
        [analyticsEventProperties.IQOS_PRODUCT_MODEL_TYPE]: modelName,
        [analyticsEventProperties.IQOS_PRODUCT_DEVICE_ID]: cryptoUtils.hashMD5(serialNumber),
        ...properties,
    });
};

const trackWithSource = (eventType, properties) => {
    const source = userBrowserService.isInStandaloneMode() ? analyticsEventValues.PWA : analyticsEventValues.BROWSER;

    track(eventType, {
        ...properties,
        [analyticsEventProperties.SOURCE]: source,
    });
};

export default {
    initAmplitude,
    setAmplitudeUserId,
    setMarket,
    getPushSource,
    enableAmplitude,
    trackEvent,
    trackWithSource,
};
