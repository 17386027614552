import React from 'react';

import Icon from '../Icon/Icon';

const RenameIcon = (props) => {
    return (
        <Icon width='19' height='19' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.15912 14.7252L16.03 1.85429C16.2252 1.65903 16.5418 1.65903 16.7371 1.85429L18.108 3.22521C18.3033 3.42048 18.3033 3.73706 18.108 3.93232L5.23714 16.8032L2.21427 18.1854C2.00832 18.2795 1.79329 18.0732 1.87883 17.8636L3.15912 14.7252Z'
                stroke='#34303D'
                strokeWidth='1.5'
            />
            <path
                d='M12.4939 18.125H2.02637'
                stroke='#34303D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.0038 4.46776L15.6626 6.11505'
                stroke='#34303D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default RenameIcon;
