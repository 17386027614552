import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import LayoutSizes from '@/consts/layout/layoutSizes';
import LayoutTypes from '@/consts/layout/layoutTypes';
import useWindowResize from '@/hooks/useWindowResize';
import {selectLayoutType} from '@/state/selectors/global';
import {setLayout} from '@/state/slices/globalSlice';

const useLayoutChange = () => {
    const layoutType = useSelector(selectLayoutType);
    const dispatch = useDispatch();
    const getSize = () => ({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [windowSize, setWindowSize] = useState(getSize());
    const onResize = () => setWindowSize(getSize());
    const currentLayout = getLayout(windowSize.width);

    useWindowResize(onResize);
    useEffect(() => {
        if (layoutType !== currentLayout) {
            dispatch(setLayout(currentLayout));
        }
    }, [layoutType, currentLayout]);
};

const getLayout = (deviceWidth) => {
    if (deviceWidth < LayoutSizes.SM) {
        return LayoutTypes.XS;
    } else if (deviceWidth < LayoutSizes.MD) {
        return LayoutTypes.SM;
    } else if (deviceWidth < LayoutSizes.ML) {
        return LayoutTypes.MD;
    } else if (deviceWidth < LayoutSizes.LG) {
        return LayoutTypes.ML;
    } else {
        return LayoutTypes.LG;
    }
};

export default useLayoutChange;
