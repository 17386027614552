import React from 'react';

const HighlightsHeader = ({title}) => {
    return (
        <h1 className='m-0 line-clamp-1 h-10 text-ellipsis pl-16 pr-28 text-left text-28 leading-14 text-romance'>
            {title}
        </h1>
    );
};

export default HighlightsHeader;
