import React, {useEffect} from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import SupportAndStoreTemplate from '@/components/SupportAndStoreTemplate/SupportAndStoreTemplate';
import * as advanceDiagnosticIconTypes from '@/consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import routePaths from '@/consts/route/routePaths';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appRouterService from '@/services/route/appRouterService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';

const AdvancedDiagnosticFittingConsumablesSolved = () => {
    useEffect(() => {
        amplitudeADTracking.trackADInitialQuestionnaireScreen();
        googleAnalyticsService.trackAdInitialQuestionnaireScreenView();
    }, []);

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer isDeviceHero>
                <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.SUCCESS} />
                <div className='px-8'>
                    <AdvancedDiagnosticTitle itemName='consumablessolvedtitle' />
                    <AdvancedDiagnosticDescription itemName='consumablessolveddesc' />
                </div>
                <div className='mt-10 w-full px-8 md:w-auto'>
                    <AppButton primary light onClick={appRouterService.forwardToDevicesPage} className='mb-4 mt-0'>
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.ADVANCE_DIAGNOSTIC}
                            itemName='consumablessolvedbutton'
                        />
                    </AppButton>
                </div>
                <SupportAndStoreTemplate className='mb-4 mt-0 md:mb-6 md:flex-col ml:flex-row' />
            </AdvancedDiagnosticContainer>
            <WarningBanners source={WarningBanners.SOURCES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticFittingConsumablesSolved;
