import {useEffect, useState} from 'react';

import useDidUpdate from '@/hooks/useDidUpdate';

let touchStart = null;

const DELETE_CONTAINER_WIDTH = 88;
const DELETE_BUTTON_WIDTH = 88;
const MIN_DELTA_TO_OPEN = 40;
const MIN_DELTA_TO_CLOSE = 20;
const VERTICAL_SCROLL_DELTA = 5;
const HORIZONTAL_SCROLL_DELTA = 6;
const SWIPE_TRANSITION = 'clip-path 0.2s';

const useDeviceListItemSwipe = ({deviceId, touchedDeviceId, onDeviceTouch, isEnabled}) => {
    const [isEndOfSwipe, setIsEndOfSwipe] = useState(true);
    const [isOpened, setIsOpened] = useState(false);
    const [cropLeft, setCropLeft] = useState(DELETE_CONTAINER_WIDTH);
    const [isVerticalScrolling, setIsVerticalScrolling] = useState(false);
    const [isHorizontalScrolling, setIsHorizontalScrolling] = useState(false);
    const {animationStyle, onTransitionEnd} = useAnimationStyle({cropLeft, isEndOfSwipe});

    useEffect(() => {
        if (!touchedDeviceId) return;
        if (cropLeft === DELETE_CONTAINER_WIDTH) return;

        if (deviceId !== touchedDeviceId) {
            closeButton();
        }
    }, [touchedDeviceId]);

    if (!isEnabled) {
        return {animationStyle: getStyle({cropLeft: DELETE_CONTAINER_WIDTH})};
    }

    const onTouchStart = (e) => {
        touchStart = e.changedTouches[0];
        onDeviceTouch(deviceId);
        setIsEndOfSwipe(false);
    };

    const onTouchMove = (e) => {
        if (touchStart === null) return;
        // User scrolls vertically - do no do anything
        if (isVerticalScrolling) return;

        const deltaY = touchStart.pageY - e.changedTouches[0].pageY;

        // User scrolls vertically
        if (!isHorizontalScrolling && Math.abs(deltaY) > VERTICAL_SCROLL_DELTA) {
            setIsVerticalScrolling(true);
            return;
        }

        const deltaX = touchStart.pageX - e.changedTouches[0].pageX;

        // User scrolls horizontally
        if (Math.abs(deltaX) > HORIZONTAL_SCROLL_DELTA) {
            setIsHorizontalScrolling(true);
        } else {
            // Do not do anything until the user has scrolled more than HORIZONTAL_SCROLL_DELTA pixels
            return;
        }

        const widthToCrop = DELETE_CONTAINER_WIDTH - (isOpened ? DELETE_BUTTON_WIDTH : 0) - deltaX;

        if (widthToCrop <= 0) {
            setCropLeft(0);
            return;
        }

        if (widthToCrop >= DELETE_CONTAINER_WIDTH) {
            setCropLeft(DELETE_CONTAINER_WIDTH);
            return;
        }

        setCropLeft(widthToCrop);
    };

    const onTouchEnd = (e) => {
        setIsHorizontalScrolling(false);
        setIsVerticalScrolling(false);
        if (isVerticalScrolling) {
            return;
        }

        const deltaX = touchStart.pageX - e.changedTouches[0].pageX;
        const enoughSwipeForOpening = deltaX > MIN_DELTA_TO_OPEN;
        const enoughSwipeForClosing = deltaX < -MIN_DELTA_TO_CLOSE;

        if (isOpened) {
            if (enoughSwipeForClosing) {
                closeButton();
            } else {
                openButton();
            }
        }

        if (!isOpened) {
            if (enoughSwipeForOpening) {
                openButton();
            } else {
                closeButton();
            }
        }
    };

    const openButton = () => {
        setIsEndOfSwipe(true);
        setIsOpened(true);
        setCropLeft(DELETE_CONTAINER_WIDTH - DELETE_BUTTON_WIDTH);
    };
    const closeButton = () => {
        setIsEndOfSwipe(true);
        setIsOpened(false);
        setCropLeft(DELETE_CONTAINER_WIDTH);
    };

    return {animationStyle, onTouchStart, onTouchMove, onTouchEnd, onTransitionEnd};
};

const useAnimationStyle = ({cropLeft, isEndOfSwipe}) => {
    const [animationStyle, setAnimationStyle] = useState();

    useDidUpdate(() => {
        if (!isEndOfSwipe) {
            const cropLeftOrDefault = getCropLeftOrDefault(cropLeft);
            // If swipe is in progress, swipe transition should be applied
            const style = getStyle({cropLeft: cropLeftOrDefault, includeSwipeTransition: true});

            setAnimationStyle(style);
        } else {
            const cropLeftOrDefault = getCropLeftOrDefault(cropLeft);
            // If swipe has ended and cropLeft is in an intermediate state, the button should still
            // have swipe transition until animation completes, only then will the transition be restored
            // to its default value in onTransitionEnd.
            // However, if cropLeft is already in its final state, there will be no animation
            // and onTransitionEnd will not be triggered, so the transition should be restored here.
            const includeSwipeTransition = cropLeft !== 0 && cropLeft !== DELETE_CONTAINER_WIDTH;
            const style = getStyle({cropLeft: cropLeftOrDefault, includeSwipeTransition});

            setAnimationStyle(style);
        }
    }, [cropLeft, isEndOfSwipe]);

    const onTransitionEnd = () => {
        if (isEndOfSwipe) {
            const cropLeftOrDefault = getCropLeftOrDefault(cropLeft);
            const style = getStyle({cropLeft: cropLeftOrDefault, includeSwipeTransition: false});

            setAnimationStyle(style);
        }
    };

    const getCropLeftOrDefault = (cropLeft) => (cropLeft === DELETE_CONTAINER_WIDTH ? undefined : cropLeft);

    return {animationStyle, onTransitionEnd};
};

const getStyle = ({cropLeft, includeSwipeTransition}) => {
    const style = {
        ...(cropLeft !== undefined && {'--crop-left': `${cropLeft}px`}),
        ...(includeSwipeTransition && {'--transition': SWIPE_TRANSITION}),
    };

    return style;
};

export default useDeviceListItemSwipe;
