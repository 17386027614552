import useInfiniteScroll from 'react-infinite-scroll-hook';

import useInboxMessages from './useInboxMessages';

const useInboxListView = () => {
    const {inboxMessages, loadMore, isLoading, hasNextPage} = useInboxMessages();

    const [sentryRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage,
        onLoadMore: loadMore,
    });

    return {inboxMessages, sentryRef, hasNextPage};
};

export default useInboxListView;
