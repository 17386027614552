import React, {useEffect, useState} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import LanguageSelectContentSelectList from '@/components/LanguageSelect/components/LanguageSelectContent/LanguageSelectContentSelectList';
import LanguageSelectContentTitle from '@/components/LanguageSelect/components/LanguageSelectContent/LanguageSelectContentTitle';
import useLanguageSelect from '@/components/LanguageSelect/hooks/useLanguageSelect';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import appRouterService from '@/services/route/appRouterService';
import helpers from '@/utils/helpers';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

const ProfileLanguageSelectPage = () => {
    const isMobile = useIsMobileLayout();
    const {languageCode, selectListData, handleLanguageChange} = useLanguageSelect();
    const [selectedLanguage, setSelectedLanguage] = useState(languageCode);

    //language select page is absent for mobile design
    useEffect(() => {
        if (isMobile) {
            appRouterService.forwardToConsumerProfilePage();
        }
    }, [isMobile]);

    useEffect(() => {
        setSelectedLanguage(languageCode);
    }, [languageCode]);

    const saveLanguage = () => {
        if (selectedLanguage === languageCode) return;

        helpers.runFunction(handleLanguageChange, selectedLanguage);
    };

    return (
        <>
            <div className='mx-16'>
                <LanguageSelectContentTitle
                    pageName={routePaths.PROFILE_LANGUAGE_SELECT}
                    className='text-32 mt-safe-offset-20'
                />
                <LanguageSelectContentSelectList
                    className='py-8'
                    isDesktopView
                    selectedValue={selectedLanguage}
                    data={selectListData}
                    onChange={setSelectedLanguage}
                />
                <AppButton className='mt-0 w-78' light primary onClick={saveLanguage}>
                    <AemEditableText pageName={routePaths.PROFILE_LANGUAGE_SELECT} itemName='saveButton' />
                </AppButton>
            </div>
            <WarningBanners className='mt-8' source={WarningBanners.SOURCES.LANGUAGE_SELECT} />
        </>
    );
};

export default ProfileLanguageSelectPage;
