import cn from 'classnames';

import {useGetAutoDeliveryDetails} from '@/api/consumerApi';
import PageSection from '@/components/Layout/PageSection';
import SectionLoader from '@/components/Loader/AppLoader/SectionLoader';

import {useAutoDeliverySectionIdContext} from '../../contexts/AutoDeliverySectionIdContext';

const AutoDeliveryWidgetContainer = ({className, children}) => {
    const id = useAutoDeliverySectionIdContext();
    const {isFetching} = useGetAutoDeliveryDetails();

    return (
        <PageSection id={id} className={cn('relative text-start', className)}>
            {children}
            {isFetching && <SectionLoader />}
        </PageSection>
    );
};

const ContentContainer = ({className, children}) => {
    return <section className={cn('mt-6 md:mt-8 md:rounded-xl md:bg-white md:p-8', className)}>{children}</section>;
};

AutoDeliveryWidgetContainer.ContentContainer = ContentContainer;

export default AutoDeliveryWidgetContainer;
