import React from 'react';

import {PopoverClose} from '@/components/Popover/Popover';
import routePaths from '@/consts/route/routePaths';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';

const InboxPopoverMainTitle = () => {
    return (
        <>
            <AemEditableText pageName={routePaths.HOME_PAGE} itemName='notificationsinboxtitle' />
            <PopoverClose />
        </>
    );
};

export default InboxPopoverMainTitle;
