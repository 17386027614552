import React, {forwardRef, useImperativeHandle, useRef} from 'react';

import useHighlightsSlideButtonAnimation from '../hooks/useHighlightsSlideButtonAnimation';

const HighlightsSlideButton = forwardRef(function HighlightsSlideButton({duration, playState, onClick, onEnded}, ref) {
    const progressLineRef = useRef(null);
    const {getProgress, setProgress, resetProgress} = useHighlightsSlideButtonAnimation({
        progressLineRef,
        duration,
        playState,
        onEnded,
    });

    useImperativeHandle(ref, () => ({
        getProgress,
        setProgress,
        resetProgress,
    }));

    return (
        <li className='flex-1' onClick={onClick}>
            <div className='h-1 w-full cursor-pointer overflow-hidden rounded-lg bg-white bg-opacity-50'>
                <div ref={progressLineRef} className='h-full bg-white' />
            </div>
        </li>
    );
});

export default HighlightsSlideButton;
