import {useGetAutoDeliveryDetailsExtended} from '@/api/consumerApi';
import routePaths from '@/consts/route/routePaths';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import AutoDeliveryOnHoldButton from '../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldButton';
import AutoDeliveryOnHoldInfoBox from '../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldInfoBox';
import AutoDeliveryStatusTitle from '../../../../views/AutoDelivery/AutoDeliveryStatusTitle';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryPageDesktopLayout from '../components/AutoDeliveryPageDesktopLayout';

const StatusOnHoldDesktop = ({amplitudeEvent}) => {
    const {
        data: {
            autoDelivery: {statusUpdateReason},
            order: {deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();

    return (
        <AutoDeliveryPageDesktopLayout>
            <AutoDeliveryPageDesktopLayout.Start>
                <AutoDeliveryStatusTitle
                    className='md:text-36'
                    title={<AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='autoDeliveryOnHoldTitle' />}
                />
                <AutoDeliveryOnHoldInfoBox statusUpdateReason={statusUpdateReason} className='mt-8 bg-romance p-8'>
                    <AutoDeliveryOnHoldButton
                        statusUpdateReason={statusUpdateReason}
                        amplitudeEvent={amplitudeEvent}
                        className='mt-8 w-full'
                    />
                </AutoDeliveryOnHoldInfoBox>
                <AutoDeliveryOrder className='mt-8 p-0' products={products} />
                <AutoDeliveryDetailsFooter />
            </AutoDeliveryPageDesktopLayout.Start>
            <AutoDeliveryPageDesktopLayout.End>
                <AutoDeliveryOrderAddress className='p-0' address={deliveryAddress} />
            </AutoDeliveryPageDesktopLayout.End>
        </AutoDeliveryPageDesktopLayout>
    );
};

export default StatusOnHoldDesktop;
