import React from 'react';

import Icon from '../Icon/Icon';

const AutoStartEnabledIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g stroke='#383839' strokeWidth='2' fill='none' fillRule='evenodd'>
                <path
                    d='M21.885 17.875a1.061 1.061 0 0 1 1.05.032L32 23.32c.296.17.47.44.47.727 0 .286-.172.555-.471.728l-9.045 5.334a1.16 1.16 0 0 1-1.09.008.947.947 0 0 1-.367-.334l-.118-11.113c0-.166.046-.319.126-.448a.984.984 0 0 1 .38-.348z'
                    fill='#00D1D2'
                    fillRule='nonzero'
                />
                <g strokeLinecap='round'>
                    <path d='M12.83 31.206A14.548 14.548 0 0 0 25.455 38.5C33.488 38.5 40 32.008 40 24S33.488 9.5 25.455 9.5c-8.034 0-14.546 6.492-14.546 14.5' />
                    <path strokeLinejoin='round' d='m13.818 22.693-2.983 2.757L8 22.55' />
                </g>
            </g>
        </Icon>
    );
};

export default AutoStartEnabledIcon;
