import {createSelector} from 'reselect';

import * as notificationTypes from '@/consts/app/notificationTypes';

const selectNotifications = (state) => state.notifications;

const selectNotificationsInfo = createSelector(selectNotifications, (notifications) =>
    notifications?.filter((x) => x.type === notificationTypes.INFO)
);

const selectNotificationsError = createSelector(selectNotifications, (notifications) =>
    notifications?.filter((x) => x.type === notificationTypes.ERROR)
);

export {selectNotificationsError, selectNotificationsInfo};
