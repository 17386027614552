import cn from 'classnames';
import {useRef} from 'react';

import Banner from '@/components/Banner/Banner';
import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import HighlightsContentTextBottom from './components/HighlightsContentTextBottom';
import HighlightsContentTextTop from './components/HighlightsContentTextTop';

const TYPES = {
    TEXT_BOTTOM: 'textBottom',
    TEXT_TOP: 'textTop',
};

const HighlightsSlide = (props) => {
    const isMobile = useIsMobileLayout();
    const {
        firstButtonAction,
        firstButtonActionKey,
        banner,
        secondButtonAction,
        secondButtonActionKey,
        type,
        loop,
        isFullscreen,
        onClick,
        onEnded,
        onVideoError,
        isActiveSlide,
        isPaused = false,
        isFullscreenGradient,
        onLoadedMetadata,
        videoRef,
        onVideoCanPlay,
        onVideoWaiting,
    } = props;
    const {titleKey, subTitleKey, descriptionKey, imageMedia, videoPath, isGradient, isDarkBannerText} = banner || {};
    const localizedStrings = getLocalizedStrings();
    const ContentComponent =
        type === TYPES.TEXT_TOP && isFullscreen ? HighlightsContentTextTop : HighlightsContentTextBottom;
    const isVideoAvailable = videoPath && (!imageMedia || isFullscreen);
    const bannerRef = useRef(null);
    const videoPlayState = isPaused
        ? VIDEO_PLAY_STATES.PAUSED
        : isActiveSlide
          ? VIDEO_PLAY_STATES.PLAYING
          : VIDEO_PLAY_STATES.STOPPED;

    const handleVideoPlayStateChange = (playState) => {
        if (playState === VIDEO_PLAY_STATES.ENDED) {
            onEnded?.();
        }
    };

    const getGradient = () => {
        const showGradient = isFullscreen ? isFullscreenGradient : isGradient;

        if (!showGradient) {
            return null;
        }

        return isFullscreen && (isMobile || type === TYPES.TEXT_TOP) ? 'top' : 'bottom';
    };

    return (
        <Banner
            ref={bannerRef}
            className={cn(
                'h-full w-full bg-primary text-white',
                isFullscreen ? 'h-screen ml:h-full' : 'h-[540px] overflow-hidden rounded-xl md:h-full'
            )}
            onClick={onClick}
            isControlsVisible={false}
            loop={loop}
            videoRef={videoRef}
            isVideoAvailable={isVideoAvailable}
            autoplay={!isPaused}
            videoPath={videoPath}
            imageMedia={imageMedia}
            videoPlayState={videoPlayState}
            onVideoPlayStateChange={handleVideoPlayStateChange}
            onLoadedMetadata={onLoadedMetadata}
            onVideoError={onVideoError}
            isDarkBannerText={isDarkBannerText}
            onVideoCanPlay={onVideoCanPlay}
            onVideoWaiting={onVideoWaiting}
        >
            <ContentComponent
                isFullscreen={isFullscreen}
                gradient={getGradient()}
                subTitle={localizedStrings[subTitleKey]}
                title={localizedStrings[titleKey]}
                description={localizedStrings[descriptionKey]}
                firstButtonAction={firstButtonAction}
                firstButtonText={localizedStrings[firstButtonActionKey]}
                secondButtonAction={secondButtonAction}
                secondButtonText={localizedStrings[secondButtonActionKey]}
            />
        </Banner>
    );
};

export default HighlightsSlide;
