import analyticsEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';

import amplitude from './amplitude';

const trackAutoDeliveryBannerClick = () => {
    amplitude.trackWithSource(analyticsEventNames.AUTODELIVERY_BANNER_CLICKED);
};

const trackAutoDeliveryDashboardButtonClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(analyticsEventNames.AUTODELIVERY_DASHBOARD_BUTTON_CLICKED, {
        [analyticsEventProperties.TRACKING_VALUE]: trackingValue,
    });
};

const trackAutoDeliveryGetStartedScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.AUTODELIVERY_GET_STARTED_SCREEN_VIEWED);
};

const trackAutoDeliveryGetStartedButtonClick = () => {
    amplitude.trackWithSource(analyticsEventNames.AUTODELIVERY_GET_STARTED_BUTTON_CLICKED);
};

const trackAutoDeliveryOrderScreen = (autoDeliveryStatus, orderSummary = null) => {
    amplitude.trackWithSource(analyticsEventNames.ORDER_SCREEN_VIEWED, {
        [analyticsEventProperties.AUTODELIVERY_STATUS]: autoDeliveryStatus,
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliveryOrderSummaryLinkClick = (autoDeliveryStatus) => {
    amplitude.trackWithSource(analyticsEventNames.ORDER_SUMMARY_LINK_CLICKED, {
        [analyticsEventProperties.AUTODELIVERY_STATUS]: autoDeliveryStatus,
    });
};

const trackAutoDeliveryDeliverAsapStarted = (orderSummary) => {
    amplitude.trackWithSource(analyticsEventNames.DELIVER_ASAP_STARTED, {
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliveryDeliverAsapCompleted = ({success, message, orderSummary}) => {
    amplitude.trackWithSource(analyticsEventNames.DELIVER_ASAP_COMPLETED, {
        [analyticsEventProperties.SUCCESS]: success,
        [analyticsEventProperties.MESSAGE]: message,
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliveryOrderButtonClick = (trackingValue, autoDeliveryStatus) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(analyticsEventNames.ORDER_BUTTON_CLICKED, {
        [analyticsEventProperties.TRACKING_VALUE]: trackingValue,
        [analyticsEventProperties.AUTODELIVERY_STATUS]: autoDeliveryStatus,
    });
};

const trackAutoDeliverySkipDeliveryNotAvailable = (orderSummary) => {
    amplitude.trackWithSource(analyticsEventNames.SKIP_DELIVER_NOT_AVAILABLE, {
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliverySkipDeliveryStarted = (orderSummary) => {
    amplitude.trackWithSource(analyticsEventNames.SKIP_DELIVERY_STARTED, {
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliverySkipDeliveryCompleted = ({success, message, orderSummary}) => {
    amplitude.trackWithSource(analyticsEventNames.SKIP_DELIVERY_COMPLETED, {
        [analyticsEventProperties.SUCCESS]: success,
        [analyticsEventProperties.MESSAGE]: message,
        ...getOrderSummaryProperties(orderSummary),
    });
};

const getOrderSummaryProperties = (orderSummary) => {
    return orderSummary ? {[analyticsEventProperties.ORDER_SUMMARY]: orderSummary} : {};
};

export default {
    trackAutoDeliveryBannerClick,
    trackAutoDeliveryDashboardButtonClick,
    trackAutoDeliveryGetStartedScreen,
    trackAutoDeliveryGetStartedButtonClick,
    trackAutoDeliveryOrderScreen,
    trackAutoDeliveryOrderSummaryLinkClick,
    trackAutoDeliveryDeliverAsapStarted,
    trackAutoDeliveryDeliverAsapCompleted,
    trackAutoDeliveryOrderButtonClick,
    trackAutoDeliverySkipDeliveryNotAvailable,
    trackAutoDeliverySkipDeliveryStarted,
    trackAutoDeliverySkipDeliveryCompleted,
};
