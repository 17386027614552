import analyticsEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';

import amplitude from './amplitude';

const trackShopPageScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.SHOP_SECTION_VIEWED);
};

const trackQuickOrderBannerClick = () => {
    amplitude.trackWithSource(analyticsEventNames.QUICK_ORDER_BANNER_CLICKED);
};

const trackQuickOrderButtonClick = () => {
    amplitude.trackWithSource(analyticsEventNames.QUICK_ORDER_BUTTON_CLICKED);
};

const trackYourDeviceButtonClick = (buttonType, modelName) => {
    if (!buttonType || !modelName) return;

    amplitude.trackWithSource(analyticsEventNames.YOUR_DEVICES_BUTTON_CLICKED, {
        [analyticsEventProperties.BUTTON_TYPE]: buttonType,
        [analyticsEventProperties.IQOS_PRODUCT_MODEL_TYPE]: modelName,
    });
};

const trackShopByProductBannerClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(analyticsEventNames.SHOP_BY_PRODUCT_CLICKED, {
        [analyticsEventProperties.TRACKING_VALUE]: trackingValue,
    });
};

export default {
    trackShopPageScreen,
    trackQuickOrderBannerClick,
    trackQuickOrderButtonClick,
    trackYourDeviceButtonClick,
    trackShopByProductBannerClick,
};
