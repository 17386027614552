import cn from 'classnames';
import React from 'react';

import routePaths from '@/consts/route/routePaths';
import {twx} from '@/utils/tailwindUtils';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import AutoDeliveryOrderSummaryMessage from './AutoDeliveryOrderSummaryMessage';

const AutoDeliveryOrderSummary = ({className, price}) => {
    const {currencySign, delivery, total, subTotal} = price;
    const getPrice = (amount) => {
        if (amount === 0) {
            return <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='freePriceText' />;
        } else {
            return `${currencySign} ${amount}`;
        }
    };
    const isAnyValueNull = delivery === null || total === null || subTotal === null;

    return (
        <div className={twx('px-4 py-8', className)}>
            <h3 className='mb-0 text-24'>
                <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='orderSummaryTitle' />
            </h3>
            {isAnyValueNull ? (
                <AutoDeliveryOrderSummaryMessage />
            ) : (
                <div className='mt-4 flex flex-col gap-2'>
                    <SummaryItem titleKey='subtotalSubTitle' value={getPrice(subTotal)} />
                    <SummaryItem titleKey='deliverySubTitle' value={getPrice(delivery)} />
                    <SummaryItem
                        titleKey='totalSubTitle'
                        value={getPrice(total)}
                        className='text-18 font-bold tracking-02'
                    />
                </div>
            )}
        </div>
    );
};

export default AutoDeliveryOrderSummary;

const SummaryItem = ({titleKey, value, className}) => {
    return (
        <div className={cn('flex justify-between text-black', className)}>
            <span>
                <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName={titleKey} />
            </span>
            <span>{value}</span>
        </div>
    );
};
