import {useSelector} from 'react-redux';

import {selectIsMlLayout} from '@/state/selectors/global';

const useIsMlLayout = () => {
    const isMlLayout = useSelector(selectIsMlLayout);

    return isMlLayout;
};

export default useIsMlLayout;
