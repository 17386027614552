import {useSelector} from 'react-redux';

import {SmartGesturesDisabledIcon, SmartGesturesEnabledIcon} from '@/components/Icons';
import {ON} from '@/consts/uam/onOffTypes';
import {selectDeviceSettings} from '@/state/selectors/device';

const useGestureModeData = () => {
    const {liftUpGesture, doubleTapGesture} = useSelector(selectDeviceSettings) || {};

    if (!liftUpGesture && !doubleTapGesture) return;

    const {gestureState: liftUpGestureState} = liftUpGesture || {};
    const {gestureState: doubleTapGestureState} = doubleTapGesture || {};
    const isDoubleTapEnabled = doubleTapGestureState === ON;
    const isLiftUpEnabled = liftUpGestureState === ON;
    const isEnabled = isDoubleTapEnabled || isLiftUpEnabled;
    const itemName = isEnabled ? 'gestureModeOn' : 'gestureModeOff';
    const icon = isEnabled ? SmartGesturesEnabledIcon : SmartGesturesDisabledIcon;

    return {
        itemName,
        icon,
    };
};

export default useGestureModeData;
