import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {BinIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import {NOTIFICATION_DETAILS_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useMessageDeletionWithUndo from '@/hooks/inbox/useMessageDeletionWithUndo';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import NotificationDetailsMessage from './components/NotificationDetailsMessage';
import NotificationDetailsReadNext from './components/NotificationDetailsReadNext';

const NotificationDetails = ({detailedMessage, nextMessage, onBackButtonClick}) => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();

    const handleMessageDeleteClick = useMessageDeletionWithUndo({messageId: detailedMessage?.messageId});
    const onDeleteClick = () => {
        handleMessageDeleteClick();
        onBackButtonClick();
    };

    return (
        <div className='flex flex-col bg-secondary text-start'>
            {isMobile && (
                <StickyHeader
                    onBack={onBackButtonClick}
                    endIcon={
                        <ButtonRaw onClick={onDeleteClick}>
                            <BinIcon />
                        </ButtonRaw>
                    }
                    title={localizedStrings.formatString(
                        localizedStrings[NOTIFICATION_DETAILS_PAGE_HEADER_TITLE],
                        detailedMessage?.title
                    )}
                />
            )}
            <NotificationDetailsMessage
                className='px-layout pt-safe-offset-sticky-header grow bg-romance pb-2'
                {...detailedMessage}
            />
            {nextMessage && <NotificationDetailsReadNext notificationItem={nextMessage} />}
        </div>
    );
};

export default NotificationDetails;
