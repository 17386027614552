import {useSelector} from 'react-redux';

import {VibrateDisabledBlueIcon, VibrateEnabledBlueIcon} from '@/components/Icons';
import {ENABLED} from '@/consts/uam/hapticProfileTypes';
import {ON} from '@/consts/uam/onOffTypes';
import {selectDeviceSettings} from '@/state/selectors/device';

const useVibrationData = () => {
    const {
        extHapticProfile,
        uiBatteryChargedVibration,
        batteryChargedVibration,
        uiHeatingVibration, // (P1V4 DELTA only)
        heatingVibration,
    } = useSelector(selectDeviceSettings) || {};

    const isVibrationAvailable =
        extHapticProfile ||
        uiBatteryChargedVibration ||
        batteryChargedVibration ||
        uiHeatingVibration ||
        heatingVibration;

    if (!isVibrationAvailable) return;

    const {durations} = extHapticProfile || {};
    const extHapticProfileIsOn = durations ? durations[0]?.durationIsOn : undefined;

    const {batteryChargedOneExp, batteryChargedTwoExp} = uiBatteryChargedVibration || {};
    const uiBatteryChargedVibrationIsOn = batteryChargedOneExp === ON && batteryChargedTwoExp === ON;

    const {batteryCharged} = batteryChargedVibration || {};
    const batteryChargedVibrationisOn = !!batteryCharged;

    const {
        preHeatVibration,
        heatingVibration: uiHeatVibration,
        nearEndHeatVibration,
        heatingEndVibration,
    } = uiHeatingVibration || {};
    const uiHeatingVibrationIsOn =
        convertIntToBoolSetting(preHeatVibration) ||
        convertIntToBoolSetting(uiHeatVibration) ||
        convertIntToBoolSetting(nearEndHeatVibration) ||
        convertIntToBoolSetting(heatingEndVibration);

    const {endOfHeating, fullyHeated, nearEnd, startHeating, hapticProfile} = heatingVibration || {};
    const heatingVibrationisOn = endOfHeating || fullyHeated || nearEnd || startHeating || hapticProfile === ENABLED;

    const isEnabled =
        extHapticProfileIsOn ||
        uiBatteryChargedVibrationIsOn ||
        batteryChargedVibrationisOn ||
        uiHeatingVibrationIsOn ||
        heatingVibrationisOn;

    const itemName = isEnabled ? 'vibrationsOn' : 'vibrationsOff';
    const icon = isEnabled ? VibrateEnabledBlueIcon : VibrateDisabledBlueIcon;

    return {
        itemName,
        icon,
    };
};

const convertIntToBoolSetting = (value) => {
    if (!value) return false;

    return value > 0;
};

export default useVibrationData;
