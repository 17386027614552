import {useState} from 'react';

import useHighlightsCarouselControls from '../../common/hooks/useHighlightsCarouselControls';
import useHighlightsDuplicateGroups from './useHighlightsDuplicateGroups';

const useHighlightsInfiniteCarousels = ({
    sliderRef,
    carouselsFromConfig,
    visibleCount,
    onCarouselChange,
    onSlideChange,
}) => {
    // infinite mode requires at least double of slides than is being shown, so they need to be duplicated
    const carousels = duplicateIfTooFew(carouselsFromConfig, {minCount: visibleCount * 2});
    const carouselControls = useHighlightsCarouselControls();
    const {getGroupIndex, getIsInGroup, syncWithGroup, fixGroupProgressDesync, resetGroupSlideProgress} =
        useHighlightsDuplicateGroups({
            carousels,
            carouselControls,
            initialCount: carouselsFromConfig.length,
        });
    const [carouselStates, setCarouselStates] = useState(
        generateCarouselStates(carousels, {getGroupIndex, getIsInGroup})
    );

    const {setCarouselRef, playCarousel, pauseCarousel, endCarousel, restartCarousel, changeCarouselSlide} =
        carouselControls;

    const handleCarouselLeave = (i) => {
        // setState should be called here with a function to get the actual
        // state due to the specifics on how swiper library works
        setCarouselStates((states) => {
            let newStates = pauseCarousel(states, i);

            newStates = syncWithGroup(newStates, i);
            fixGroupProgressDesync(i, newStates[i].currentSlide);

            return newStates;
        });
    };

    const handleCarouselEnter = (i) => {
        // same as with handleCarouselLeave
        setCarouselStates((states) => {
            const currentState = states[i];
            let newStates = currentState.isEnded ? restartCarousel(states, i) : playCarousel(states, i);

            newStates = syncWithGroup(newStates, i);
            onCarouselChange?.(getGroupIndex(i), currentState.currentSlide);

            return newStates;
        });
    };

    const handleCarouselEnded = (i) => {
        if (i !== sliderRef.current?.swiper.realIndex) return;

        // same as with handleCarouselLeave
        setCarouselStates((states) => {
            let newStates = endCarousel(states, i);

            newStates = syncWithGroup(newStates, i);

            return newStates;
        });

        sliderRef.current?.swiper.slideNext();
    };

    const handleSlideChange = (i, newSlide) => {
        if (i !== sliderRef.current?.swiper.realIndex) return;

        // same as with handleCarouselLeave
        setCarouselStates((states) => {
            const currentState = states[i];

            // newSlide === currentSlide when user clicks on same slide button
            if (newSlide === currentState.currentSlide) {
                resetGroupSlideProgress(i, currentState.currentSlide);
                return states;
            } else {
                let newStates = changeCarouselSlide(states, i, {newSlide});

                newStates = syncWithGroup(newStates, i);
                onSlideChange?.(getGroupIndex(i), newSlide);

                return newStates;
            }
        });
    };

    return {
        carousels,
        carouselStates,
        setCarouselRef,
        handleCarouselLeave,
        handleCarouselEnter,
        handleCarouselEnded,
        handleSlideChange,
    };
};

const duplicateIfTooFew = (carousels, {minCount}) => {
    const duplicates = [...carousels];

    while (duplicates.length < minCount) {
        duplicates.push(...carousels);
    }

    return duplicates;
};

const generateCarouselStates = (carousels, {getGroupIndex, getIsInGroup}) => {
    const carouselStates = carousels.map(({highlights}, i) => ({
        group: getGroupIndex(i),
        slideCount: highlights.length,
        currentSlide: 0,
        isPlaying: getIsInGroup(i, 0),
        isEnded: false,
    }));

    return carouselStates;
};

export default useHighlightsInfiniteCarousels;
