import LayoutTypes from '../consts/layout/layoutTypes';

const isXsLayout = (layout) => layout === LayoutTypes.XS;
const isSmLayout = (layout) => layout === LayoutTypes.SM;
const isMdLayout = (layout) => layout === LayoutTypes.MD;
const isMlLayout = (layout) => layout === LayoutTypes.ML;
const isLgLayout = (layout) => layout === LayoutTypes.LG;

const isMobileLayout = (layout) => isXsLayout(layout) || isSmLayout(layout) || isMdLayout(layout);
const isTabletAndDesktopLayout = (layout) => isMdLayout(layout) || isDesktopLayout(layout);
const isDesktopLayout = (layout) => isMlLayout(layout) || isLgLayout(layout);

export default {
    isXsLayout,
    isSmLayout,
    isMdLayout,
    isMlLayout,
    isLgLayout,
    isDesktopLayout,
    isMobileLayout,
    isTabletAndDesktopLayout,
};
