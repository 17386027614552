import routePaths from '@/consts/route/routePaths';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../components/Button';
import {ArrowLeftIcon, CalendarIcon} from '../../../../components/Icons';
import PopupResponsive from '../../../../components/Popup/PopupResponsive';
import PopupStickedWithSwipeClose from '../../../../components/Popup/PopupStickedWithSwipeClose';
import useIsMobileLayout from '../../../../hooks/useIsMobileLayout';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import useDeliveryDate from '../hooks/useDeliveryDate';
import DeliveryAsapInfo from './DeliveryAsapInfo';

const DeliveryAsapConfirmPopup = ({onClose, onConfirm}) => {
    const isMobile = useIsMobileLayout();
    const {startDeliveryDay, asapDeliveryDay} = useDeliveryDate();
    const Popup = isMobile ? PopupStickedWithSwipeClose : PopupResponsive;

    return (
        <Popup
            className='md:ml-[calc(var(--sidebar-width)/2)] md:w-full md:max-w-[494px] md:rounded-xl md:p-8 md:pt-19'
            onClose={onClose}
        >
            <div className='text-start'>
                <h2 className='mb-0 text-28'>
                    <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliveryAsapConfirmPopupTitle' />
                </h2>
                <DeliveryAsapInfo className='mt-8' />
                <div className='mt-8 flex items-center justify-between'>
                    <div className='flex items-center gap-2'>
                        <CalendarIcon className='text-french-gray' />
                        <p>
                            <AemEditableText
                                pageName={routePaths.AUTO_DELIVERY}
                                itemName='deliveryAsapConfirmPopupStartDate'
                                contentList={[startDeliveryDay]}
                            />
                        </p>
                    </div>
                    <ArrowLeftIcon className='mx-2 rotate-180' />
                    <div className='flex items-center gap-2'>
                        <CalendarIcon className='text-tertiary' />
                        <p>
                            <AemEditableText
                                pageName={routePaths.AUTO_DELIVERY}
                                itemName='deliveryAsapConfirmPopupEndDate'
                                contentList={[asapDeliveryDay]}
                            />
                        </p>
                    </div>
                </div>
                <AutoDeliveryDetailsFooter className='mt-8 md:border-none md:pt-0'>
                    <AppButton primary light className='mt-0' onClick={onConfirm}>
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='deliveryAsapConfirmPopupButton'
                        />
                    </AppButton>
                </AutoDeliveryDetailsFooter>
            </div>
        </Popup>
    );
};

export default DeliveryAsapConfirmPopup;
