import React from 'react';

import Icon from '../Icon/Icon';

const SocialChannelYoutubeIcon = (props) => {
    return (
        <Icon width='63' height='44' fill='none' {...props}>
            <g clipPath='url(#clip0_3955_10036)'>
                <path
                    d='M40.05 17.0847C39.8434 16.2637 39.237 15.6185 38.4654 15.3987C37.0667 15 31.4616 15 31.4616 15C31.4616 15 25.8566 15 24.4593 15.3987C23.6877 15.6185 23.0813 16.2637 22.8748 17.0847C22.5 18.5712 22.5 21.6747 22.5 21.6747C22.5 21.6747 22.5 24.7781 22.8748 26.2647C23.0813 27.0856 23.6877 27.7308 24.4593 27.9506C25.8566 28.3493 31.4616 28.3493 31.4616 28.3493C31.4616 28.3493 37.0667 28.3493 38.4639 27.9506C39.2356 27.7308 39.842 27.0856 40.0485 26.2647C40.4233 24.7781 40.4233 21.6747 40.4233 21.6747C40.4233 21.6747 40.4233 18.5712 40.0485 17.0847H40.05Z'
                    fill='white'
                />
                <path d='M29.669 24.5347V18.8145L34.3269 21.6746L29.669 24.5347Z' fill='currentColor' />
            </g>
            <defs>
                <clipPath id='clip0_3955_10036'>
                    <rect width='18' height='13.5' fill='white' transform='translate(22.5 15)' />
                </clipPath>
            </defs>
        </Icon>
    );
};

export default SocialChannelYoutubeIcon;
