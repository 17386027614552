import {useState} from 'react';

import {getLocalizedStrings} from '@/services/localization/localizationService';

const useHighlightsHeaderText = (carousels) => {
    const [currentCarouselInfo, setCurrentCarouselInfo] = useState({carousel: 0, slide: 0});
    const localizedStrings = getLocalizedStrings();

    const {carousel, slide} = currentCarouselInfo;
    const subtitleKey = carousels[carousel]?.highlights[slide]?.banner?.subTitleKey;
    const headerText = subtitleKey && localizedStrings[subtitleKey];

    const handleSlideChange = (carousel, slide) => {
        // setTimeout is used to avoid error "Cannot update a component while rendering a different component"
        setTimeout(() => setCurrentCarouselInfo({carousel, slide}), 0);
    };

    return {headerText, handleSlideChange};
};

export default useHighlightsHeaderText;
