import React from 'react';

import {MailIcon} from '@/components/Icons';

import ContactUsSection from './ContactUsSection';

const ContactUsSendQuestion = ({email}) => {
    if (!email) return null;

    return (
        <ContactUsSection>
            <ContactUsSection.Icon>
                <MailIcon />
            </ContactUsSection.Icon>
            <ContactUsSection.Title itemName='sendQuestionTitle' />
            <ContactUsSection.Description itemName='sendQuestionDesc' />
            <ContactUsSection.Button
                itemName='sendQuestionButton'
                to={`mailto:${email}`}
                secondary
                light
                hasThickerShadow
                isExternalLink
            />
        </ContactUsSection>
    );
};

export default ContactUsSendQuestion;
