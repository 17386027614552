import appConfig from '@/config/appConfig';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import deviceTypesService from '@/services/device/deviceTypesService';
import marketService from '@/services/marketService';
import userBrowserService from '@/services/user/userBrowserService';
import {setIsGaAnonymizationEnabled} from '@/state/selectors/consumerAggregatedSettings';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import {getState} from '@/state/store';
import cryptoUtils from '@/utils/cryptoUtils';

export const toggleAnalytics = (isEnabled) => {
    const googleTagManagerId = appConfig.getGoogleTagManagerId();

    window[`ga-disable-${googleTagManagerId}`] = !isEnabled;
};

const getProperties = () => {
    const createProperty = (key, value) => ({[key]: value});

    const getInteractionType = (interactionType) =>
        createProperty(analyticsEventProperties.INTERACTION_TYPE, interactionType);

    const getSource = () => {
        const source = userBrowserService.isInStandaloneMode()
            ? analyticsEventValues.PWA
            : analyticsEventValues.BROWSER;

        return createProperty(analyticsEventProperties.SOURCE, source);
    };

    const getMarket = () => {
        const market = marketService.getMarketCode()?.toUpperCase();

        return createProperty(analyticsEventProperties.MARKET, market);
    };

    const getTrackingValue = (trackingValue) => createProperty(analyticsEventProperties.TRACKING_VALUE, trackingValue);

    const getSuccess = (isSuccess) => createProperty(analyticsEventProperties.SUCCESS, isSuccess);

    const getDeviceSerialNumber = (deviceSerialNumber = undefined) => {
        const shouldTakeFromCurrentDevice = !deviceSerialNumber;
        const productInfo = selectCurrentProductInfo(getState());
        const deviceSerialNumberValue = shouldTakeFromCurrentDevice
            ? productInfo.deviceSerialNumber
            : deviceSerialNumber;
        const anonymizedDeviceSerialNumber = anonymize(deviceSerialNumberValue);

        return createProperty(analyticsEventProperties.IQOS_PRODUCT_DEVICE_ID, anonymizedDeviceSerialNumber);
    };

    const getDeviceModel = (deviceType = undefined) => {
        const shouldTakeFromCurrentDevice = !deviceType;
        const productInfo = selectCurrentProductInfo(getState());
        const deviceTypeValue = shouldTakeFromCurrentDevice ? productInfo.type : deviceType;
        const deviceData = deviceTypesService.getDeviceTypesDataByType(deviceTypeValue);
        const deviceModel = deviceData?.modelName;

        return createProperty(analyticsEventProperties.IQOS_PRODUCT_MODEL_TYPE, deviceModel);
    };

    const getPushSource = (pushSource) => createProperty(analyticsEventProperties.PUSH_SOURCE, pushSource);

    const getCampaignName = (campaignName) => createProperty(analyticsEventProperties.CAMPAIGN_NAME, campaignName);

    const getLegal = (legalName) => createProperty(analyticsEventProperties.LEGAL, legalName);

    return {
        interactionType: getInteractionType,
        source: getSource,
        market: getMarket,
        trackingValue: getTrackingValue,
        success: getSuccess,
        deviceSerialNumber: getDeviceSerialNumber,
        deviceModel: getDeviceModel,
        pushSource: getPushSource,
        campaignName: getCampaignName,
        legal: getLegal,
    };
};

export const properties = getProperties();

export const track = ({event: eventName, properties: eventPropertiesArray}) => {
    if (!eventName) return;

    const properties = convertToObj(eventPropertiesArray);
    const event = {
        [analyticsEventProperties.EVENT]: eventName,
        ...properties,
    };

    window.dataLayer?.push(event);
};

const convertToObj = (arrayOfObjects) => {
    const result = {};

    for (const obj of arrayOfObjects) {
        for (const [key, value] of Object.entries(obj)) {
            result[key] = value;
        }
    }

    return result;
};

const anonymize = (value) => {
    const isAnonymizationEnabled = setIsGaAnonymizationEnabled(getState());

    return isAnonymizationEnabled ? cryptoUtils.hashMD5(value) : value;
};
