import React from 'react';
import {useSelector} from 'react-redux';

import {ContactUsIcon, GlobeIcon, PreferenceCenterIcon} from '@/components/Icons';
import {SidebarPageLayout} from '@/components/Layout';
import {TERMS_OF_USE} from '@/consts/tnc/tncTypes';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {selectIsAllMandatoryTermsApproved} from '@/state/selectors/consumerAggregatedSettings';
import {twx} from '@/utils/tailwindUtils';

import ConsumerProfileHeader from './components/ConsumerProfileHeader';
import ConsumerProfileLogoutButton from './components/ConsumerProfileLogoutButton';
import {PROFILE_SIDEBAR_MENU_ITEMS} from './consts/profileSidebarMenu';
import useProfilePageSidebar from './hooks/useProfilePageSidebar';

const ProfilePageContainer = ({
    className,
    children,
    activeMenuItem,
    isTncLayout,
    onPreferenceCenterClick,
    onTermsOfUseClick,
}) => {
    const isMobile = useIsMobileLayout();
    const {
        userName,
        handlePreferenceCenterClick,
        handleLanguageSelectClick,
        handleContactUsClick,
        handleTermsOfUseClick,
        handlePrivacyNoticeClick,
        handleAttributionNoticeClick,
    } = useProfilePageSidebar({onPreferenceCenterClick, onTermsOfUseClick});
    const isAllMandatoryTermsApproved = useSelector(selectIsAllMandatoryTermsApproved);

    if (isMobile) {
        return <MobileContainer className={className}>{children}</MobileContainer>;
    }

    const prefCenter = {
        icon: <PreferenceCenterIcon className='shrink-0' />,
        itemName: 'preferenceCentreButton',
        onClick: handlePreferenceCenterClick,
        itemKey: PROFILE_SIDEBAR_MENU_ITEMS.PREFERENCE_CENTER,
        disabled: !isAllMandatoryTermsApproved,
    };
    const accountSettingsItems = isTncLayout
        ? [prefCenter]
        : [
              prefCenter,
              {
                  icon: <GlobeIcon className='shrink-0' />,
                  itemName: 'languageButton',
                  onClick: handleLanguageSelectClick,
                  itemKey: PROFILE_SIDEBAR_MENU_ITEMS.LANGUAGE_SELECT,
              },
          ];

    return (
        <SidebarPageLayout>
            <SidebarPageLayout.Sidebar hasBackButton={isAllMandatoryTermsApproved}>
                {!isTncLayout && <ConsumerProfileHeader userName={userName} />}
                <SidebarPageLayout.SidebarMenuSection
                    headerItemName='accountSettingsHeader'
                    items={accountSettingsItems}
                    activeItemKey={activeMenuItem}
                />
                {!isTncLayout && (
                    <SidebarPageLayout.SidebarMenuSection
                        headerItemName='supportHeader'
                        items={[
                            {
                                icon: <ContactUsIcon className='shrink-0' />,
                                itemName: 'contactUsButton',
                                onClick: handleContactUsClick,
                                itemKey: PROFILE_SIDEBAR_MENU_ITEMS.CONTACT_US,
                            },
                        ]}
                        activeItemKey={activeMenuItem}
                    />
                )}
                <SidebarPageLayout.SidebarMenuSection
                    headerItemName='legalHeader'
                    items={[
                        {
                            itemName: 'termsOfUseButton',
                            onClick: handleTermsOfUseClick,
                            itemKey: TERMS_OF_USE,
                        },
                        {
                            itemName: 'privacyNoticeButton',
                            onClick: handlePrivacyNoticeClick,
                        },
                        {
                            itemName: 'attributionNoticeButton',
                            onClick: handleAttributionNoticeClick,
                        },
                    ]}
                    activeItemKey={activeMenuItem}
                    hasShorterGapBetweenItems
                />
                {!isTncLayout && (
                    <LogoutButtonContainer>
                        <ConsumerProfileLogoutButton className='tracking-05' />
                    </LogoutButtonContainer>
                )}
            </SidebarPageLayout.Sidebar>
            <SidebarPageLayout.Content className={className}>{children}</SidebarPageLayout.Content>
        </SidebarPageLayout>
    );
};

const MobileContainer = ({children, className}) => {
    return <div className={twx('w-full', className)}>{children}</div>;
};

const LogoutButtonContainer = ({children}) => {
    return <div className='mt-8 px-4'>{children}</div>;
};

export default ProfilePageContainer;
