import cn from 'classnames';
import React from 'react';

const HighlightsSlideButtonContainer = ({children, isFullscreen, ...props}) => {
    return (
        <div {...props}>
            <div
                className={cn(
                    'absolute left-0 right-0 z-[2]',
                    isFullscreen ? 'top-safe-offset-sticky-header' : 'top-10 md:top-[50px]'
                )}
            >
                <ul className='flex gap-2 px-4 md:px-6'>{children}</ul>
            </div>
        </div>
    );
};

export default HighlightsSlideButtonContainer;
