import React from 'react';
import {useSelector} from 'react-redux';

import {MODES} from '@/components/DeviceIcon/DeviceIcon';
import {MyDevicesIcon, RenameBlueIcon} from '@/components/Icons';
import advanceDiagnosticService from '@/containers/AdvancedDiagnosticPage/advanceDiagnosticService';
import DeviceSettings from '@/containers/DevicesPage/components/MyDevicesView/components/DeviceSettings/DeviceSettings';
import useOnRenameClick from '@/containers/DevicesPage/components/MyDevicesView/hooks/useOnRenameClick';
import useLayout from '@/hooks/inbox/useLayout';
import amplitudeDevicesPageTracking from '@/services/analytics/amplitude/amplitudeDevicesPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appRouterService from '@/services/route/appRouterService';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import DevicesSettingsSection from '../../DevicesSettingsSection/DevicesSettingsSection';
import MyDevicesConnectButton from './MyDevicesConnectButton';

const MyDevicesManageDevice = () => {
    const {isMobile, isMl} = useLayout();
    const device = useSelector(selectCurrentProductInfo);
    const onRenameClick = useOnRenameClick();

    const {type, deviceSerialNumber} = device || {};
    const isDiagnosticEnabled = advanceDiagnosticService.isEnabled(type);

    const onAboutClick = () => {
        appRouterService.forwardToAboutDevicePage();
    };

    const onAdClick = () => {
        appRouterService.forwardToADPage();
    };

    const onTipsAndTutorialsClick = () => {
        amplitudeDevicesPageTracking.trackTipsAndTutorialsScreen();
        googleAnalyticsService.trackTipsAndTutorialsScreenView(type, deviceSerialNumber);
        appRouterService.forwardToTipsAndTutorialsList();
    };

    return (
        <>
            {(isMobile || isMl) && (
                <DevicesSettingsSection.Group>
                    <DevicesSettingsSection.Buttons>
                        <MyDevicesConnectButton device={device} />
                    </DevicesSettingsSection.Buttons>
                </DevicesSettingsSection.Group>
            )}
            <DeviceSettings />
            <DevicesSettingsSection.Group className='mt-4 ml:mt-0'>
                <DevicesSettingsSection.Title itemName='manageYourIqos' />
                <DevicesSettingsSection.Buttons>
                    {isMobile && (
                        <DevicesSettingsSection.Button
                            itemName='renamemydevice'
                            icon={RenameBlueIcon}
                            onClick={onRenameClick}
                            hasSeparator
                        />
                    )}
                    <DevicesSettingsSection.Button
                        itemName='aboutmydevice'
                        mode={MODES.ABOUT}
                        onClick={onAboutClick}
                        hasSeparator
                    />
                    {isDiagnosticEnabled && (
                        <DevicesSettingsSection.Button
                            itemName='diagnoseissue'
                            mode={MODES.DIAGNOSTICS}
                            onClick={onAdClick}
                            hasSeparator
                        />
                    )}
                    <DevicesSettingsSection.Button
                        itemName='tipsandtutorials'
                        icon={MyDevicesIcon}
                        onClick={onTipsAndTutorialsClick}
                    />
                </DevicesSettingsSection.Buttons>
            </DevicesSettingsSection.Group>
            <WarningBanners source={WarningBanners.SOURCES.DEVICES} />
        </>
    );
};

export default MyDevicesManageDevice;
