import iccService from '@/services/icc/iccService';
import httpStatusTypesService from '@/services/server/httpStatusTypesService';
import {showMaintenancePopup} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';

const iccResponseErrorCheckService = async ({isAnonymous, status, error, skipError}) => {
    const isServerUnavailable = httpStatusTypesService.isServiceUnavailable(status);
    const isAuthError = httpStatusTypesService.isNotAuthorized(status);

    if (isServerUnavailable) {
        dispatch(showMaintenancePopup());
    }

    if (isAuthError) {
        await iccService.refreshIccToken(isAnonymous);
    } else {
        if (skipError) return;
        throw error;
    }
};

export default iccResponseErrorCheckService;
