import React from 'react';

import RemoteImage from '@/components/Image/RemoteImage';

import appConfig from '../../config/appConfig';
import urlUtils from '../../utils/urlUtils';
import AppImage from '../Image/AppImage';

const RemoteIcon = ({imageMedia, imagePath, className}) => {
    if (!imagePath && !imageMedia) return null;

    if (imageMedia) {
        return <RemoteImage className={className} imageMedia={imageMedia} />;
    }

    const imageSrc = urlUtils.join(appConfig.getContentPath(), imagePath);

    return <AppImage alt={imageSrc} className={className} src={imageSrc} />;
};

export default RemoteIcon;
