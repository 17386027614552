import cn from 'classnames';

import PageSection from '@/components/Layout/PageSection';

import {useHighlightsSectionId} from '../../../contexts/HighlightsSectionIdContext';

const HighlightsContainer = ({className, children}) => {
    const sectionId = useHighlightsSectionId();

    return (
        <PageSection id={sectionId} className={cn('flex flex-col gap-4 md:gap-6 lg:gap-8', className)}>
            {children}
        </PageSection>
    );
};

export default HighlightsContainer;
