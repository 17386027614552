import {twx} from '@/utils/tailwindUtils';

import useHighlightsIsTextLarger from '../hooks/useHighlightsIsTextLarger';
import HighlightsContentContainer from './HighlightsContentContainer';

const HighlightsContentTextBottom = ({isFullscreen, gradient, title, subTitle, description}) => {
    const isTextLarger = useHighlightsIsTextLarger(isFullscreen);

    return (
        <HighlightsContentContainer isFullscreen={isFullscreen} gradient={gradient}>
            <h3
                className={twx(
                    'mb-auto line-clamp-1 text-14 leading-[10px] md:leading-none',
                    isFullscreen && 'pr-12 md:pr-0',
                    isTextLarger && 'text-24 leading-[18px]'
                )}
            >
                {subTitle}
            </h3>
            <h2 className={twx('mb-0 text-24', !isFullscreen && 'line-clamp-2')}>{title}</h2>
            <p className={twx('md:mt-2 md:leading-[20px]', !isFullscreen && 'line-clamp-3')}>{description}</p>
        </HighlightsContentContainer>
    );
};

export default HighlightsContentTextBottom;
