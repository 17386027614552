import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';
import React from 'react';

import {DeviceUnregisterIcon, TickCircle} from '@/components/Icons';
import appConfig from '@/config/appConfig';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import DeviceImage from '@/containers/DeviceImage/DeviceImage';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import productHelpers from '@/services/product/productHelpers';
import {twx} from '@/utils/tailwindUtils';

import styles from './DevicesListItem.module.scss';
import useDeviceListItemSwipe from './useDeviceListItemSwipe';

const DeviceListItem = ({device, isSelected, touchedDeviceId, onDeviceTouch, onUnregisterClick}) => {
    const isUnregistrationEnabled = appConfig.getIsUnregistrationEnabled();
    const {codentify} = device || {};
    const name = productHelpers.getProductName(device);
    const {animationStyle, onTouchStart, onTouchMove, onTouchEnd, onTransitionEnd} = useDeviceListItemSwipe({
        deviceId: codentify,
        touchedDeviceId,
        onDeviceTouch,
        isEnabled: isUnregistrationEnabled,
    });

    return (
        <div className={twx('relative flex w-full cursor-pointer', styles.DeviceButton)}>
            <RadioGroup.Item
                className='max-w-full flex-1 px-4 ml:p-0 ml:px-0 lg:hover:cursor-pointer'
                value={codentify}
                id={codentify}
            >
                <div
                    className='flex items-center gap-2 overflow-x-auto py-4 text-start text-primary'
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <DeviceImage
                        className='h-16 w-16'
                        device={device}
                        holder={device?.holder}
                        imgClassName='absolute'
                        ignoreRestrictedMarket
                        showHolder
                    />
                    <h4 className={cn('mb-0 flex-1 truncate text-16', !isSelected && 'ml:pr-9')}>{name}</h4>
                    {isSelected && <TickCircle className='shrink-0' />}
                </div>
            </RadioGroup.Item>
            {isUnregistrationEnabled && (
                <div className={styles.DeleteContainer} style={animationStyle} onTransitionEnd={onTransitionEnd}>
                    <UnregisterDeviceButton onClick={onUnregisterClick} />
                </div>
            )}
        </div>
    );
};

const UnregisterDeviceButton = ({onClick}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div
            onClick={onClick}
            className='flex h-full w-[88px] flex-col items-center justify-center text-center text-12 tracking-02'
        >
            <DeviceUnregisterIcon className='h-6 w-6' />
            <span className='max-w-full truncate px-1'>
                {localizedStrings[localizationKeys.DEVICE_UNREGISTER_BUTTON_TEXT]}
            </span>
        </div>
    );
};

export default DeviceListItem;
