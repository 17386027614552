import React from 'react';

import Icon from '../Icon/Icon';

const BigBlueSunIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M24 31.391a7.391 7.391 0 1 0 0-14.782 7.391 7.391 0 0 0 0 14.782z'
                    stroke='#272A33'
                    strokeWidth='2'
                />
                <path
                    d='M24 26.957a2.957 2.957 0 1 0 0-5.914 2.957 2.957 0 0 0 0 5.914z'
                    fill='#00D1D2'
                    fillRule='nonzero'
                />
                <path
                    d='M24 29a5 5 0 1 0 0-10 5 5 0 0 0 0 10z'
                    fill='#00D1D2'
                    fillRule='nonzero'
                    className='icon-accent-stroke icon-accent-fill'
                />
                <path
                    stroke='#272A33'
                    strokeWidth='2'
                    strokeLinecap='round'
                    d='M24 34.7V41M24 13.3V7M34.7 24H41M7 24h6.3M31.566 31.566l4.455 4.455M16.434 16.434l-4.455-4.455M31.566 16.434l4.455-4.455M11.979 36.021l4.455-4.455'
                />
            </g>
        </Icon>
    );
};

export default BigBlueSunIcon;
