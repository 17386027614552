import {useGetAutoDeliveryDetailsExtended} from '@/api/consumerApi';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';

const useOrderSummaryTracking = () => {
    const {
        data: {
            order: {price},
        },
    } = useGetAutoDeliveryDetailsExtended();

    if (!price) {
        return null;
    }

    const {delivery, total, subTotal} = price || {};
    const orderSummary =
        delivery !== null && total !== null && subTotal !== null
            ? analyticsEventValues.PRICES_INFO
            : analyticsEventValues.LINK;

    return orderSummary;
};

export default useOrderSummaryTracking;
