import React from 'react';

import Icon from '../Icon/Icon';

const IlumaAboutBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props} fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.25 41.25C13.549 41.25 12.799 40.0982 12 37.7947V12.0345C12 10.9593 12.4475 9.94784 13.3425 9H18.1575C19.0525 9.94784 19.5 10.9593 19.5 12.0345V37.7947C18.701 40.0982 17.951 41.25 17.25 41.25H14.25Z'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
            />
            <path
                d='M15.8149 25.0361C16.5848 25.0361 17.2089 24.412 17.2089 23.6421C17.2089 22.8722 16.5848 22.248 15.8149 22.248C15.045 22.248 14.4209 22.8722 14.4209 23.6421C14.4209 24.412 15.045 25.0361 15.8149 25.0361Z'
                fill='#34303D'
                stroke='#00D1D2'
                strokeWidth='1.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M29.25 6.9375C30.8551 6.9375 32.3082 7.58809 33.3601 8.63994C34.4119 9.6918 35.0625 11.1449 35.0625 12.75V39.75C35.0625 40.1124 34.9156 40.4406 34.6781 40.6781C34.4406 40.9156 34.1124 41.0625 33.75 41.0625H24.75C24.3876 41.0625 24.0594 40.9156 23.8219 40.6781C23.5844 40.4406 23.4375 40.1124 23.4375 39.75V8.25C23.4375 7.88756 23.5844 7.55944 23.8219 7.32192C24.0594 7.08441 24.3876 6.9375 24.75 6.9375H29.25Z'
                stroke='#34303D'
                strokeWidth='2'
            />
            <path
                d='M35.25 31.5C39.3921 31.5 42.75 28.1421 42.75 24C42.75 19.8579 39.3921 16.5 35.25 16.5C31.1079 16.5 27.75 19.8579 27.75 24C27.75 28.1421 31.1079 31.5 35.25 31.5Z'
                fill='#FFFDFB'
                stroke='#34303D'
                strokeWidth='2'
            />
            <path
                d='M35.25 29.25C38.1495 29.25 40.5 26.8995 40.5 24C40.5 21.1005 38.1495 18.75 35.25 18.75C32.3505 18.75 30 21.1005 30 24C30 26.8995 32.3505 29.25 35.25 29.25Z'
                fill='#00D1D2'
            />
            <path
                d='M35.25 21.9141C35.6642 21.9141 36 21.5783 36 21.1641C36 20.7498 35.6642 20.4141 35.25 20.4141C34.8358 20.4141 34.5 20.7498 34.5 21.1641C34.5 21.5783 34.8358 21.9141 35.25 21.9141Z'
                fill='#34303D'
            />
            <path
                d='M36 23.4141C36 22.9998 35.6642 22.6641 35.25 22.6641C34.8358 22.6641 34.5 22.9998 34.5 23.4141V26.4141C34.5 26.8283 34.8358 27.1641 35.25 27.1641C35.6642 27.1641 36 26.8283 36 26.4141V23.4141Z'
                fill='#34303D'
            />
        </Icon>
    );
};

export default IlumaAboutBlueIcon;
