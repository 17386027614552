import cn from 'classnames';
import React, {useState} from 'react';

import RemoteImage from '@/components/Image/RemoteImage';
import helpers from '@/utils/helpers';
import {twx} from '@/utils/tailwindUtils';

import GuideVideo from '../Video/GuideVideo/GuideVideo';

const PageHeaderMedia = ({
    isVideoAvailable,
    videoThumbnailSrc,
    videoSrc,
    imageMedia,
    className,
    videoRef,
    videoClassName,
    videoPlayState,
    onVideoPlayStateChange,
    onVideoError,
    imgClassName,
    onVideoCanPlay,
    onVideoWaiting,
    ...rest
}) => {
    const [isVideoError, setIsVideoError] = useState(false);

    const handleVideoError = () => {
        setIsVideoError(true);
        helpers.runFunction(onVideoError);
    };

    return (
        <div className={twx('relative min-h-[256px] w-full', className)}>
            {isVideoAvailable && !isVideoError ? (
                <GuideVideo
                    ref={videoRef}
                    src={videoSrc}
                    thumbnailSrc={videoThumbnailSrc}
                    containerClassName='absolute top-0 left-0 w-full h-full'
                    className={cn('h-full w-full object-cover', videoClassName)}
                    controlsClassName='m-0 right-4 left-[unset] z-20 rtl:left-4 rtl:right-[unset]'
                    playState={videoPlayState}
                    onPlayStateChange={onVideoPlayStateChange}
                    onError={handleVideoError}
                    onCanPlay={onVideoCanPlay}
                    onWaiting={onVideoWaiting}
                    {...rest}
                />
            ) : (
                <RemoteImage
                    className={twx('absolute left-0 top-0 h-full w-full shrink-0 object-cover', imgClassName)}
                    imageMedia={imageMedia}
                />
            )}
        </div>
    );
};

export default PageHeaderMedia;
