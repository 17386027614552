import React from 'react';

import {ButtonRaw} from '@/components/Button/index.js';
import {ArrowLeftIcon} from '@/components/Icons/index.js';
import routerService from '@/services/route/routerService.js';
import {twx} from '@/utils/tailwindUtils.js';

const BackButton = (props) => {
    const {className, onClick = routerService.goBack, alt, ...restProps} = props;
    const altResult = alt || 'Back';

    return (
        <ButtonRaw
            onClick={onClick}
            className={twx('flex h-12 w-12 items-center justify-center ml:h-6 ml:w-6', className)}
            {...restProps}
        >
            <ArrowLeftIcon className='rtl:rotate-180' ariaLabel={altResult} />
        </ButtonRaw>
    );
};

export default BackButton;
