import cn from 'classnames';
import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';

const LanguageSelectContentTitle = ({className, pageName}) => {
    return (
        <h2 className={cn('m-0 p-0 text-left ml:pr-8', className)}>
            <AemEditableText pageName={pageName || 'modals'} itemName='languageselecttitle' />
        </h2>
    );
};

export default LanguageSelectContentTitle;
