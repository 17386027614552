import {useState} from 'react';

import arrayUtils from '@/utils/arrayUtils';

const useHighlightsVideoBuffering = (highlights) => {
    const initialList = Array(highlights.length).fill(false);
    const [isVideoBufferingList, setIsVideoBufferingList] = useState(initialList);

    const getIsVideoBuffering = (i) => isVideoBufferingList[i];

    const handleVideoWaiting = (i) => {
        const newIsVideoBufferingList = arrayUtils.updateArrayImmutably(isVideoBufferingList, [i, true]);

        setIsVideoBufferingList(newIsVideoBufferingList);
    };

    const handleVideoCanPlay = (i) => {
        const newIsVideoBufferingList = arrayUtils.updateArrayImmutably(isVideoBufferingList, [i, false]);

        setIsVideoBufferingList(newIsVideoBufferingList);
    };

    return {getIsVideoBuffering, handleVideoWaiting, handleVideoCanPlay};
};

export default useHighlightsVideoBuffering;
