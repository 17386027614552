import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {StickyPageLayout} from '@/components/Layout';
import {PREFERENCE_CENTER_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {WEB_PREFERENCE_CENTER} from '@/consts/tnc/tncTypes';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import useTermsOfUsePopup from '@/hooks/useTermsOfUsePopup';
import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import tncService from '@/services/tncService';
import {twx} from '@/utils/tailwindUtils';
import TermsOfUseDescription from '@/views/Tnc/TermsOfUseDescription/TermsOfUseDescription';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import DCExpansionPanel from './components/DCExpansionPanel';
import SaveContinueButton from './components/SaveContinueButton';

const PreferenceCenter = ({description, acceptedLegals, legals, onApproved, isEnableAllAvailable, onBack}) => {
    const isMobileLayout = useIsMobileLayout();
    const {legalsSettingsNotNull, filteredAcceptedLegals} = tncService.getPreferenceCenterLegals(
        legals,
        acceptedLegals
    );
    const legalsSettingsNotNullCodes = legalsSettingsNotNull.map(({code}) => code);
    const [acceptedSettingList, setAcceptedSettingList] = useState(filteredAcceptedLegals);
    const {openPopup} = useTermsOfUsePopup({
        overlayClassName: 'md:left-[var(--profile-sidebar-width)] md:w-[calc(100%-var(--profile-sidebar-width))]',
    });
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[PREFERENCE_CENTER_PAGE_HEADER_TITLE]);

    useEffect(() => {
        amplitudeProfileTracking.trackPreferenceCenterScreen();
        googleAnalyticsService.trackPreferenceCenterScreenView();
    }, []);

    useEffect(() => {
        setAcceptedSettingList(filteredAcceptedLegals);
    }, [legals, acceptedLegals]);

    const onSettingLegalChange = (code, isAccepted) => {
        if (isAccepted) {
            setAcceptedSettingList([...acceptedSettingList, code]);
        } else {
            const isCodeInList = acceptedSettingList.indexOf(code) !== -1;

            if (isCodeInList) {
                const filteredList = acceptedSettingList.filter((el) => el !== code);

                setAcceptedSettingList(filteredList);
            }
        }
    };

    return (
        <>
            <StickyPageLayout
                className='items-center'
                desktopClassName='p-16 pb-4'
                headerOptions={{
                    onBack,
                    title: localizedStrings[PREFERENCE_CENTER_PAGE_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={
                    <div
                        className={twx(
                            'flex flex-col gap-6 ml:mt-8 ml:flex-row',
                            isEnableAllAvailable && 'ml:flex-row-reverse'
                        )}
                    >
                        <SaveContinueButton
                            className={cn(!isEnableAllAvailable && 'ml:w-[314px]')}
                            legals={legals}
                            onApproved={onApproved}
                            legalsSettingsNotNull={legalsSettingsNotNull}
                            acceptedSettingList={
                                isEnableAllAvailable ? legalsSettingsNotNullCodes : acceptedSettingList
                            }
                            itemName={isEnableAllAvailable ? 'preferenceCenterEnableAll' : 'preferenceCenterSave'}
                        />
                        {isEnableAllAvailable && (
                            <SaveContinueButton
                                isSecondary
                                legals={legals}
                                onApproved={onApproved}
                                legalsSettingsNotNull={legalsSettingsNotNull}
                                acceptedSettingList={acceptedSettingList}
                                itemName='preferenceCenterContinue'
                            />
                        )}
                    </div>
                }
            >
                <h1 className='mt-safe-offset-sticky-header mb-3 px-4 ml:mb-4 ml:mt-0 ml:px-0 ml:text-start ml:text-32 ml:leading-13125'>
                    <AemEditableText pageName={routePaths.TNC} itemName='preferenceCenterTitle' />
                </h1>
                <TermsOfUseDescription
                    className='mb-4 px-4 md:px-0 md:text-start'
                    innerHtml={description}
                    tncType={WEB_PREFERENCE_CENTER}
                    onTncLinkClick={openPopup}
                />
                {legalsSettingsNotNull.map(({code, title, text}) => {
                    return (
                        <div className='w-full px-4 md:px-8 ml:px-0' key={code}>
                            <DCExpansionPanel
                                index={code}
                                title={title}
                                innerText={text}
                                onChange={(value) => onSettingLegalChange(code, value)}
                                isActive={acceptedSettingList.includes(code)}
                                onTncLinkClick={openPopup}
                            />
                        </div>
                    );
                })}

                {isMobileLayout && <WarningBanners source={WarningBanners.SOURCES.PREFERENCE_CENTER} />}
            </StickyPageLayout>
            {!isMobileLayout && <WarningBanners source={WarningBanners.SOURCES.PREFERENCE_CENTER} />}
        </>
    );
};

export default PreferenceCenter;
