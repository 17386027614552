import {Slot} from '@radix-ui/react-slot';
import React from 'react';

import {twx} from '@/utils/tailwindUtils';

import {HighlightsSliderContext, useFullscreenHighlightsSliderContext} from './HighlightsSliderContext';

const HighlightsSlider = ({className, children, currentSlide, onClick}) => {
    const context = {
        currentSlide,
    };

    return (
        <HighlightsSliderContext.Provider value={context}>
            <div className={twx('relative h-full w-full overflow-hidden', className)} onClick={onClick}>
                {children}
            </div>
        </HighlightsSliderContext.Provider>
    );
};

const Slides = ({children = []}) => {
    return <div className='h-full w-full'>{children}</div>;
};

const Slide = ({className, children, index}) => {
    const {currentSlide} = useFullscreenHighlightsSliderContext();
    const isVisible = index === currentSlide;

    return <div className={twx('hidden h-full w-full', isVisible && 'block', className)}>{children}</div>;
};

const Buttons = ({children, asChild, ...props}) => {
    // if asChild === true, maps all props directly to child instead of creating additional div
    const Container = getComponentOrSlot(asChild, 'div');

    return <Container {...props}>{children}</Container>;
};

const getComponentOrSlot = (asChild, component) => {
    return asChild ? Slot : component;
};

HighlightsSlider.Slide = Slide;
HighlightsSlider.Slides = Slides;
HighlightsSlider.Buttons = Buttons;

export default HighlightsSlider;
