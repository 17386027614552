import * as localizationKeys from '@/consts/localization/localizationKeys';
import {TERMS_OF_USE} from '@/consts/tnc/tncTypes';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import TncDetailsContent from '@/views/Tnc/TncDetailsContent/TncDetailsContent';

const ProfileTermsOfUsePage = () => {
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[localizationKeys.TNC_PAGE_HEADER_TITLE]);

    return <TncDetailsContent pageType={TERMS_OF_USE} />;
};

export default ProfileTermsOfUsePage;
