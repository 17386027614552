import React from 'react';

import {twx} from '@/utils/tailwindUtils';

import SwiperNavigationButton from './SwiperNavigationButton';

const SwiperNavigation = ({prevRef, nextRef, isBeginning, isEnd, className}) => {
    return (
        <div className={twx('hidden shrink-0 gap-4 lg:flex', className)}>
            <SwiperNavigationButton ref={prevRef} disabled={isBeginning} className='rotate-180' />
            <SwiperNavigationButton ref={nextRef} disabled={isEnd} />
        </div>
    );
};

export default SwiperNavigation;
