import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import routePaths from '@/consts/route/routePaths';
import productHelpers from '@/services/product/productHelpers';

const AboutTitle = ({device}) => {
    const deviceName = productHelpers.getProductName(device);

    return (
        <h4 className='mb-0 flex flex-col text-28 leading-128 ml:mt-8 ml:flex-row ml:text-32 ml:leading-13125'>
            <span>
                <AemEditableText pageName={routePaths.ABOUT_DEVICE} itemName='aboutYour' />
            </span>
            <span className='hidden whitespace-pre-wrap ml:block'> </span>
            <span>{deviceName}</span>
        </h4>
    );
};

export default AboutTitle;
