import cn from 'classnames';
import React from 'react';

import styles from './Container.module.scss';

const Container = ({className, children}) => {
    return <div className={cn(styles.Container, className)}>{children}</div>;
};

export default Container;
