import {useSelector} from 'react-redux';

import {FlexPuffBlueIcon, FlexPuffIcon} from '@/components/Icons';
import {ON} from '@/consts/uam/onOffTypes';
import {selectDeviceSettings} from '@/state/selectors/device';

const useFlexPuffData = () => {
    const {engineJoule} = useSelector(selectDeviceSettings) || {};

    if (!engineJoule) return;

    const {featureState} = engineJoule;
    const isEnabled = featureState === ON;
    const itemName = isEnabled ? 'flexPuffOn' : 'flexPuffOff';
    const icon = isEnabled ? FlexPuffBlueIcon : FlexPuffIcon;

    return {
        itemName,
        icon,
    };
};

export default useFlexPuffData;
