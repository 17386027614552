import React from 'react';

import {AppButton} from '@/components/Button';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import {MaxWidthContainer} from '@/components/Layout';
import PageHeaderMedia from '@/components/PageHeaderMedia/PageHeaderMedia';
import InstallPwaLanguageSelect from '@/containers/InstallPwaPage/components/InstallPwaLanguageSelect';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import marketConfigService from '@/services/marketConfigService';
import appRouterService from '@/services/route/appRouterService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import InstallPwaPageContent from './components/InstallPwaPageContent';
import useInstalledAppRedirection from './useInstalledAppRedirection';

const InstallPwaPage = () => {
    const localizedStrings = getLocalizedStrings();
    const currentGetStartedPage = marketConfigService.getGetStartedPage();

    const {page, icons, skipButton} = currentGetStartedPage || {};
    const {titleKey, descriptionKey, imageMedia, videoPath, videoThumbnailPath} = page || {};

    useInstalledAppRedirection();

    const onClick = () => {
        appRouterService.forwardToLoginPage();
    };

    return (
        <div className='flex w-full flex-col'>
            <div className='flex w-full flex-1 flex-col'>
                <InstallPwaLanguageSelect />
                <div className='flex h-full w-full flex-col items-center justify-items-center ml:mx-8 ml:grid ml:max-w-[1024px] ml:grid-cols-[1fr_1fr] ml:grid-rows-[auto_auto] ml:gap-x-8 ml:self-center'>
                    <MobileScreenHeightContainer>
                        <PageHeaderMedia
                            className='ml:order-1 ml:row-span-2 ml:min-h-[370px]'
                            autoPlay
                            loop
                            playsInline
                            isVideoAvailable={!!videoThumbnailPath}
                            videoClassName='ml:rounded-[24px]'
                            imgClassName='ml:object-none'
                            videoThumbnailSrc={videoThumbnailPath}
                            videoSrc={videoPath}
                            imageMedia={imageMedia}
                            content={[{isControlsAvailable: false}]}
                        />
                        <MaxWidthContainer
                            className='m-8 flex grow flex-col items-center ml:m-0 ml:mb-8 ml:items-start ml:self-end'
                            size='ml'
                        >
                            <InstallPwaPageContent titleKey={titleKey} descriptionKey={descriptionKey} icons={icons} />
                        </MaxWidthContainer>
                    </MobileScreenHeightContainer>

                    <div className='order-2 ml:w-full ml:self-start'>
                        <AppButton
                            className='m-0 py-[10px] text-14 underline shadow-none ml:px-8 ml:py-0 ml:text-16 ml:no-underline'
                            dark
                            onClick={onClick}
                        >
                            {localizedStrings[skipButton?.titleKey]}
                            <div className='ml-3 hidden ml:inline'>
                                <RemoteIcon className='inline fill-primary' imagePath={skipButton?.imagePath} />
                            </div>
                        </AppButton>
                    </div>
                </div>
            </div>
            <WarningBanners source={WarningBanners.SOURCES.INSTALL_PWA} />
        </div>
    );
};

const MobileScreenHeightContainer = ({children}) => {
    const isMobileLayout = useIsMobileLayout();

    return isMobileLayout ? <div className='min-h-svh'>{children}</div> : <>{children}</>;
};

export default InstallPwaPage;
