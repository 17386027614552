import {useEffect, useRef} from 'react';

const useHighlightsSlideProgress = ({isAutoPlayEnabled, currentSlide}) => {
    const buttonRefs = useRef([]);
    const videoRefs = useRef([]);

    const setButtonRef = (i, ref) => {
        buttonRefs.current[i] = ref;
    };

    const setVideoRef = (i, ref) => {
        videoRefs.current[i] = ref;
    };

    const getSlideProgress = (i) => {
        const buttonProgress = isAutoPlayEnabled ? buttonRefs.current[i]?.getProgress() : 1;
        const videoProgress = videoRefs.current[i]?.getVideoCurrentTime();

        return {buttonProgress, videoProgress};
    };

    const setSlideProgress = (i, {buttonProgress, videoProgress}) => {
        if (isAutoPlayEnabled) {
            buttonRefs.current[i]?.setProgress(buttonProgress);
        }

        videoRefs.current[i]?.seekVideo(videoProgress);
    };

    const resetSlideProgress = (i) => {
        if (isAutoPlayEnabled) {
            buttonRefs.current[i]?.resetProgress();
        }

        videoRefs.current[i]?.resetVideoToStart();
    };

    useEffect(() => {
        resetSlideProgress(currentSlide);
    }, [currentSlide]);

    return {setButtonRef, setVideoRef, getSlideProgress, setSlideProgress, resetSlideProgress};
};

export default useHighlightsSlideProgress;
