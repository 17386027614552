import useIsMobileLayout from '@/hooks/useIsMobileLayout';

const useHighlightsIsTextLarger = (isFullscreen) => {
    const isMobile = useIsMobileLayout();
    const isTextLarger = isMobile && isFullscreen;

    return isTextLarger;
};

export default useHighlightsIsTextLarger;
