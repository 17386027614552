import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import {AppButton} from '../../../components/Button';
import {RegularCleaningIcon} from '../../../components/Icons';
import {StickyHeader} from '../../../components/Layout';
import appConfig from '../../../config/appConfig';
import * as advanceDiagnosticScreenTypes from '../../../consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import {AD_FITTING_CONSUMABLES_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import appRouterService from '../../../services/route/appRouterService';
import routerService from '../../../services/route/routerService';
import {selectDeviceType} from '../../../state/selectors/device';
import advanceDiagnosticService from '../advanceDiagnosticService';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';

const AdvancedDiagnosticFittingConsumables = () => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();
    const deviceType = useSelector(selectDeviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'step3');
    const dataArrayCount = diagnosticData.length;
    const [dataIndex, setDataIndex] = useState(0);
    const isLastStep = dataArrayCount - 1 === dataIndex;
    const {title, description, acceptButtonText, declineButtonText, errorCode, isHolder} =
        diagnosticData[dataIndex] || {};

    const onBackButtonClick = () => {
        if (dataIndex > 0) {
            setDataIndex(dataIndex - 1);
        } else {
            routerService.goBack();
        }
    };

    const declineClick = () => {
        const screenName = advanceDiagnosticScreenTypes.QUESTIONNAIRE_MARKET_SCREEN;

        advanceDiagnosticService.setInferenceCodeAndRedirect(errorCode, isHolder, screenName, true);
    };

    const onNextClick = () => {
        if (isLastStep) {
            appRouterService.forwardToFittingConsumablesResolvedAD();
        } else {
            setDataIndex(dataIndex + 1);
        }
    };

    useDocumentTitle(localizedStrings[AD_FITTING_CONSUMABLES_HEADER_TITLE]);

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer>
                {isMobile && (
                    <StickyHeader
                        onBack={onBackButtonClick}
                        title={localizedStrings[AD_FITTING_CONSUMABLES_HEADER_TITLE]}
                    />
                )}
                <RegularCleaningIcon className='mb-4 h-16 w-16' />
                <h4 className='mt-4 text-28 md:mt-2 ml:mt-3 ml:text-32'>{localizedStrings[title]}</h4>
                <p>
                    <span className='whitespace-pre-line'>{localizedStrings[description]}</span>
                </p>
                <div className='mt-10 w-full md:w-auto'>
                    <AppButton secondary light className='mb-3 mt-0 md:min-w-[346px]' onClick={onNextClick}>
                        {localizedStrings[acceptButtonText]}
                    </AppButton>
                    <AppButton secondary light className='mb-3 mt-0 md:min-w-[346px]' onClick={declineClick}>
                        {localizedStrings[declineButtonText]}
                    </AppButton>
                </div>
            </AdvancedDiagnosticContainer>{' '}
            <WarningBanners source={WarningBanners.SOURCES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticFittingConsumables;
