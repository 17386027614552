import ROUTE_PATHS from '@/consts/route/routePaths';
import useLocationPathname from '@/hooks/useLocationPathname';
import appRouterService from '@/services/route/appRouterService';

const useOnRenameClick = () => {
    const {pathname} = useLocationPathname();

    const onRenameClick = () => {
        if (pathname === ROUTE_PATHS.DEVICE_RENAME) return;

        const shouldReplace = pathname !== ROUTE_PATHS.DEVICES_PAGE;

        appRouterService.forwardToDeviceRenamePage(shouldReplace);
    };

    return onRenameClick;
};

export default useOnRenameClick;
