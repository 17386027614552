import React, {useEffect} from 'react';

import BackButton from '@/components/BackButton/BackButton';
import {ButtonRaw} from '@/components/Button';
import {TrashIcon} from '@/components/Icons';
import AppLoaderContent from '@/components/Loader/AppLoader/AppLoaderContent';
import * as sourceTypes from '@/consts/sourceTypes';
import useMessageDeletionWithUndo from '@/hooks/inbox/useMessageDeletionWithUndo';
import useNotificationDetails from '@/hooks/inbox/useNotificationDetails';
import amplitudeInboxTracking from '@/services/analytics/amplitude/amplitudeInboxTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';

import NotificationDetailsMessage from '../../Inbox/NotificationDetails/components/NotificationDetailsMessage';
import InboxPopoverContent from './InboxPopoverContent';

const InboxPopoverNotificationDetails = ({messageId, closeNotificationDetails}) => {
    const {detailedMessage, isLoaderActive} = useNotificationDetails({
        messageId,
        onErrorHandler: closeNotificationDetails,
    });

    useEffect(() => {
        if (detailedMessage) {
            amplitudeInboxTracking.trackNotificationDetailedScreen(sourceTypes.INBOX, detailedMessage.campaignName);
            googleAnalyticsService.trackInboxDetailsScreenView(sourceTypes.INBOX, detailedMessage.campaignName);
        }
    }, [detailedMessage]);

    const handleMessageDeleteClick = useMessageDeletionWithUndo({messageId});
    const onDeleteClick = () => {
        handleMessageDeleteClick();
        closeNotificationDetails();
    };

    return (
        <InboxPopoverContent className='h-[556px]'>
            <InboxPopoverContent.Title className='flex items-center justify-between'>
                <BackButton onClick={closeNotificationDetails} />
                <ButtonRaw onClick={onDeleteClick}>
                    <TrashIcon />
                </ButtonRaw>
            </InboxPopoverContent.Title>
            <InboxPopoverContent.Content>
                {isLoaderActive ? (
                    <div className='flex h-full items-center justify-center'>
                        <AppLoaderContent />
                    </div>
                ) : (
                    <div className='flex flex-col pt-2 text-start'>
                        <NotificationDetailsMessage {...detailedMessage} />
                    </div>
                )}
            </InboxPopoverContent.Content>
        </InboxPopoverContent>
    );
};

export default InboxPopoverNotificationDetails;
