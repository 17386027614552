import amplitudePluginTypes from '@/consts/analytics/amplitudePluginTypes';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import marketService from '@/services/marketService';

class EnricherPlugin {
    name = 'enricher-plugin';
    type = amplitudePluginTypes.ENRICHMENT;

    async setup(config) {
        return undefined;
    }

    async execute(event) {
        const eventProperties = event[analyticsEventProperties.EVENT_PROPERTIES] || {};

        event[analyticsEventProperties.EVENT_PROPERTIES] = {
            ...eventProperties,
            market: marketService.getMarketCode()?.toUpperCase(),
        };

        return event;
    }
}

export default EnricherPlugin;
