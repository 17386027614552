import React from 'react';

import routePaths from '@/consts/route/routePaths';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import ContentList from '../../../components/ContentList/ContentList';
import {MaxWidthContainer} from '../../../components/Layout';
import appConfig from '../../../config/appConfig';

const BenefitsSection = ({className}) => {
    const list = appConfig.getQuickOrderBenefitsIcons();

    return (
        <article className={className}>
            <AemEditableText
                pageName={routePaths.QUICK_ORDER}
                itemName='benefitsTitle'
                render={(title) => (title ? <h2 className='mb-0 text-24'>{title}</h2> : null)}
            />
            <MaxWidthContainer className='mt-4 flex-1 first:mt-0'>
                <ContentList list={list} className='md:grid md:grid-cols-2 md:items-start' />
            </MaxWidthContainer>
        </article>
    );
};

export default BenefitsSection;
