import {useEffect, useState} from 'react';

import queryParameters from '@/consts/route/queryParameters';
import {TERMS_OF_USE, WEB_PREFERENCE_CENTER} from '@/consts/tnc/tncTypes';
import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appErrorService from '@/services/app/appErrorService';
import tncService from '@/services/tncService';
import urlUtils from '@/utils/urlUtils';

const useTncDetailsPage = () => {
    const [pageTypeFromUrl] = urlUtils.getDataFromUrl(queryParameters.TNC_PAGE_TYPE);

    const [pageType, setPageType] = useState(pageTypeFromUrl);

    useEffect(() => {
        setPageType(pageTypeFromUrl);
    }, [pageTypeFromUrl]);

    useEffect(() => {
        tncService.getTncData(null, appErrorService.showGlobalError);
    }, []);

    const handlePreferenceCenterClick = () => {
        setPageType(WEB_PREFERENCE_CENTER);
    };

    const handleTermsOfUseClick = () => {
        amplitudeProfileTracking.trackTermsOfUseClick();
        googleAnalyticsService.trackTermsOfUseClick();
        setPageType(TERMS_OF_USE);
    };

    return {
        handlePreferenceCenterClick,
        handleTermsOfUseClick,
        pageType,
    };
};

export default useTncDetailsPage;
