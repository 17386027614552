import analyticsEventNames from '@/consts/analytics/analyticsEventNames';

import amplitude from './amplitude';
import amplitudeDeviceTracking from './amplitudeDeviceTracking';

const trackDevicesPageScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.DEVICE_SECTION_VIEWED);
};

const trackTryYourFirstIqosButtonClick = () => {
    amplitude.trackWithSource(analyticsEventNames.TRY_YOUR_FIRST_IQOS_BUTTON_CLICKED);
};

const trackAboutMyDeviceScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(analyticsEventNames.ABOUT_MY_DEVICE_SCREEN_VIEWED);
};

const trackTipsAndTutorialsScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(analyticsEventNames.TIPS_AND_TUTORIAL_SCREEN_VIEWED);
};

export default {
    trackDevicesPageScreen,
    trackTryYourFirstIqosButtonClick,
    trackAboutMyDeviceScreen,
    trackTipsAndTutorialsScreen,
};
