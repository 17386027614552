import React from 'react';

import routePaths from '@/consts/route/routePaths';
import {twx} from '@/utils/tailwindUtils';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '../../../components/Button';
import {ArrowIcon} from '../../../components/Icons';

const ConsumerProfileSection = ({headerItemName, className, items}) => {
    return (
        <article className={twx('mt-9', className)}>
            <ConsumerProfileSectionHeader itemName={headerItemName} />
            <ul className='mt-4'>
                {items.map((item, index) => {
                    return <ConsumerProfileSectionListItem key={index} {...item} />;
                })}
            </ul>
        </article>
    );
};

const ConsumerProfileSectionHeader = ({itemName}) => {
    return (
        <h2 className='mb-0 text-24'>
            <AemEditableText pageName={routePaths.CONSUMER_PROFILE} itemName={itemName} />
        </h2>
    );
};

const ConsumerProfileSectionListItem = ({icon, itemName, onClick}) => {
    return (
        <li className='border-b border-border'>
            <ButtonRaw className='flex w-full gap-5 py-4' onClick={onClick}>
                {icon && icon}
                <span className='flex-1'>
                    <AemEditableText pageName={routePaths.CONSUMER_PROFILE} itemName={itemName} />
                </span>
                <ArrowIcon />
            </ButtonRaw>
        </li>
    );
};

export default ConsumerProfileSection;
