import React from 'react';

import Icon from '../Icon/Icon';

const TipsAndTutorialsBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9 41.25C8.29899 41.25 7.54899 40.3661 6.75 38.5982V18.8288C6.75 18.0037 7.19749 17.2274 8.09246 16.5H12.9075C13.8025 17.2274 14.25 18.0037 14.25 18.8288V38.5982C13.451 40.3661 12.701 41.25 12 41.25H9Z'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.25 41.25C19.549 41.25 18.799 40.0982 18 37.7947V12.0345C18 10.9593 18.4475 9.94784 19.3425 9H24.1575C25.0525 9.94784 25.5 10.9593 25.5 12.0345V37.7947C24.701 40.0982 23.951 41.25 23.25 41.25H20.25Z'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
            />
            <path
                d='M10.5659 25.0361C11.3358 25.0361 11.9599 24.412 11.9599 23.6421C11.9599 22.8722 11.3358 22.248 10.5659 22.248C9.796 22.248 9.17188 22.8722 9.17188 23.6421C9.17188 24.412 9.796 25.0361 10.5659 25.0361Z'
                fill='#34303D'
                stroke='#00D1D2'
                strokeWidth='1.5'
            />
            <path
                d='M21.8159 25.0361C22.5858 25.0361 23.2099 24.412 23.2099 23.6421C23.2099 22.8722 22.5858 22.248 21.8159 22.248C21.046 22.248 20.4219 22.8722 20.4219 23.6421C20.4219 24.412 21.046 25.0361 21.8159 25.0361Z'
                fill='#34303D'
                stroke='#00D1D2'
                strokeWidth='1.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M35.25 6.9375C36.8551 6.9375 38.3082 7.58809 39.3601 8.63994C40.4119 9.6918 41.0625 11.1449 41.0625 12.75V39.75C41.0625 40.1124 40.9156 40.4406 40.6781 40.6781C40.4406 40.9156 40.1124 41.0625 39.75 41.0625H30.75C30.3876 41.0625 30.0594 40.9156 29.8219 40.6781C29.5844 40.4406 29.4375 40.1124 29.4375 39.75V8.25C29.4375 7.88756 29.5844 7.55944 29.8219 7.32192C30.0594 7.08441 30.3876 6.9375 30.75 6.9375H35.25Z'
                stroke='#34303D'
                strokeWidth='2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M33 9.75H35.25C36.9069 9.75 38.25 11.0931 38.25 12.75V37.5C38.25 37.9142 37.9142 38.25 37.5 38.25H33C32.5858 38.25 32.25 37.9142 32.25 37.5V10.5C32.25 10.0858 32.5858 9.75 33 9.75Z'
                fill='#00D1D2'
            />
        </Icon>
    );
};

export default TipsAndTutorialsBlueIcon;
