import React from 'react';

import Icon from '../Icon/Icon';

const VibrateEnabledBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g stroke='#272A33' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'>
                    <path d='M33.79 17.34a11.236 11.236 0 0 1-.3 11.746M37.899 15.014a15.976 15.976 0 0 1-.413 16.68' />
                </g>
                <g stroke='#272A33' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'>
                    <path d='M14.21 17.34a11.236 11.236 0 0 0 .3 11.746M10.101 15.014a15.976 15.976 0 0 0 .413 16.68' />
                </g>
                <g transform='translate(19.5 8)'>
                    <path
                        d='M2.772 32C1.91 32 .984 30.857 0 28.571V3.011C0 1.944.551.94 1.654 0h5.702C8.458.94 9.01 1.944 9.01 3.01v25.561C8.025 30.857 7.1 32 6.238 32z'
                        stroke='#34303D'
                        strokeWidth='2'
                        strokeLinejoin='round'
                    />
                    <path
                        d='M2.441 2.783c-.241.356-.362 1.114-.362 2.275v21.377'
                        stroke='#00D1D2'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                    />
                    <circle stroke='#00D1D2' fill='#34303D' cx='5.413' cy='12.63' r='1.5' />
                </g>
            </g>
        </Icon>
    );
};

export default VibrateEnabledBlueIcon;
