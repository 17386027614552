import React from 'react';

import appConfig from '@/config/appConfig';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import {AUTO_DELIVERY_INACTIVE_BUTTON} from '@/consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';

import AutoDeliveryButton from '../AutoDeliveryButton';

const AutoDeliveryInactiveButton = (props) => {
    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryDashboardButtonClick(analyticsEventValues.REACTIVATE);
    };

    return (
        <AutoDeliveryButton
            localizationKey={AUTO_DELIVERY_INACTIVE_BUTTON}
            amplitudeEvent={amplitudeEvent}
            externalLinkConfig={appConfig.getAutoDeliveryInactiveExternalLink()}
            {...props}
        />
    );
};

export default AutoDeliveryInactiveButton;
