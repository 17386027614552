import React from 'react';

import routePaths from '@/consts/route/routePaths';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';

const AdvancedDiagnosticTitle = ({itemName}) => {
    return (
        <h4 className='mt-4 text-28'>
            <AemComponent component={EditableText} pageName={routePaths.ADVANCE_DIAGNOSTIC} itemName={itemName} />
        </h4>
    );
};

export default AdvancedDiagnosticTitle;
