import React from 'react';

import amplitudeHomePageTracking from '@/services/analytics/amplitude/amplitudeHomePageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';

import {QUICK_START_GUIDE_ACTION_TYPE} from '../consts/howToUseActionTypes';
import HomePageForYouContainer from './HomePageForYouContainer/HomePageForYouContainer';

const HomePageLearnHowToUseItem = (props) => {
    const {banner, deviceType, isGradient, action: actionType} = props;
    const {titleKey, subTitleKey, imageMedia, videoPath, isDarkBannerText, amplitudeTrackingValue} = banner;

    const isQuickStartGuide = actionType === QUICK_START_GUIDE_ACTION_TYPE;
    const localizedStrings = getLocalizedStrings();

    const onClick = () => {
        amplitudeHomePageTracking.trackForYouTileOpened(amplitudeTrackingValue);
        googleAnalyticsService.trackForYouTileClick(amplitudeTrackingValue);
        isQuickStartGuide
            ? appRouterService.forwardToQuickStartGuidePage(deviceType)
            : appRouterService.forwardToTipsAndTutorialsList(deviceType);
    };

    return (
        <HomePageForYouContainer
            imageMedia={imageMedia}
            videoPath={videoPath}
            onClick={onClick}
            isDarkText={isDarkBannerText}
            titleText={localizedStrings[titleKey]}
            subTitleText={localizedStrings[subTitleKey]}
            linearGradient={isGradient}
        />
    );
};

export default HomePageLearnHowToUseItem;
