import cn from 'classnames';
import React, {useState} from 'react';

import {ButtonRaw} from '@/components/Button';
import {CancelSmallIcon, InfoIcon} from '@/components/Icons';
import InfoBox from '@/components/InfoBox/InfoBox';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import storageService from '@/services/storage/storageService';

const MissingDeviceNotification = ({className}) => {
    const dataFromStorage = storageService.getMissingDeviceNotificationDataFromLocalStorage();
    const wasNotificationClosedBefore = !!dataFromStorage;
    const [isVisible, setIsVisible] = useState(!wasNotificationClosedBefore);

    if (!isVisible) return;
    const localizedStrings = getLocalizedStrings();

    const onCloseClick = () => {
        setIsVisible(false);
        storageService.setMissingDeviceNotificationDataToLocalStorage(true);
    };

    return (
        <InfoBox className={cn('flex items-start justify-between', className)}>
            <div className='flex items-center gap-2 text-start'>
                <InfoIcon className='shrink-0' />
                <div>
                    <h4 className='mb-0 text-16 ml:leading-15 ml:tracking-02'>
                        {localizedStrings[localizationKeys.MY_DEVICES_LIST_MISSING_DEVICE_TITLE]}
                    </h4>
                    <p className='text-14 ml:leading-143 ml:tracking-04'>
                        {localizedStrings[localizationKeys.MY_DEVICES_LIST_MISSING_DEVICE_DESCRIPTION_TEXT]}
                    </p>
                </div>
            </div>
            <ButtonRaw onClick={onCloseClick} className='shrink-0'>
                <CancelSmallIcon />
            </ButtonRaw>
        </InfoBox>
    );
};

export default MissingDeviceNotification;
