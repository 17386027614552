import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {LeviaIcon} from '@/components/Icons';
import {MaxWidthContainer} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import routePaths from '@/consts/route/routePaths';

import LeviaLearnMoreItem from './LeviaLearnMoreItem';

const LeviaLearnMoreArticle = () => {
    const list = appConfig.getLeviaLearnMorePopupIcons();
    const isAnyItem = list.some((x) => x.titleKey);

    return (
        <MaxWidthContainer className='flex-1 px-4 pb-4 pt-safe-offset-[calc(var(--sticky-header-height)+16px)] ml:max-w-none ml:p-0 ml:pt-safe-offset-14'>
            <LeviaIcon className='h-16 w-16' />
            <h1 className='mb-0 mt-4 text-28 ml:leading-143'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='leviaTitle' />
            </h1>
            <p className='ml:mt-4 ml:text-16 ml:leading-15'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='leviaDesc' />
            </p>
            {isAnyItem && (
                <p className='mt-8 text-20 font-bold ml:mt-4 ml:leading-12 ml:tracking-02'>
                    <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='leviaAbout' />
                </p>
            )}
            <ul className='mt-4 flex max-w-[356px] flex-col items-start gap-4 ml:w-full ml:max-w-none'>
                {list.map((item, index) => (
                    <LeviaLearnMoreItem key={index} item={item} />
                ))}
            </ul>
        </MaxWidthContainer>
    );
};

export default LeviaLearnMoreArticle;
