import {useSelector} from 'react-redux';

import {BigBlueSunIcon, BigSunIcon, SmallBlueSunIcon, StealthIcon} from '@/components/Icons';
import * as ledModeTypes from '@/consts/uam/ledModeTypes';
import {selectDeviceSettings} from '@/state/selectors/device';

const useLedData = () => {
    const {ledIntensity} = useSelector(selectDeviceSettings) || {};

    if (!ledIntensity) return;

    const {intensity} = ledIntensity;
    const get100Icon = (isActive) => (isActive ? BigBlueSunIcon : BigSunIcon);
    const itemNameStrong = 'illuminationModeStrong';

    let icon;
    let itemName;

    switch (intensity) {
        case ledModeTypes.DAY_MODE: {
            itemName = itemNameStrong;
            icon = get100Icon(true);
            break;
        }
        case ledModeTypes.NIGHT_MODE: {
            itemName = 'illuminationModeLow';
            icon = SmallBlueSunIcon;
            break;
        }
        case ledModeTypes.STEALTH_MODE: {
            itemName = 'illuminationModeStealth';
            icon = StealthIcon;
            break;
        }
        default:
            itemName = itemNameStrong;
            icon = get100Icon();
    }

    return {
        itemName,
        icon,
    };
};

export default useLedData;
