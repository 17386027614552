import React, {useRef, useState} from 'react';

import HighlightsSlideButtonContainer from '../../common/components/HighlightsSlideButtonContainer';
import useHighlightsAnimationDuration from '../../common/hooks/useHighlightsAnimationDuration';
import HighlightsSlideButton from '../components/HighlightsSlideButton';
import useHighlightsCarouselFlags from './useHighlightsCarouselFlags';
import useHighlightsCarouselSlideRestart from './useHighlightsCarouselSlideRestart';
import useHighlightsSliderSync from './useHighlightsSliderSync';

const BUTTON_ANIMATION_CLASS = 'animated';

const useHighlightsCarousel = ({
    isFullscreen,
    isPlaying,
    isInfinite,
    autoplay,
    initialSlide,
    currentIndex,
    highlights,
    getIsVideoBuffering,
    onSlideChange,
    onCarouselEnded,
}) => {
    const sliderRef = useRef(null);
    const [currentIndexBeforeChange, setCurrentIndexBeforeChange] = useState(initialSlide);

    const {canSwipe, slideChangeAnimationSpeed} = useHighlightsCarouselFlags();
    const {getAnimationDuration, handleLoadedMetadata, handleVideoError} = useHighlightsAnimationDuration({
        highlights,
        autoplay,
        currentIndex,
    });
    const {setVideoRef, setAnimatedButtonRef, restartSlide} = useHighlightsCarouselSlideRestart({
        highlights,
        animationClass: BUTTON_ANIMATION_CLASS,
    });

    useHighlightsSliderSync({sliderRef, currentIndex});

    const getIsActiveSlide = (i) => currentIndexBeforeChange === i;

    const options = {
        arrows: false,
        infinite: true,
        adaptiveHeight: true,
        dots: true,
        swipe: canSwipe,
        speed: slideChangeAnimationSpeed,
        appendDots: (dots) => (
            <HighlightsSlideButtonContainer isFullscreen={isFullscreen}>{dots}</HighlightsSlideButtonContainer>
        ),
        customPaging: (i) => {
            const isVideoBuffering = getIsVideoBuffering(i);
            const isAnimationPlaying = isPlaying && !isVideoBuffering;

            return (
                <HighlightsSlideButton
                    ref={(ref) => setAnimatedButtonRef(i, ref)}
                    animationClass={BUTTON_ANIMATION_CLASS}
                    animationDuration={getAnimationDuration(i)}
                    isAnimationPlaying={isAnimationPlaying}
                    isAnimationEnded={currentIndexBeforeChange > i}
                    onAnimationEnd={handleSlideEnded}
                />
            );
        },
        initialSlide,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentIndexBeforeChange(newIndex);

            // when user clicks on playing slide, change event is raised and oldIndex is same as newIndex
            if (oldIndex === newIndex) {
                setTimeout(() => restartSlide(newIndex), 0);
            }
        },
        afterChange: (newIndex) => {
            onSlideChange?.(newIndex);
        },
    };

    const handleSlideEnded = () => {
        const isLastSlide = currentIndex >= highlights.length - 1;

        if (isLastSlide && !isInfinite) {
            onCarouselEnded?.();
        } else {
            sliderRef.current.slickNext();
        }
    };

    return {
        sliderRef,
        options,
        getIsActiveSlide,
        setVideoRef,
        handleLoadedMetadata,
        handleVideoError,
        handleSlideEnded,
    };
};

export default useHighlightsCarousel;
