import cn from 'classnames';
import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import {InfoBlueIcon, OtherDeviceConnectedIcon} from '@/components/Icons';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useOnConnectClick from '@/containers/DevicesPage/hooks/useOnConnectClick';
import useLayout from '@/hooks/inbox/useLayout';
import amplitudeDeviceTracking from '@/services/analytics/amplitude/amplitudeDeviceTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import userBrowserService from '@/services/user/userBrowserService';
import stringUtils from '@/utils/stringUtils';
import uaUtils from '@/utils/uaUtils';

import DevicesSettingsSection from '../../DevicesSettingsSection/DevicesSettingsSection';

const MyDevicesConnectButton = ({device}) => {
    const {deviceSerialNumber, type} = device || {};
    const {isMobile, isMl} = useLayout();
    const {onConnectClick} = useOnConnectClick(type);

    const trackAndHandleConnect = () => {
        amplitudeDeviceTracking.trackPairDeviceClick(deviceSerialNumber, type);
        googleAnalyticsService.trackPairDeviceClick(type, deviceSerialNumber);
        onConnectClick();
    };

    return isMobile || isMl ? (
        <MobileConnectButton onClick={trackAndHandleConnect} />
    ) : (
        <DesktopConnectButton onClick={trackAndHandleConnect} />
    );
};

const MobileConnectButton = ({onClick}) => {
    return (
        <DevicesSettingsSection.Button
            bold
            itemName='connectYourIqosNew'
            icon={OtherDeviceConnectedIcon}
            onClick={onClick}
        />
    );
};

const DesktopConnectButton = ({onClick}) => {
    const isBrowserTrusted = userBrowserService.isBrowserTrusted();

    return isBrowserTrusted ? (
        <AemEditableText
            pageName={routePaths.DEVICES_PAGE}
            itemName='connectButton'
            render={(text) =>
                text && (
                    <AppButton className={cn('m-0 mt-1 self-start')} primary light onClick={onClick}>
                        {text}
                    </AppButton>
                )
            }
        />
    ) : (
        <BrowserNotTrustedMessage />
    );
};

const BrowserNotTrustedMessage = () => {
    const localizedStrings = getLocalizedStrings();
    const browserName = uaUtils.getBrowserName();

    const topText = stringUtils.formatString(
        localizedStrings[localizationKeys.MY_DEVICES_PAGE_CONNECT_BROWSER_NOT_TRUSTED_TEXT_1],
        browserName
    );
    const bottomText = localizedStrings[localizationKeys.MY_DEVICES_PAGE_CONNECT_BROWSER_NOT_TRUSTED_TEXT_2];

    if (!topText && !bottomText) return null;

    return (
        <div className='mt-2 flex w-full flex-col gap-4.5 rounded-xl bg-romance p-4'>
            <div className={cn('flex gap-2', (!topText || !bottomText) && 'items-center')}>
                <div className='p-1.5'>
                    <InfoBlueIcon />
                </div>
                <div className='flex flex-col'>
                    {topText && <p className='text-16 font-bold leading-15 tracking-02'>{topText}</p>}
                    {bottomText && <p className='text-14 leading-143 tracking-04'>{bottomText}</p>}
                </div>
            </div>
        </div>
    );
};

export default MyDevicesConnectButton;
