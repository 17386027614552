import React from 'react';

import routePaths from '@/consts/route/routePaths';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';

const AdvancedDiagnosticDescription = ({itemName}) => {
    if (!itemName) return null;

    return (
        <p>
            <span className='whitespace-pre-line'>
                <AemComponent component={EditableText} pageName={routePaths.ADVANCE_DIAGNOSTIC} itemName={itemName} />
            </span>
        </p>
    );
};

export default AdvancedDiagnosticDescription;
