import cn from 'classnames';
import React from 'react';

import DeviceHero from '@/components/DeviceHero/DeviceHero';

const AdvancedDiagnosticContainer = ({children, isDeviceHero}) => {
    return (
        <div className='flex w-full flex-1 flex-col overflow-hidden'>
            <div
                className={cn(
                    'relative flex flex-1 flex-col items-center pb-4',
                    isDeviceHero ? 'pt-12' : 'pt-safe-offset-sticky-header'
                )}
            >
                <section className={cn('flex w-full flex-1 flex-col items-center', isDeviceHero ? 'px-0' : 'px-8')}>
                    {isDeviceHero && <DeviceHero containerClassName='w-full mb-9 -mt-12' />}
                    {children}
                </section>
            </div>
        </div>
    );
};

export default AdvancedDiagnosticContainer;
