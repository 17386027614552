import analyticsEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';

import amplitude from './amplitude';

const trackInboxScreen = () => {
    amplitude.trackWithSource(analyticsEventNames.INBOX_SCREEN_VIEWED);
};

const trackNotificationDetailedScreen = (source, campaignName) => {
    const pushSource = amplitude.getPushSource(source);

    amplitude.trackWithSource(analyticsEventNames.INBOX_MESSAGE_OPENED, {
        [analyticsEventProperties.PUSH_SOURCE]: pushSource,
        [analyticsEventProperties.CAMPAIGN_NAME]: campaignName,
    });
};

const trackInboxMessageButtonClicked = (source, campaignName) => {
    const pushSource = amplitude.getPushSource(source);

    amplitude.trackWithSource(analyticsEventNames.INBOX_MESSAGE_BUTTON_CLICKED, {
        [analyticsEventProperties.PUSH_SOURCE]: pushSource,
        [analyticsEventProperties.CAMPAIGN_NAME]: campaignName,
    });
};

const trackActionScreen = (source) => {
    const pushSource = amplitude.getPushSource(source);

    amplitude.trackWithSource(analyticsEventNames.ACTION_SCREEN_VIEWED, {
        [analyticsEventProperties.PUSH_SOURCE]: pushSource,
    });
};

export default {
    trackInboxScreen,
    trackNotificationDetailedScreen,
    trackInboxMessageButtonClicked,
    trackActionScreen,
};
