import {TERMS_OF_USE, WEB_PREFERENCE_CENTER} from '@/consts/tnc/tncTypes';
import {PROFILE_SIDEBAR_MENU_ITEMS} from '@/containers/ConsumerProfilePage/consts/profileSidebarMenu';
import ProfilePageContainer from '@/containers/ConsumerProfilePage/ProfilePageContainer';
import routerService from '@/services/route/routerService';

import TncDetailsContent from '../../views/Tnc/TncDetailsContent/TncDetailsContent';
import useTncDetailsPage from './useTncDetailsPage';

const TncDetailsPage = () => {
    const {handlePreferenceCenterClick, handleTermsOfUseClick, pageType} = useTncDetailsPage();
    const activeMenuItem = getMenuItemFromPageType(pageType);

    const handleApproved = routerService.goBack;

    return (
        <ProfilePageContainer
            activeMenuItem={activeMenuItem}
            isTncLayout
            onPreferenceCenterClick={handlePreferenceCenterClick}
            onTermsOfUseClick={handleTermsOfUseClick}
        >
            <TncDetailsContent
                pageType={pageType}
                onMandatoryTermsAccept={handlePreferenceCenterClick}
                onApproved={handleApproved}
            />
        </ProfilePageContainer>
    );
};

const getMenuItemFromPageType = (pageType) => {
    switch (pageType) {
        case TERMS_OF_USE:
            return PROFILE_SIDEBAR_MENU_ITEMS.TERMS_OF_USE;
        case WEB_PREFERENCE_CENTER:
            return PROFILE_SIDEBAR_MENU_ITEMS.PREFERENCE_CENTER;
        default:
            return null;
    }
};

export default TncDetailsPage;
