import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';

const FirmwareAvailableButtons = ({onCancel}) => {
    const checkCompatibilityExternalLink = appConfig.getFirmwareAvailableCheckCompatibilityExternalLink();
    const learnMoreExternalLink = appConfig.getFirmwareAvailableLearnMoreExternalLink();
    const redirectToExternalLink = useConfigExternalLink(checkCompatibilityExternalLink);
    const redirectToLearnMore = useConfigExternalLink(learnMoreExternalLink);

    return (
        <div className='m-auto flex max-w-[312px] flex-col items-center gap-4 ml:mt-8 ml:w-full ml:max-w-none ml:items-stretch ml:gap-3'>
            {checkCompatibilityExternalLink && (
                <AppButton className='mt-0' primary light onClick={redirectToExternalLink}>
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.MODALS}
                        itemName='fwAvailableCompatibilityButton'
                    />
                </AppButton>
            )}
            {learnMoreExternalLink && (
                <AppButton className='mt-0' secondary light onClick={redirectToLearnMore}>
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.MODALS}
                        itemName='fwAvailableLearnMoreButton'
                    />
                </AppButton>
            )}
            <AppButton tertiary className='mt-0 text-center ml:self-center' onClick={onCancel}>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='fwAvailableSkipButton' />
            </AppButton>
        </div>
    );
};

export default FirmwareAvailableButtons;
