import * as localizationKeys from '../../consts/localization/localizationKeys';
import {setNotificationError} from '../../state/slices/notificationsSlice';
import {dispatch} from '../../state/store';
import {getLocalizedStrings} from '../localization/localizationService';

const showError = (errorKey) => dispatch(setNotificationError(getLocalizedStrings()[errorKey]));

const showGlobalError = () => {
    showError(localizationKeys.GLOBAL_ERROR_NOTIFICATION_TEXT);
};

export default {
    showGlobalError,
};
