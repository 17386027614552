import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import * as React from 'react';

import {twx} from '@/utils/tailwindUtils';

const ScrollArea = React.forwardRef(({className, children, ...props}, ref) => (
    <ScrollAreaPrimitive.Root ref={ref} className={twx('relative overflow-hidden', className)} {...props}>
        <ScrollAreaPrimitive.Viewport className='h-full w-full rounded-[inherit]'>
            {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
));

ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef(({className, orientation = 'vertical', ...props}, ref) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
        ref={ref}
        orientation={orientation}
        className={twx(
            'flex touch-none select-none transition-colors',
            orientation === 'vertical' && 'h-full w-1',
            orientation === 'horizontal' && 'h-1 flex-col',
            className
        )}
        {...props}
    >
        <ScrollAreaPrimitive.ScrollAreaThumb className='relative flex-1 rounded-full bg-primary-tins-slate' />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
));

ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export {ScrollArea, ScrollBar};
