import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';

const ConsumerProfileHeader = ({userName}) => {
    const isMobile = useIsMobileLayout();
    const consumerProfileExternalLink = appConfig.getConsumerProfileExternalLink();
    const redirectToExternalLink = useConfigExternalLink(consumerProfileExternalLink);
    const onClick = () => {
        amplitudeProfileTracking.trackShowProfileClick();
        googleAnalyticsService.trackShowProfileLinkClick();
        redirectToExternalLink();
    };

    return (
        <article className='ml:mt-8 ml:px-4'>
            {userName && <h1 className='mb-0 text-32 ml:max-w-[233px] ml:text-left ml:leading-[40px]'>{userName}</h1>}
            <AppButton
                className='pt-2 tracking-05 ml:mt-2 ml:w-[153px] ml:rounded-none ml:p-0 ml:text-left ml:tracking-0'
                tertiary={isMobile}
                isExternalLink
                onClick={onClick}
            >
                <AemEditableText pageName={routePaths.CONSUMER_PROFILE} itemName='showProfileButton' />
            </AppButton>
        </article>
    );
};

export default ConsumerProfileHeader;
