import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

import useOnScroll from '../../hooks/useOnScroll';
import domService from '../../services/domService';
import {twx} from '../../utils/tailwindUtils';
import {CancelIcon} from '../Icons';

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverStickyTitle = ({className, contentElement, ...props}) => {
    const scrollTop = useOnScroll({scrollElement: contentElement});

    return (
        <div
            className={twx(
                'sticky top-0 z-10 border-mischka bg-romance p-6 pb-4 text-24 font-bold leading-[24px]',
                {'border-b': scrollTop},
                className
            )}
            {...props}
        />
    );
};

const PopoverContent = React.forwardRef(({className, align = 'center', sideOffset = 4, ...props}, ref) => (
    <PopoverPrimitive.Portal container={domService.getRoot()}>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={twx(
                'z-40 w-[360px] rounded-xl bg-romance text-start shadow-[0_8px_8px_0px_rgba(0,0,0,0.1)]',
                className
            )}
            {...props}
        />
    </PopoverPrimitive.Portal>
));

const PopoverArrow = React.forwardRef(({className, ...props}, ref) => (
    <PopoverPrimitive.Arrow ref={ref} className={twx('fill-romance', className)} width={17} height={8.5} {...props} />
));

const PopoverClose = React.forwardRef(({className, ...props}, ref) => (
    <PopoverPrimitive.Close
        ref={ref}
        className={twx('absolute right-5 top-6 cursor-pointer rounded-full p-1 focus-visible:outline-0', className)}
        {...props}
    >
        <CancelIcon className='h-4 w-4 text-primary' />
    </PopoverPrimitive.Close>
));

PopoverContent.displayName = PopoverPrimitive.Content.displayName;
PopoverArrow.displayName = PopoverPrimitive.Arrow.displayName;
PopoverClose.displayName = PopoverPrimitive.Close.displayName;

export {Popover, PopoverAnchor, PopoverArrow, PopoverClose, PopoverContent, PopoverStickyTitle, PopoverTrigger};
