import React from 'react';

import routePaths from '@/consts/route/routePaths';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../../components/Button';
import {ConfirmationIcon} from '../../../../components/Icons';

const ThanksPopupContent = ({onBackButtonClick}) => {
    return (
        <div className='flex w-full flex-col items-center justify-between'>
            <ConfirmationIcon />
            <h1 className='mb-2 w-full break-words text-28'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='thankstitle' />
            </h1>
            <p className='w-full break-words text-14'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='thankssubtitle' />
            </p>
            <AppButton className='mt-8 min-h-12 max-w-[312px] break-words' onClick={onBackButtonClick} primary light>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='thanksbuttonlabel' />
            </AppButton>
        </div>
    );
};

export default ThanksPopupContent;
