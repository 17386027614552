import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {EllipsisMenuIcon, RenameIcon} from '@/components/Icons';
import useIsDesktopLayout from '@/hooks/useIsDesktopLayout';

import useDevicesListPopup from '../hooks/useDevicesListPopup';
import useOnRenameClick from '../hooks/useOnRenameClick';
import DevicesListPopup from './DevicesListPopup/DevicesListPopup';

const MyDevicesDeviceHeader = ({deviceName}) => {
    const isDesktop = useIsDesktopLayout();
    const onRenameClick = useOnRenameClick();
    const {isPopupOpened, openPopup, closePopup} = useDevicesListPopup();

    const onMoreDevicesClick = () => {
        openPopup();
    };

    return (
        <>
            <div className='flex w-full items-center'>
                <h1 className='mb-0 overflow-hidden text-ellipsis text-center text-28 leading-[40px]'>{deviceName}</h1>
                {isDesktop && (
                    <ButtonRaw className='h-full p-2' onClick={onRenameClick}>
                        <RenameIcon />
                    </ButtonRaw>
                )}
                <div className='flex flex-1 justify-end'>
                    <ButtonRaw className='shrink-0' onClick={onMoreDevicesClick}>
                        <EllipsisMenuIcon />
                    </ButtonRaw>
                </div>
            </div>
            {isPopupOpened && <DevicesListPopup onClose={closePopup} />}
        </>
    );
};

export default MyDevicesDeviceHeader;
