import React from 'react';

import Icon from '../Icon/Icon';

const FlexPuffBlueIcon = (props) => {
    return (
        <Icon width='65' height='64' {...props}>
            <path
                d='M52.2778 30.3785C51.7165 30.3785 51.2376 29.9434 51.1857 29.3663C51.0515 27.8419 50.7281 26.3267 50.2278 24.864C48.6904 20.3709 45.5666 16.6586 41.4301 14.4121C40.8963 14.122 40.6949 13.4493 40.9817 12.9092C41.2684 12.3692 41.9334 12.1655 42.4703 12.4556C47.0949 14.9675 50.5878 19.115 52.3053 24.1388C52.8635 25.7713 53.2235 27.4623 53.373 29.1688C53.4279 29.7798 52.9794 30.3199 52.3754 30.3723C52.3419 30.3754 52.3083 30.3785 52.2778 30.3785Z'
                fill='#00D1D2'
                stroke='#00D1D2'
                strokeWidth='0.5'
            />
            <path
                d='M52.3568 30.3877L46.6706 30.3692C46.3686 30.3692 46.1215 30.1193 46.1245 29.8107C46.1245 29.5052 46.3716 29.2583 46.6736 29.2583L52.3598 29.2768C52.6618 29.2768 52.9089 29.5268 52.9059 29.8354C52.9059 30.1409 52.6588 30.3877 52.3568 30.3877Z'
                fill='#34303D'
            />
            <path
                d='M32.6115 51.5632C32.3095 51.5632 32.0624 51.3133 32.0655 51.0047L32.0838 45.2526C32.0838 44.9471 32.3309 44.7002 32.6329 44.7002C32.9349 44.7002 33.182 44.9502 33.1789 45.2587L33.1606 51.0109C33.1606 51.3164 32.9135 51.5632 32.6115 51.5632Z'
                fill='#34303D'
            />
            <path
                d='M19.5201 22.5495C19.4591 22.5495 19.395 22.5341 19.337 22.5002L15.7771 20.4203C15.6032 20.3184 15.5422 20.0901 15.6428 19.9142C15.7435 19.7383 15.9692 19.6766 16.1431 19.7784L19.7031 21.8583C19.877 21.9601 19.938 22.1885 19.8373 22.3644C19.7702 22.4847 19.6482 22.5495 19.5201 22.5495Z'
                fill='#34303D'
            />
            <path
                d='M22.2262 48.388C22.1652 48.388 22.1011 48.3726 22.0432 48.3386C21.8693 48.2368 21.8083 48.0085 21.9089 47.8326L23.9711 44.2221C24.0718 44.0462 24.2975 43.9844 24.4714 44.0863C24.6453 44.1881 24.7063 44.4165 24.6056 44.5924L22.5435 48.2029C22.4763 48.3232 22.3543 48.388 22.2262 48.388Z'
                fill='#34303D'
            />
            <path
                d='M14.9051 40.5345C14.777 40.5345 14.6549 40.4697 14.5878 40.3493C14.4872 40.1734 14.5451 39.9451 14.7221 39.8432L18.3797 37.7078C18.5535 37.606 18.7793 37.6646 18.8799 37.8436C18.9806 38.0195 18.9196 38.2478 18.7457 38.3497L15.0881 40.4851C15.0302 40.519 14.9661 40.5345 14.9051 40.5345Z'
                fill='#34303D'
            />
            <path
                d='M45.6981 22.5495C45.573 22.5495 45.4479 22.4847 45.3808 22.3644C45.2801 22.1885 45.3381 21.9601 45.515 21.8583L48.9865 19.8308C49.1604 19.729 49.3862 19.7876 49.4868 19.9666C49.5875 20.1425 49.5265 20.3709 49.3526 20.4727L45.8811 22.5001C45.8231 22.5341 45.7591 22.5495 45.6981 22.5495Z'
                fill='#00D1D2'
            />
            <path
                d='M42.8297 48.101C42.7046 48.101 42.5795 48.0362 42.5124 47.9158L40.615 44.5923C40.5143 44.4164 40.5753 44.188 40.7492 44.0862C40.9231 43.9844 41.1488 44.043 41.2495 44.222L43.1469 47.5455C43.2476 47.7214 43.1897 47.9498 43.0127 48.0516C42.9548 48.0855 42.8907 48.101 42.8297 48.101Z'
                fill='#34303D'
            />
            <path
                d='M49.7116 40.1827C49.6505 40.1827 49.5865 40.1672 49.5285 40.1333L46.4719 38.3465C46.298 38.2447 46.237 38.0164 46.3377 37.8405C46.4383 37.6646 46.6641 37.6028 46.8379 37.7047L49.8946 39.4914C50.0685 39.5932 50.1295 39.8216 50.0288 39.9975C49.9617 40.1179 49.8397 40.1827 49.7116 40.1827Z'
                fill='#34303D'
            />
            <path
                d='M53.3783 29.2741C53.3875 29.3481 53.3936 29.4191 53.3997 29.4962C54.0922 39.0101 48.3449 47.8327 39.4221 50.9556C37.1891 51.7363 34.886 52.1252 32.592 52.1252C29.4774 52.1252 26.3811 51.4092 23.4983 49.9928C18.4924 47.5302 14.7311 43.2439 12.913 37.9238C11.2809 33.1438 11.3968 27.9286 13.2455 23.238C15.0727 18.5968 18.471 14.7549 22.8089 12.422C23.3458 12.135 24.0078 12.3386 24.2945 12.8818C24.5782 13.4249 24.3769 14.0945 23.84 14.3846C19.9597 16.4707 16.9214 19.9053 15.2863 24.0589C13.6329 28.2557 13.5292 32.9216 14.9904 37.1956C16.6194 41.9571 19.9811 45.7898 24.4592 47.9931C28.9374 50.1965 33.9983 50.502 38.7022 48.8541C46.6855 46.0614 51.8256 38.1676 51.2064 29.6567C51.1972 29.524 51.2094 29.3944 51.243 29.2741H53.3722H53.3783Z'
                fill='#34303D'
                stroke='#34303D'
                strokeWidth='0.5'
            />
            <path
                d='M42.0359 18.8206C41.5845 18.8206 41.1604 18.5367 41.0018 18.08L39.4826 13.7289C39.2416 13.0407 39.6016 12.2816 40.2819 12.0378L44.5831 10.501C45.1536 10.2973 45.782 10.5998 45.9833 11.1768C46.1847 11.757 45.8857 12.3896 45.3122 12.5932L41.8468 13.8338L43.0701 17.3394C43.2714 17.9195 42.9724 18.5521 42.402 18.7558C42.28 18.799 42.158 18.8206 42.0359 18.8206Z'
                fill='#00D1D2'
            />
            <path
                d='M18.5501 30.3877L12.8639 30.3692C12.5619 30.3692 12.3148 30.1193 12.3179 29.8107C12.3179 29.5052 12.565 29.2583 12.867 29.2583L18.5532 29.2768C18.8552 29.2768 19.1023 29.5268 19.0992 29.8354C19.0992 30.1409 18.8521 30.3877 18.5501 30.3877Z'
                fill='#34303D'
            />
            <path
                d='M35.0576 42.7222H30.1645C29.8198 42.7222 29.5361 42.4383 29.5361 42.0865V11.0732C29.5361 10.7245 29.8168 10.4375 30.1645 10.4375H35.0576C35.4023 10.4375 35.686 10.7214 35.686 11.0732V42.0865C35.686 42.4352 35.4054 42.7222 35.0576 42.7222ZM31.2503 41.0517H33.8269V11.7402H31.2503V41.0517Z'
                fill='#00D1D2'
            />
            <path
                d='M35.1357 23.9476L30.0871 23.929C29.782 23.929 29.538 23.6791 29.541 23.3705C29.541 23.065 29.7881 22.8181 30.0901 22.8181L35.1388 22.8366C35.4408 22.8366 35.6879 23.0866 35.6848 23.3952C35.6848 23.7007 35.4377 23.9476 35.1357 23.9476Z'
                fill='#00D1D2'
            />
            <path
                d='M34.954 21.923C34.2127 21.923 33.8131 21.673 33.4928 21.4725C33.2304 21.3089 33.0413 21.1886 32.6111 21.1886C32.181 21.1886 31.9919 21.3089 31.7295 21.4725C31.4092 21.673 31.0096 21.923 30.2683 21.923C29.9663 21.923 29.7192 21.673 29.7192 21.3675C29.7192 21.062 29.9663 20.8121 30.2683 20.8121C30.6985 20.8121 30.8876 20.6917 31.1499 20.5282C31.4702 20.3276 31.8699 20.0776 32.6111 20.0776C33.3524 20.0776 33.752 20.3276 34.0724 20.5282C34.3347 20.6917 34.5238 20.8121 34.954 20.8121C35.256 20.8121 35.5031 21.062 35.5031 21.3675C35.5031 21.673 35.256 21.923 34.954 21.923Z'
                fill='#00D1D2'
            />
        </Icon>
    );
};

export default FlexPuffBlueIcon;
