import {useRef} from 'react';

import arrayUtils from '@/utils/arrayUtils';

export const useHighlightsCarouselControls = () => {
    const carouselRefs = useRef([]);

    const setCarouselRef = (i, ref) => {
        carouselRefs.current[i] = ref;
    };

    const getSlideProgress = ({carousel, slide}) => carouselRefs.current[carousel].getSlideProgress(slide);

    const setSlideProgress = ({carousel, slide, progress}) => {
        carouselRefs.current[carousel].setSlideProgress(slide, progress);
    };

    const resetSlideProgress = ({carousel, slide}) => {
        carouselRefs.current[carousel].resetSlideProgress(slide);
    };

    const playCarousel = (carousels, i) => {
        const carousel = carousels[i];
        const newCarousel = {
            ...carousel,
            isPlaying: true,
        };

        const newCarousels = arrayUtils.updateArrayImmutably(carousels, [i, newCarousel]);

        return newCarousels;
    };

    const pauseCarousel = (carousels, i) => {
        const carousel = carousels[i];
        const newCarousel = {
            ...carousel,
            isPlaying: false,
        };

        const newCarousels = arrayUtils.updateArrayImmutably(carousels, [i, newCarousel]);

        return newCarousels;
    };

    const endCarousel = (carousels, i) => {
        const carousel = carousels[i];
        const newCarousel = {
            ...carousel,
            isPlaying: false,
            isEnded: true,
        };

        const newCarousels = arrayUtils.updateArrayImmutably(carousels, [i, newCarousel]);

        return newCarousels;
    };

    const restartCarousel = (carousels, i) => {
        const carousel = carousels[i];
        const newCarousel = {
            ...carousel,
            isPlaying: true,
            currentSlide: 0,
            isEnded: false,
        };

        const newCarousels = arrayUtils.updateArrayImmutably(carousels, [i, newCarousel]);

        resetSlideProgress({carousel: i, slide: newCarousel.currentSlide});

        return newCarousels;
    };

    const changeCarouselSlide = (carousels, i, {newSlide}) => {
        const carousel = carousels[i];

        if (newSlide === carousel.currentSlide) {
            const newCarousels = carousel.isEnded ? restartCarousel(carousels, i) : carousels;

            resetSlideProgress({carousel: i, slide: carousel.currentSlide});

            return newCarousels;
        }

        const newCarousel = {
            ...carousel,
            currentSlide: newSlide,
            isEnded: false,
        };
        const newCarousels = arrayUtils.updateArrayImmutably(carousels, [i, newCarousel]);

        return newCarousels;
    };

    return {
        setCarouselRef,
        getSlideProgress,
        setSlideProgress,
        resetSlideProgress,
        playCarousel,
        pauseCarousel,
        endCarousel,
        restartCarousel,
        changeCarouselSlide,
    };
};

export default useHighlightsCarouselControls;
